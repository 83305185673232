/* eslint-env es6 */
/* eslint-disable */
import { combineReducers } from 'redux'
import { userReducer } from './user'
import { reducer as permissions } from "react-redux-permissions"
import { workspaceReducer } from './workspaces'
import { widgetReducer } from './widgets'
import { streamReducer } from './streams'
import { channelReducer } from './channels'
import { messageReducer } from './messages'
import { labelReducer } from './labels'
import { tagReducer } from './tags'
import { richMessage } from './richMessage'
import { settingsReducer } from './settings'
import { configsReducer } from './config'
import { adminPanel } from './adminPanel'
import { createTemplate } from './template'
import { stripeReducer } from './stripe'
import { extraReducer } from './extra'
import { productReducer } from  './products'
import { eventReducer } from "./event"
import { sessionReducer } from "./sessions"
import { modalReducer } from "./modal"

import theme from "./themeStore"
import { USER_SIGNOUT_SUCCESS } from '../actions/UserActions'
import { tradesyncReducer } from "./tradesync"
import { topicsReducer } from "./topics"
import { reactionReducer } from "./reactions"

const appReducer = combineReducers({
  theme,
  configs: configsReducer,
  user: userReducer,
  permissions: permissions,
  workspaces: workspaceReducer,
  widgets: widgetReducer,
  streams: streamReducer,
  channels: channelReducer,
  messages: messageReducer,
  labels: labelReducer,
  tags: tagReducer,
  reactions: reactionReducer,
  posts: richMessage,
  settings: settingsReducer,
  adminPanel: adminPanel,
  template: createTemplate,
  stripe: stripeReducer,
  extra: extraReducer,
  products: productReducer,
  events: eventReducer,
  sessions: sessionReducer,
  modal: modalReducer,
  tradesync: tradesyncReducer,
  topics: topicsReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === USER_SIGNOUT_SUCCESS) {
    state = undefined
  }

  return appReducer(state, action)
}