import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { hashStr } from "../../../utils/Helpres"


import { withTranslation } from 'react-i18next'

class ShareLinkChannelModal extends Component {
  constructor(props) {
    super(props)

    const { channel } = props.data
    this.state = {
      link: window?.location?.origin?.concat("?share=" + hashStr(channel?.id)),
    }
  }

  toggleModal = () => {
    navigator.clipboard.writeText(this.state.link)
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { channel } = this.props.data

    return (
      <Modal {...this.props}
             onHide={this.toggleModal}
             centered size="md"
             aria-labelledby="contained-modal-title-vcenter"
             className="n-modal__modal">
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg width="38rem" height="38rem" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 37C28.9 37 37 28.9 37 19C37 9.1 28.9 1 19 1C9.1 1 1 9.1 1 19C1 28.9 9.1 37 19 37Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M13.5996 21.2C14.7042 21.2 15.5996 20.3046 15.5996 19.2C15.5996 18.0954 14.7042 17.2 13.5996 17.2C12.495 17.2 11.5996 18.0954 11.5996 19.2C11.5996 20.3046 12.495 21.2 13.5996 21.2Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M22.3994 16.8C23.504 16.8 24.3994 15.9046 24.3994 14.8C24.3994 13.6954 23.504 12.8 22.3994 12.8C21.2948 12.8 20.3994 13.6954 20.3994 14.8C20.3994 15.9046 21.2948 16.8 22.3994 16.8Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M22.3994 25.7C23.504 25.7 24.3994 24.8046 24.3994 23.7C24.3994 22.5954 23.504 21.7 22.3994 21.7C21.2948 21.7 20.3994 22.5954 20.3994 23.7C20.3994 24.8046 21.2948 25.7 22.3994 25.7Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M15.8994 18L20.0994 15.9" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M15.8994 20.4L20.0994 22.5" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                </svg>
              </div>
              <div className="n-modal__header__title">
                {t("modal_channel_text_86")}<b>{channel?.name}</b>
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex">
                <div className='n-modal__content__link'>{this.state.link}</div>
              </div>
              <div className="n-modal__btns">
                <div className="button-lg" onClick={this.toggleModal}>{t("modal_channel_text_87")}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ShareLinkChannelModal)