import { extraRead, publishedExtraUpdate } from '../../../services/api/extra'

export const ADMIN_EXTRA_MANAGEMENT_LIST_SUCCESS = "ADMIN_EXTRA_MANAGEMENT_LIST_SUCCESS"
export const ADMIN_EXTRA_MANAGEMENT_LIST_FAIL = "ADMIN_EXTRA_MANAGEMENT_LIST_FAIL"

export const ADMIN_EXTRA_MANAGEMENT_UPDATE_SUCCESS = "ADMIN_EXTRA_MANAGEMENT_UPDATE_SUCCESS"
export const ADMIN_EXTRA_MANAGEMENT_UPDATE_FAIL = "ADMIN_EXTRA_MANAGEMENT_UPDATE_FAIL"

export function handleAdminExtraRead() {
  return dispatch => {
    extraRead()
    .then(data => {
      dispatch({  
        type: ADMIN_EXTRA_MANAGEMENT_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_EXTRA_MANAGEMENT_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminExtraProductUpdate(extra, updatedProduct, productIsPublic, productPrice, productTrial) {
  return dispatch => {
    const products = extra?.products.filter(product => product?.id !== updatedProduct?.id).concat({
      itemId: updatedProduct?.id,
      isPublic: productIsPublic,
      isPaid: productIsPublic && productPrice > 0
    })
    publishedExtraUpdate(extra?.id, extra?.name, extra?.brief, extra?.description, extra?.public, products)
    .then(data => {      
      dispatch({  
        type: ADMIN_EXTRA_MANAGEMENT_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_EXTRA_MANAGEMENT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}