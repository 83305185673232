import React, { Component } from 'react'
import { connect } from 'react-redux'

import AdminNavbar from '../components/admin/AdminNavbar'
import AdminSideNav from '../components/admin/AdminSideNav'
import layoutHelpers from '../components/admin/helpers'

import Permissions from "react-redux-permissions"
import { PLATFORM_ADMIN_PANEL } from "../utils/Constant"
import AccessDenied from '../shared/NotFound/AccessDenied'

import "../styles/admin/variables/index.scss"

class AdminContainer extends Component {
  closeSidenav(e) {
    e.preventDefault()
    layoutHelpers.setCollapsed(true)
  }

  componentDidMount() {
    layoutHelpers.init()
    layoutHelpers.update()
    layoutHelpers.setAutoUpdate(true)
  }

  componentWillUnmount() {
    layoutHelpers.destroy()
  }

  render() {
    return (
      <Permissions
        allowed={[PLATFORM_ADMIN_PANEL]}
        fallbackElement={<AccessDenied />}
      >
        <div className="layout-wrapper layout-2 admin">
          <div className="layout-inner">
            <AdminSideNav {...this.props} />

            <div className="layout-container">
              <AdminNavbar {...this.props} />

              <div className="layout-content">
                <div className="container-fluid flex-grow-1 container-p-y">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
          <div className="layout-overlay" onClick={this.closeSidenav}></div>
        </div>
      </Permissions>
    )
  }
}

const mapStateToProps = store => {
  return {
    permissions: store.permissions,
  }
}

export default connect(
  mapStateToProps
)(AdminContainer)