import { API, graphqlOperation } from 'aws-amplify';
import { listTopics, getTopic } from "../../graphql/queries";
import { createTopic, deleteTopic, updateTopic } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listTopics
const attribute = "listTopics"

export const HUB_OBSERVER_TOPICS = "topic"

export const topicCreate = async (owner, user, parentChannelId, rule) => {
  if (owner == null || user == null || parentChannelId == null || rule?.channelId == null || rule == null || rule?.disabled === true) {
    console.error('Topic API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createTopic, {input: {
      owner: owner,
      parentChannelId: parentChannelId,
      channelId: rule?.channelId,
      categories: rule?.categories,
      labels: rule?.labels,
      topicUserId: user.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createTopic
  } catch (err) {
    console.error('error creating topic:', err)
    return
  }
}

export const topicRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const topic = await loadList(action, attribute, filter, 0)
    return topic
  } catch (err) { 
    console.error('error fetching topic', err)
    return null
  }  
}

export const topicUpdate = async (owner, topicId, rule) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getTopic, 
      { id: topicId }
    ))
    original = exist?.data?.getTopic
  } catch (err) { console.error('error fetching topic:', err) }
  if (original?.id === topicId) {
    if (owner == null || topicId == null || rule?.channelId == null || rule == null || rule?.disabled === true) {
      console.error('Topic API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateTopic, {input: {
        id: topicId,
        channelId: rule?.channelId,
        categories: rule?.categories,
        labels: rule?.labels,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateTopic
    } catch (err) {
      console.error('error updating topic:', err)
      return
    }
  } else {
    return null
  }
}

export const topicDelete = async (owner, topicId) => {
  await API.graphql(graphqlOperation(deleteTopic, { input: { id: topicId }}));
  return topicId
}
