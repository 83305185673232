import { Hub } from "aws-amplify"
import * as K from "../../utils/Constant"
import { toastSuccess, toastError } from "../../shared/platform/Toast"
import { apiPayments, apiPaymentRequest } from "../../services/rest/apiPayment"

export const STRIPE_CURRENCY_GET_SUCCESS = 'STRIPE_CURRENCY_GET_SUCCESS'
export const STRIPE_BALANCE_GET_SUCCESS = 'STRIPE_BALANCE_GET_SUCCESS'
export const STRIPE_BALANCE_GET_FAIL = 'STRIPE_BALANCE_GET_FAIL'

export const STRIPE_CUSTOMER_CREATE_SUCCESS = 'STRIPE_CUSTOMER_CREATE_SUCCESS'
export const STRIPE_CUSTOMER_CREATE_FAIL = 'STRIPE_CUSTOMER_CREATE_FAIL'
export const STRIPE_CUSTOMER_READ_SUCCESS = 'STRIPE_CUSTOMER_READ_SUCCESS'
export const STRIPE_CUSTOMER_READ_FAIL = 'STRIPE_CUSTOMER_READ_FAIL'
export const STRIPE_CUSTOMER_LIST_SUCCESS = 'STRIPE_CUSTOMER_LIST_SUCCESS'
export const STRIPE_CUSTOMER_LIST_FAIL = 'STRIPE_CUSTOMER_LIST_FAIL'
export const STRIPE_CUSTOMER_GET_SUCCESS = 'STRIPE_CUSTOMER_GET_SUCCESS'
export const STRIPE_CUSTOMER_GET_FAIL = 'STRIPE_CUSTOMER_GET_FAIL'
export const STRIPE_CUSTOMER_DELETE_SUCCESS = 'STRIPE_CUSTOMER_DELETE_SUCCESS'
export const STRIPE_CUSTOMER_DELETE_FAIL = 'STRIPE_CUSTOMER_DELETE_FAIL'

export const STRIPE_PRODUCT_CREATE_SUCCESS = 'STRIPE_PRODUCT_CREATE_SUCCESS'
export const STRIPE_PRODUCT_CREATE_FAIL = 'STRIPE_PRODUCT_CREATE_FAIL'
export const STRIPE_PRODUCT_LIST_SUCCESS = 'STRIPE_PRODUCT_LIST_SUCCESS'
export const STRIPE_PRODUCT_LIST_FAIL = 'STRIPE_PRODUCT_LIST_FAIL'
export const STRIPE_PRODUCT_DELETE_SUCCESS = 'STRIPE_PRODUCT_DELETE_SUCCESS'
export const STRIPE_PRODUCT_DELETE_FAIL = 'STRIPE_PRODUCT_DELETE_FAIL'

export const STRIPE_PRICE_CREATE_SUCCESS = 'STRIPE_PRICE_CREATE_SUCCESS'
export const STRIPE_PRICE_CREATE_FAIL = 'STRIPE_PRICE_CREATE_FAIL'
export const STRIPE_PRICE_LIST_SUCCESS = 'STRIPE_PRICE_LIST_SUCCESS'
export const STRIPE_PRICE_LIST_FAIL = 'STRIPE_PRICE_LIST_FAIL'
export const STRIPE_PRICE_DELETE_SUCCESS = 'STRIPE_PRICE_DELETE_SUCCESS'
export const STRIPE_PRICE_DELETE_FAIL = 'STRIPE_PRICE_DELETE_FAIL'

export const STRIPE_SUBSCRIPTION_CREATE_SUCCESS = 'STRIPE_SUBSCRIPTION_CREATE_SUCCESS'
export const STRIPE_SUBSCRIPTION_CREATE_FAIL = 'STRIPE_SUBSCRIPTION_CREATE_FAIL'
export const STRIPE_SUBSCRIPTION_UPDATE_SUCCESS = 'STRIPE_SUBSCRIPTION_UPDATE_SUCCESS'
export const STRIPE_SUBSCRIPTION_UPDATE_FAIL = 'STRIPE_SUBSCRIPTION_UPDATE_FAIL'
export const STRIPE_SUBSCRIPTION_LIST_SUCCESS = 'STRIPE_SUBSCRIPTION_LIST_SUCCESS'
export const STRIPE_SUBSCRIPTION_LIST_FAIL = 'STRIPE_SUBSCRIPTION_LIST_FAIL'
export const STRIPE_SUBSCRIPTION_GET_SUCCESS = 'STRIPE_SUBSCRIPTION_GET_SUCCESS'
export const STRIPE_SUBSCRIPTION_GET_FAIL = 'STRIPE_SUBSCRIPTION_GET_FAIL'
export const STRIPE_SUBSCRIPTION_DELETE_SUCCESS = 'STRIPE_SUBSCRIPTION_DELETE_SUCCESS'
export const STRIPE_SUBSCRIPTION_DELETE_FAIL = 'STRIPE_SUBSCRIPTION_DELETE_FAIL'

export const STRIPE_CARD_CREATE_SUCCESS = 'STRIPE_CARD_CREATE_SUCCESS'
export const STRIPE_CARD_CREATE_FAIL = 'STRIPE_CARD_CREATE_FAIL'
export const STRIPE_CARD_DELETE_SUCCESS = 'STRIPE_CARD_DELETE_SUCCESS'
export const STRIPE_CARD_DELETE_FAIL = 'STRIPE_CARD_DELETE_FAIL'

export const STRIPE_ACCOUNT_CREATE_SUCCESS = 'STRIPE_ACCOUNT_CREATE_SUCCESS'
export const STRIPE_ACCOUNT_CREATE_FAIL = 'STRIPE_ACCOUNT_CREATE_FAIL'
export const STRIPE_ACCOUNT_LIST_SUCCESS = 'STRIPE_ACCOUNT_LIST_SUCCESS'
export const STRIPE_ACCOUNT_LIST_FAIL = 'STRIPE_ACCOUNT_LIST_FAIL'
export const STRIPE_ACCOUNT_READ_SUCCESS = 'STRIPE_ACCOUNT_READ_SUCCESS'
export const STRIPE_ACCOUNT_READ_FAIL = 'STRIPE_ACCOUNT_READ_FAIL'
export const STRIPE_ACCOUNT_GET_SUCCESS = 'STRIPE_ACCOUNT_GET_SUCCESS'
export const STRIPE_ACCOUNT_GET_FAIL = 'STRIPE_ACCOUNT_GET_FAIL'
export const STRIPE_ACCOUNT_DELETE_SUCCESS = 'STRIPE_ACCOUNT_DELETE_SUCCESS'
export const STRIPE_ACCOUNT_DELETE_FAIL = 'STRIPE_ACCOUNT_DELETE_FAIL'

export const STRIPE_PAYMENT_CREATE_SUCCESS = 'STRIPE_PAYMENT_CREATE_SUCCESS'
export const STRIPE_PAYMENT_CREATE_FAIL = 'STRIPE_PAYMENT_CREATE_FAIL'

export const STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS = 'STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS'
export const STRIPE_ACCOUNT_TRANSFER_LIST_FAIL = 'STRIPE_ACCOUNT_TRANSFER_LIST_FAIL'

export function handlePaymentGeCurrency() {
  return dispatch => {
    apiPaymentRequest(apiPayments.GetCurrency, {})
      .then(currency => {
        dispatch({
          type: STRIPE_CURRENCY_GET_SUCCESS,
          payload: currency,
        })
      }).catch(e => {
        console.error('get currency error', e)
    })
  }
}

export function handlePaymentGetBalance() {
  return dispatch => {
    apiPaymentRequest(apiPayments.GetBalance, {})
    .then(balance => {
      dispatch({
        type: STRIPE_BALANCE_GET_SUCCESS,
        payload: balance,
      })
    }).catch(error => {
      dispatch({
        type: STRIPE_BALANCE_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentCreateCustomer(email) {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListCustomers, {email: email})
    .then(customers => {
      const myCustomer = customers?.find(item => item?.email === email)
      if (customers == null) {
        dispatch({
          type: STRIPE_CUSTOMER_READ_FAIL,
          error: true,
          payload: customers,
        })
      } else {
        if (myCustomer != null) {
          apiPaymentRequest(apiPayments.GetCustomerPaymentMethod, {customerId : myCustomer?.id})
            .then(methods => {
              dispatch({
                type: STRIPE_PAYMENT_CREATE_SUCCESS,
                payload: methods,
              })
          }).catch(error => {
            console.error(error);
            dispatch({
              type: STRIPE_PAYMENT_CREATE_FAIL,
              error: true,
              payload: new Error(error),
            })
          })
          dispatch({
            type: STRIPE_CUSTOMER_READ_SUCCESS,
            payload: myCustomer,
          })
        } else {
          apiPaymentRequest(apiPayments.CreateCustomer, {email: email})
            .then(customer => {
              dispatch({
                type: STRIPE_CUSTOMER_CREATE_SUCCESS,
                payload: customer,
              })
            }).catch(error => {
            console.error(error);
            dispatch({
              type: STRIPE_CUSTOMER_CREATE_FAIL,
              error: true,
              payload: new Error(error),
            })
          })
        }
      }
    }).catch(error => {
      dispatch({
        type: STRIPE_CUSTOMER_READ_FAIL,
        error: true,
        payload: new Error(error),
      })
      console.error(error);
    })    
  }
}

export function handlePaymentReadMyCustomer(email) {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListCustomers, {email: email})
      .then(customers => {
        const myCustomer = customers?.find(item => item?.email === email)
        if (customers == null) {
          dispatch({
            type: STRIPE_CUSTOMER_READ_FAIL,
            payload: customers,
          })
        } else {
          if (myCustomer != null) {
            apiPaymentRequest(apiPayments.GetCustomerPaymentMethod, {customerId: myCustomer?.id})
              .then(methods => {
                dispatch({
                  type: STRIPE_PAYMENT_CREATE_SUCCESS,
                  payload: methods,
                })
            }).catch(error => {
              dispatch({
                type: STRIPE_PAYMENT_CREATE_FAIL,
                payload: new Error(error),
              })
              console.error(error);
            })
            dispatch({
              type: STRIPE_CUSTOMER_READ_SUCCESS,
              payload: myCustomer,
            })
          } else {
            apiPaymentRequest(apiPayments.CreateCustomer, {email: email})
              .then(customer => {
                dispatch({
                  type: STRIPE_CUSTOMER_CREATE_SUCCESS,
                  payload: customer,
                })
              }).catch(error => {
              console.error(error);
              dispatch({
                type: STRIPE_CUSTOMER_CREATE_FAIL,
                error: true,
                payload: new Error(error),
              })
            })
          }
        }
    }).catch(error => {
      dispatch({
        type: STRIPE_CUSTOMER_READ_FAIL,
        error: true,
        payload: new Error(error),
      })
      console.error(error);
    })
  }
}

export function handlePaymentListCustomers(email) {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListCustomers, {email: email})
    .then(customers => {
      dispatch({
        type: STRIPE_CUSTOMER_LIST_SUCCESS,
        payload: customers,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CUSTOMER_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentGetCustomer(customerId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.GetCustomer, {customerId: customerId})
    .then(customer => {
      dispatch({
        type: STRIPE_CUSTOMER_GET_SUCCESS,
        payload: customer?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CUSTOMER_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentDeleteCustomer(customerId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.DestroyCustomer, {customerId: customerId})
    .then(customer => {
      dispatch({
        type: STRIPE_CUSTOMER_DELETE_SUCCESS,
        payload: customer?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CUSTOMER_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentUpdatePayment(customerId, paymentId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.AttachCustomerPaymentMethod, {customerId: customerId, paymentId: paymentId})
      .then(updateDefault => {
        apiPaymentRequest(apiPayments.GetCustomerPaymentMethod, {customerId: customerId})
          .then(methods => {
            dispatch({
              type: STRIPE_PAYMENT_CREATE_SUCCESS,
              payload: methods,
            })
            toastSuccess('toast_text_33')
          }).catch(error => {
          console.error(error);
          toastError('toast_text_34')
        })
      }).catch(error => {
      console.error(error);
      toastError('toast_text_34')
    })
  }  
}

export function handlePaymentCreateProduct(productId, name) {
  return dispatch => {
    apiPaymentRequest(apiPayments.CreateProduct, {productId: productId, name: name})
    .then(product => {
      dispatch({
        type: STRIPE_PRODUCT_CREATE_SUCCESS,
        payload: product?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handleAdminStripeProductCreate(bundle, name, description, price, currency, productItem) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiPaymentRequest(apiPayments.CreateProduct, {productId: bundle, name: name, authorName: productItem?.authorName})
        .then(stripeProduct => {
          if (stripeProduct?.id == null) {
            return
          }
          apiPaymentRequest(apiPayments.CreatePrice, {
            productId: stripeProduct?.id, name: stripeProduct?.name, amount: (price ?? 0)*100,
            currency: currency, interval: "month", authorName: productItem?.authorName})
            .then(priceData => {
              dispatch({
                type: STRIPE_PRICE_CREATE_SUCCESS,
                payload: priceData,
              })
              resolve({
                stripeProduct,
                priceData,
              })
            })
            .catch(err => {
              console.error('Stripe price create error: ', err)
              reject(err)
            })
          dispatch({
            type: STRIPE_PRODUCT_CREATE_SUCCESS,
            payload: stripeProduct,
          })
        })
        .catch(err => {
          console.error('Stripe product create error: ', err)
          reject(err)
        })
    })
  }
}

export function handlePaymentListProduct() {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListProduct, {})
    .then(products => {
      const allowedProds = products?.filter(pr => pr?.id?.startsWith(K.PRODUCT_PREFIX_SINGLE) || pr?.id?.startsWith(K.PRODUCT_PREFIX_PLAN) || pr?.id?.startsWith(K.PRODUCT_PREFIX_PACKAGE))
      dispatch({
        type: STRIPE_PRODUCT_LIST_SUCCESS,
        payload: allowedProds,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRODUCT_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentDeleteProduct(productId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.DestroyProduct, {productId: productId})
    .then(product => {
      dispatch({
        type: STRIPE_PRODUCT_DELETE_SUCCESS,
        payload: product?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentCreatePrice(productId, name, amount, currency, interval) {
  return dispatch => {
    apiPaymentRequest(apiPayments.CreatePrice, {productId: productId, name: name, amount: amount, currency: currency, interval: interval})
    .then(price => {
      dispatch({
        type: STRIPE_PRICE_CREATE_SUCCESS,
        payload: price?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRICE_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentListPrice() {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListPrice, {})
    .then(prices => {
      const allowedPrices = prices?.filter(pr => pr?.product?.startsWith(K.PRODUCT_PREFIX_SINGLE) || pr?.product?.startsWith(K.PRODUCT_PREFIX_PLAN) || pr?.product?.startsWith(K.PRODUCT_PREFIX_PACKAGE) )
      dispatch({
        type: STRIPE_PRICE_LIST_SUCCESS,
        payload: allowedPrices,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRICE_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentDeletePrice(priceId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.DestroyPrice, {priceId: priceId})
    .then(price => {
      dispatch({
        type: STRIPE_PRICE_DELETE_SUCCESS,
        payload: price?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_PRICE_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentCreateSubscription(customerId, priceId, isPackage, vendorId, vendorShare) {
  return dispatch => {
    apiPaymentRequest(apiPayments.CreateSubscription, {customerId: customerId, priceId: priceId, vendorId: vendorId, vendorShare: vendorShare})
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_CREATE_SUCCESS,
        payload: subscription,
      })
      Hub.dispatch(isPackage ? K.HUB_PAYMENT_PACKAGE : K.HUB_PAYMENT,
        {event: "success", data: subscription, message:'payment done' });
       
      if (subscription.error) {
        toastError('toast_text_36')
      } else {
        toastSuccess('toast_text_35')
      }
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
      Hub.dispatch(isPackage ? K.HUB_PAYMENT_PACKAGE : K.HUB_PAYMENT,
        {event: "error", data: error, message:'payment error' });
      toastError('toast_text_36')
    })
  }
}

export function handlePaymentUpdateSubscription(subscriptionId, data) {
  return dispatch => {
    apiPaymentRequest(apiPayments.UpdateSubscription, {subscriptionId: subscriptionId, data: data})
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_UPDATE_SUCCESS,
        payload: subscription?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_UPDATE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentListSubscription(customerId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListSubscriptions, {customerId: customerId})
    .then(subscriptions => {
      const allowedSubs = subscriptions?.filter(sub => sub?.plan?.product?.startsWith(K.PRODUCT_PREFIX_SINGLE) || sub?.plan?.product?.startsWith(K.PRODUCT_PREFIX_PLAN) || sub?.plan?.product?.startsWith(K.PRODUCT_PREFIX_PACKAGE) )
      dispatch({
        type: STRIPE_SUBSCRIPTION_LIST_SUCCESS,
        payload: allowedSubs,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentGetSubscription(subscriptionId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.GetSubscription, {subscriptionId: subscriptionId})
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_GET_SUCCESS,
        payload: subscription?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentDeleteSubscription(subscriptionId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.DestroySubscription, {subscriptionId: subscriptionId})
    .then(subscription => {
      dispatch({
        type: STRIPE_SUBSCRIPTION_UPDATE_SUCCESS,
        payload: subscription,
      })
      toastSuccess('toast_text_37')
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_SUBSCRIPTION_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
      toastError('toast_text_38')
    })
  }
}

export function handlePaymentCreateCard(customerId, number, exp_month, exp_year, cvc) {
  return dispatch => {
    apiPaymentRequest(apiPayments.CreateCardToken, {number: number, exp_month: exp_month, exp_year: exp_year, cvc: cvc})
    .then(token => {
      if (token == null) { return }       
      apiPaymentRequest(apiPayments.CreateCard, {customerId: customerId, tokenId: token?.id})
        .then(card => {
          dispatch({
            type: STRIPE_CARD_CREATE_SUCCESS,
            payload: card?.data,
          })
        }).catch(error => {
          console.error(error);
          dispatch({
            type: STRIPE_CARD_CREATE_FAIL,
            error: true,
            payload: new Error(error),
          })            
        })
    }).catch(error => {
      console.error(error);            
    })
  }
}

export function handlePaymentDeleteCard(cardId, custId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.DestroyCard, {cardId: cardId, custId: custId})
    .then((card) => {
      dispatch({
        type: STRIPE_CARD_DELETE_SUCCESS,
        payload: card?.data,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_CARD_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentCreateAccount(email) {
  return dispatch => {
    apiPaymentRequest(apiPayments.CreateAccount, {email: email})
    .then(account => {
      dispatch({
        type: STRIPE_ACCOUNT_CREATE_SUCCESS,
        payload: account,
      })
      toastSuccess('toast_text_39')
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_CREATE_FAIL,
        error: true,
        payload: new Error(error),
      })
      toastError('toast_text_40')
    })
  }
}

export function handlePaymentReadMyAccount(email) {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListAccounts, {})
      .then(accounts => {
        const listAccounts = accounts?.sort(function(a,b){
          return new Date(a.created) - new Date(b.created);
        })
        const myAccounts = listAccounts?.filter(v => v?.email === email)
        const myAccount = myAccounts?.find(v => v?.charges_enabled && v?.payouts_enabled) ?? myAccounts?.find(v => v != null)
        if (listAccounts == null) {
          dispatch({
            type: STRIPE_ACCOUNT_READ_FAIL,
            payload: myAccount,
          })
        } else {
          dispatch({
            type: STRIPE_ACCOUNT_READ_SUCCESS,
            payload: myAccount,
          })
        }
      }).catch(error => {
        console.error(error);
        dispatch({
          type: STRIPE_ACCOUNT_READ_FAIL,
          payload: null,
        })
    })
  }
}

export function handlePaymentListAccounts() {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListAccounts, {})
    .then(accounts => {
      dispatch({
        type: STRIPE_ACCOUNT_LIST_SUCCESS,  
        payload: accounts,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentGetAccount(accountId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.GetAccount, {accountId: accountId})
    .then(account => {
      dispatch({
        type: STRIPE_ACCOUNT_GET_SUCCESS,
        payload: account,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_GET_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentDeleteAccount(accountId) {
  return dispatch => {
    apiPaymentRequest(apiPayments.DestroyAccount, {accountId: accountId})
    .then(account => {
      dispatch({
        type: STRIPE_ACCOUNT_DELETE_SUCCESS,
        payload: account,
      })
    }).catch(error => {
      console.error(error);
      dispatch({
        type: STRIPE_ACCOUNT_DELETE_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}

export function handlePaymentListAccountTransfers() {
  return dispatch => {
    apiPaymentRequest(apiPayments.ListAccountTransfers, {})
    .then(transfers => {
      dispatch({
        type: STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS,  
        payload: transfers,
      })
    }).catch(error => {
      dispatch({
        type: STRIPE_ACCOUNT_TRANSFER_LIST_FAIL,
        error: true,
        payload: new Error(error),
      })
    })
  }
}