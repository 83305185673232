import { Auth, Hub, DataStore } from 'aws-amplify'
import * as K from "../utils/Constant"
import { Sha256 } from "@aws-crypto/sha256-js"

export async function authSignUp(email, password) {
  Auth.signUp({
      email,
      password
  })
    .then(data => {
      Hub.dispatch(K.HUB_AUTHSIGNUP,{event: "authSignUp", data: data, message:'auth sign up' });
    })
    .catch(err => console.error(err))
}

export async function authConfirmSignUp(usee, paa, code) {
  Auth.confirmSignUp(usee, code)
    .then(async () => {
      const user = await Auth.signIn(usee, paa);
      Hub.dispatch(K.HUB_AUTOSIGNIN,{event: 'signedIn', data: user, message:'signed in' });
      localStorage.setItem("VoxStorageResu","")
      localStorage.setItem("VoxStorageSsap","")
      localStorage.setItem("VoxStorageAcoo","")
    })
    .catch(err => console.error(err))  
}

export async function authSignIn(email, password) {
  Auth.signIn(email, password)
    .then(data => {
      Hub.dispatch(K.HUB_AUTHSIGNIN,{event: "authSignIn", data: data, message:'auth sign in' });
    })
    .catch(err => console.error(err))
}

export async function authResendCode(email) {
  Auth.authResendCode(email)
    .then(data => {
      Hub.dispatch(K.HUB_AUTHRESENDCODE,{event: "authResendCode", data: data, message:'auth resend code' });
    })
    .catch(err => console.error(err))
}

export async function authSignOut() {
  Auth.signOut()
    .then(data => {
      DataStore.clear()
      Hub.dispatch(K.HUB_AUTHSIGNOUT,{event: "authSignOut", data: data, message:'auth sign out' });
    })
    .catch(err => console.error(err))
}

export async function authCurrentAuthenticatedUser() {
  Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then(data => {
      Hub.dispatch(K.HUB_CURRENTUSER,{event: "currentUser", data: data, message:'current user' });
    })
    .catch(err => console.error(err));
}

export async function authCurrentSession(lastSession, isUpdate) {
  Auth.currentSession()
    .then(data => {
      const idSession = String(data?.accessToken?.payload?.auth_time) + data?.accessToken?.payload?.client_id + data?.accessToken?.payload?.event_id + data?.accessToken?.payload?.username
      const hash = new Sha256()
      hash.update(idSession)
      const hashArray = hash.digestSync()
      const hashData = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('')
      if (lastSession !== hashData) {
        if (isUpdate) {
          authUpdateUserAttributes({},{last_session: hashData })
        } else {
          Hub.dispatch(K.HUB_ENDSESSION,{event: "endSession", data: {}, message:'end session' })
        }
      }
    })
    .catch(err => console.error(err));
}

export async function authChangePassword(oldPassword, newPassword) {
  Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      Hub.dispatch(K.HUB_CHANGEPASSWORD,{event: "changePassword", data: data, message:'change password' });
    })
    .catch(err => {
      console.error(err)
      Hub.dispatch(K.HUB_CHANGEPASSWORD,{event: "changePassword", data: err, message:'change password' });
    });
}

export async function authForgotPassword(email) {
  Auth.forgotPassword(email)
    .then(data => {
      Hub.dispatch(K.HUB_FORGOTPASSWORD,{event: "forgotPassword", data: data, message:'forgot password' });
    })
    .catch(err => console.error(err));
}

export async function authForgotPasswordSubmit(email, code, new_password) {
  Auth.forgotPasswordSubmit(email, code, new_password)
    .then(data => {
      Hub.dispatch(K.HUB_FORGOTPASSWORD,{event: 'forgotPasswordSubmit', data: data, message:'forgot password submit' });
    })
    .catch(err => console.error(err));
}

export async function authUpdateUserAttributes(values, custom) {
  if (values?.name != null) { values["custom:setup_realname"] = "1" }
  if (values?.nickname != null) { values["custom:setup_username"] = "1" }
  if (values?.phone_number != null) {
    if (!values?.phone_number?.startsWith("+")) {
      values.phone_number = "+" + values?.phone_number
    }
    values["custom:setup_phone"] = "1"
  }
  if (custom?.last_session != null) { values["custom:last_session"] = custom?.last_session }
  Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.updateUserAttributes(user, values)
    })
    .then(data => {
      Hub.dispatch(K.HUB_UPDATEUSERATTRIBUTES,{event: "updateUserAttributes", data: data, message:'update user attributes' })
    })
    .catch(err => console.error(err));
}

export async function authResendEmailVerificationCode() {
  Auth.verifyCurrentUserAttribute('email')
}

export async function verifyCurrentUserAttribute(payload) {
  Auth.verifyCurrentUserAttributeSubmit(
    payload?.attribute,
    payload?.verification_code
  )
  .then(a => {
    Auth.currentAuthenticatedUser().then(user => {
      Auth.refreshUserAttributes(user);
    });
  })
}