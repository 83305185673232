import React from 'react'
import styles from './NotFound.module.scss'

function Error500() {
  return (
    <div className={styles.Container}>
      <div className="text-center">
        <h1 className={styles.Status}>500 ERROR</h1>
        <br/>
        <br/>
        <h2 className={styles.Message}>Sorry, something went wrong on our end. <br/>We are currently trying to fix the problem.</h2>
        <br/>
        <br/>
        <h3 className={styles.Description}>In the meantime, you can:</h3>
        <h4 className={styles.List}>* Refresh the page</h4>
        <h4 className={styles.List}>* Wait a few minutes</h4>
      </div>      
    </div>
  )
}

export default Error500
