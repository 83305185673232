import * as K from "./Constant"
import { isBlankOrNull, loadBrokerAddress } from "./Helpres"

export const getCompanyNameFromConfig = (configs) => {
  return configs?.find(el => el?.detail === K.DEFAULT_SET_COMPANY_NAME_CONFIG_KEY)?.content ?? "Company";
}

export const getTradeSyncBrokerIdFromConfig = (configs) => {
  const brokerAddressContent = configs?.find(cf => cf?.detail === K.PLATFORM_COPYTRADING_BROKER_ADDRESS_KEY)?.content ?? ""
  if (!isBlankOrNull(brokerAddressContent)) {
    var json = null
    try {
      json = loadBrokerAddress(brokerAddressContent)
    } catch {}
    return json
  } else {
    return null
  }
}