import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listMessages, getMessage, messagesByChannelId } from "../../graphql/queries";
import { createMessage, deleteMessage, updateMessage } from "../../graphql/mutations";
import { onCreateMessage, onUpdateMessage, onDeleteMessage } from "../../graphql/subscriptions";
import { AWS_API_LIMIT } from "../../utils/Constant"

import { loadList, loadListWithCustomFilters } from "./shared"
const action = listMessages
const attribute = "listMessages"

export const HUB_OBSERVER_MESSAGE = "message"

let subscriptions = []

export const messageCreate = async (owner, user, channel, content, guid) => {
  if (owner == null || user == null || channel == null || content == null) { 
    console.error('Message API: Required fields cannot be empty!')
    return
  }
  try {
    var item = {
      owner: owner,
      content: content,
      channelId: channel.id,
      messageUserId: user.id,
      messageChannelId: channel.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
    if (guid != null) {
      item["id"] = guid
    }
    const response = await API.graphql(graphqlOperation(createMessage, {input: item }))
      return response?.data?.createMessage
  } catch (err) {
    console.error('error creating message:', err)
    return
  }
}

export const messageReadV1 = async (rule, limit) => {
  try {
    const filter = {
      messageChannelId: {
        eq: rule.channelId,
      },
      deleted: {
        ne: true,
      },
    }
    const limit = Math.min(AWS_API_LIMIT, limit)
    const messages = await loadList(action, attribute, filter, limit)
    return messages
  } catch (err) {
    console.error('error fetching messages', err)
    return null
  }
}

export const messageGet = async (messageId) => {
  try {
    const item = await API.graphql(graphqlOperation(getMessage,
      { id: messageId }
    ))
    return item?.data?.getMessage
  } catch (err) {
    console.error('error fetching messages', err)
  }
}

export const messageRead = async (rule, limit, lastCreatedAt, handler) => {
  try {
    const customFilters = new Map()
    customFilters.set("messageChannelId", rule.channelId)
    customFilters.set("sortDirection", "DESC")
    const filter = {
      deleted: {
        ne: true,
      },
    }
    if (lastCreatedAt) {
      customFilters.set("createdAt", { gt: lastCreatedAt })
    }
    const apiLimit = Math.min(AWS_API_LIMIT, limit)
    await loadListWithCustomFilters(messagesByChannelId, "messagesByChannelId", customFilters, filter, apiLimit, handler)
  } catch (err) {
    console.error('error fetching messages', err)
    handler(null, err)
  }
}

export const messageUpdate = async (owner, messageId, content, deleted) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getMessage, 
      { id: messageId }
    ))
    original = exist?.data?.getMessage
  } catch (err) { console.error('error fetching message:', err) }
  if (original?.id === messageId) {
    if (owner == null || messageId == null || content == null) { 
      console.error('Message API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateMessage, {input: {
        id: messageId, 
        content: content,
        deleted: deleted === true,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateMessage
    } catch (err) {
      console.error('error updating message:', err)
      return
    }
  } else {
    return null
  }
}

export const messageDelete = async (owner, messageId) => {
  await API.graphql(graphqlOperation(deleteMessage, { input: { id: messageId }}));
  return messageId
}

export const messageObserve = async (parentChannelId, rule) => {
  const channelId = rule.channelId
  if (channelId == null || subscriptions.some(sub => sub.channelId === channelId )) { return } //sub.parentChannelId === parentChannelId &&
  const createEvent = 'INSERT MSG'
  const createSubscription = API.graphql(graphqlOperation(onCreateMessage, { channelId: channelId } )).subscribe({
    next: response => {
      if (response?.value.errors != null) {
        console.error('CATCH CREATE EVENT', response)
      }
      const message = response.value.data.onCreateMessage
      Hub.dispatch(parentChannelId, {event: createEvent, data: message, message:'create message observe' });
    },
  })
  subscriptions.push({parentChannelId, channelId, createSubscription, createEvent})
  const updateEvent = 'UPDATE MSG'
  const updateSubscription = API.graphql(graphqlOperation(onUpdateMessage, { channelId: channelId } )).subscribe({
    next: response => {
      if (response?.value.errors != null) {
        console.error('CATCH UPDATE EVENT', response)
      }
      const message = response.value.data.onUpdateMessage
      Hub.dispatch(parentChannelId, {event: updateEvent, data: message, message:'update message observe' });
    },
  })
  subscriptions.push({parentChannelId, channelId, updateSubscription, updateEvent})
  const deleteEvent = 'DELETE MSG'
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteMessage, { channelId: channelId } )).subscribe({
    next: response => {
      if (response?.value.errors != null) {
        console.error('CATCH DELETE EVENT', response)
      }
      const message = response.value.data.onDeleteMessage
      Hub.dispatch(parentChannelId, {event: deleteEvent, data: message, message:'delete message observe' });
    },
  })
  subscriptions.push({parentChannelId, channelId, deleteSubscription, deleteEvent})
}

export async function messageDeleteAllObservers() {
    for (let sub of subscriptions) {
      if (sub != null && sub?.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
}
