import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listWidgets, getWidget } from "../../graphql/queries"
import { createWidget, deleteWidget, updateWidget } from "../../graphql/mutations";
import { onCreateWidget, onUpdateWidget, onDeleteWidget } from "../../graphql/subscriptions";

import { loadList } from "./shared"
const action = listWidgets
const attribute = "listWidgets"

export const HUB_OBSERVER_WIDGET = "widget"

let subscriptions = []

export const widgetCreate = async (owner, workspace, stream, top, left, width, height, minWidth, minHeight, content) => {
  try {
    const filter = { owner: { eq: owner }, widgetStreamId: { eq: stream.id } }
    const widgets = await loadList(action, attribute, filter, 1)
    if (widgets?.length > 0) {
      console.error('Widget already exist!')
      return
    }
  } catch (err) { console.error('error fetching widgets') }
  if (owner == null || workspace == null || stream == null || 
    top == null || left == null || width == null || height == null || 
    minWidth == null || minHeight == null || content == null) { 
    console.error('Widget API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createWidget, {input: {
      owner: owner, 
      top: top,
      left: left,
      width: width,
      height: height,
      minWidth: minWidth,
      minHeight: minHeight,
      isFullHeight: false,
      visible: true,
      workspaceId: workspace.id,
      widgetWorkspaceId: workspace.id,
      widgetStreamId: stream.id,
      content: content,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createWidget
  } catch (err) {
    console.error('error creating widget:', err)
    return
  }
}

export const widgetRead = async (owner, workspaceId) => {
  try {
    const filter = {
      owner: {
        eq: owner
      },
      workspaceId: {
        eq: workspaceId
      }
    }
    const widgets = await loadList(action, attribute, filter, 0)
    return widgets
  } catch (err) { 
    console.error('error fetching widgets', err)
    return null
  }
}

export const widgetGet = async (widgetId) => {
  const exist = await API.graphql(graphqlOperation(getWidget,
    { id: widgetId }
  ))
  const original = exist?.data?.getWidget
  return original
}

export const widgetUpdate = async (owner, workspaceId, widgetId, top, left, width, height, minWidth, minHeight, isFullHeight, visible) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getWidget, 
      { id: widgetId }
    ))
    original = exist?.data?.getWidget
  } catch (err) { console.error('error fetching widget:', err) }
  if (original?.id === widgetId) {
    if (owner == null || widgetId == null || visible == null || 
      top == null || left == null || width == null || height == null || 
      minWidth == null || minHeight == null) { 
      console.error('Widget API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateWidget, {input: {
        id: widgetId, 
        top: top,
        left: left,
        width: width,
        height: height,
        minWidth: minWidth,
        minHeight: minHeight,
        isFullHeight: isFullHeight ?? false,
        visible: visible,
        workspaceId: workspaceId,
        widgetWorkspaceId: workspaceId,   
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateWidget
    } catch (err) {
      console.error('error updating widget:', err)
      return
    }
  } else {
    return null
  }
}

export const widgetDelete = async (owner, widgetId) => {
  try {
    await API.graphql(graphqlOperation(deleteWidget, {input: { id: widgetId }} ));    
    return widgetId
  } catch (err) { 
    console.error('error delete widget:', err)
    return widgetId
  }    
}

export const widgetObserve = async (owner, workspaceId) => {
  if ( owner == null || workspaceId == null || subscriptions.some(sub => sub.workspaceId === workspaceId) ) { return }
  const createSubscription = API.graphql(graphqlOperation(onCreateWidget, { owner: owner, workspaceId: workspaceId } )).subscribe({
    next: response => {
      const widget = response.value.data.onCreateWidget
      Hub.dispatch(HUB_OBSERVER_WIDGET, {event: 'INSERT', data: widget, message:'create widget observe' });  
    },
  })
  const createEvent = 'INSERT' 
  subscriptions.push({workspaceId, createSubscription, createEvent})
  const updateSubscription = API.graphql(graphqlOperation(onUpdateWidget, { owner: owner, workspaceId: workspaceId } )).subscribe({
    next: response => {
      const widget = response.value.data.onUpdateWidget
      Hub.dispatch(HUB_OBSERVER_WIDGET, {event: 'UPDATE', data: widget, message:'create widget observe' });  
    },
  })
  const updateEvent = 'UPDATE'
  subscriptions.push({workspaceId, updateSubscription, updateEvent})
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteWidget, { owner: owner, workspaceId: workspaceId } )).subscribe({
    next: response => {
      const widget = response.value.data.onDeleteWidget
      Hub.dispatch(HUB_OBSERVER_WIDGET, {event: 'DELETE', data: widget, message:'create widget observe' });  
    },
  })
  const deleteEvent = 'DELETE'  
  subscriptions.push({workspaceId, deleteSubscription, deleteEvent})
}

export async function deleteWidgetObservers(streamId) {
  if (streamId != null) {
    for (let sub of subscriptions) {
      if (sub.streamId === streamId && sub.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
  }          
}