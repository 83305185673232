import {
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_SUCCESS,
  EVENT_CREATE_FAIL,
  EVENT_READ_SUCCESS,
  EVENT_UPDATE_SUCCESS,
  EVENT_DELETE_SUCCESS
} from '../actions/EventActions'

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function eventReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case EVENT_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state,
        list: [...state.list, action.payload],
        isFetching: false, error: ''
      }

    case EVENT_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case EVENT_READ_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.filter(oldEv => !action.payload?.some(newEv => oldEv?.id === newEv?.id))?.concat(action.payload)] }

    case EVENT_UPDATE_SUCCESS:
      return { ...state, isFetching: false,
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          name: action.payload?.name, 
          description: action.payload?.description,
          info: action.payload?.info,
          public: action.payload?.public,
          filterRules: action.payload?.filterRules,
          status: action.payload?.status,
          products: action.payload?.products,
        } : item)
      }
    case EVENT_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }
    default:
      return state
  }
}