import {
  TAG_CREATE_REQUEST,
  TAG_CREATE_SUCCESS,
  TAG_CREATE_FAIL,
  TAG_READ_SUCCESS,
  TAG_DELETE_SUCCESS
} from '../actions/TagActions'

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function tagReducer(state = initialState, action) {
  switch (action.type) {
    case TAG_CREATE_REQUEST:
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case TAG_CREATE_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload)] }

    case TAG_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case TAG_READ_SUCCESS:
      return { ...state, isFetching: false, list: action.payload }

    case TAG_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}