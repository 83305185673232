import React, { Component } from 'react'
import { BrowserRouter as AppRouter, Route, Switch } from 'react-router-dom'
import { connect } from "react-redux"
import Amplify, { Auth, Hub } from 'aws-amplify'
import awsconfig from './aws-exports'

import ErrorFormModal from './components/modal/error-form/ErrorFormModal';

import PrivacyContainer from './containers/privacy/PrivacyContainer';
import SignInContainer from './containers/signin/SignInContainer';
import RouterContainer from './containers/RouterContainer';
import PrivacyPolicyContainer from './containers/privacy-policy/PrivacyPolicyContainer';
import EulaContainer from './containers/eula/EulaContainer';

import NotFound from './shared/NotFound/NotFound';

import { handleUserSignOut } from "./store/actions/UserActions"

import { storageRead } from "./services/storage/storage"
import { authCurrentSession } from "./services/auth"

import * as K from "./utils/Constant"
import { navigateToExternalUrl } from "./utils/Helpres"
import { DEFAULT_SET_COMPANY_ICON_CONFIG_KEY } from "./utils/Constant"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './vendor/styles/bootstrap.scss'
import './vendor/styles/platform.scss'
import './vendor/styles/theme-corporate.scss'
import './vendor/styles/colors.scss'
import './vendor/styles/uikit.scss'
import './App.scss'
import './styles/scss/_style.scss'
import './vendor/styles/amplify.css'


Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: false
  }
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authData: {},
      companyIcon: null,
      error: null, 
      errorInfo: null
    };
  }

  componentDidMount() {
    Hub.listen(K.HUB_AUTOSIGNIN, (data) => {
      this.reloadAuthData(true)
    })
    Hub.listen("auth", (data) => {
      if (data?.payload?.event === "signIn" && this.state.authData?.username == null) {
        this.reloadAuthData(true)
      } else if (data?.payload?.event === "signOut") {
        this.setState({authData: {}})
      }
    })
    Hub.listen(K.HUB_UPDATEUSERATTRIBUTES, (data) => {
      this.reloadAuthData(false)
    })
    Hub.listen(K.HUB_ENDSESSION, (data) => {
      this.props.handleUserSignOut()
    })
    this.reloadAuthData(false)

    this.iconLoader()
    this.handleDeepLink()
  }

  componentWillUnmount() {
    Hub.remove(K.HUB_AUTOSIGNIN)
    Hub.remove("auth")
    Hub.remove(K.HUB_UPDATEUSERATTRIBUTES)
    Hub.remove(K.HUB_ENDSESSION)
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  iconLoader() {
    const key = DEFAULT_SET_COMPANY_ICON_CONFIG_KEY
    storageRead(key)
      .then(data => document.getElementById("favicon").href = data)
      .catch(err => console.error("READ FILE ERROR ", err))
  }

  handleDeepLink = () => {
    try {
      const deeplink = window?.location?.href?.split("share=")[1]?.split("&")[0]
      if (deeplink && localStorage.getItem("deeplink") !== deeplink) {
        localStorage.setItem("deeplink", deeplink)
        navigateToExternalUrl(window?.location?.origin, false)
      }
    } catch (e) {
      console.error("error with deep link", e)
    }
  }

  reloadAuthData(isNewSession) {
    Auth.currentAuthenticatedUser({
      bypassCache: true  // If true, will request latest auth data
    })
    .then(authData => {
      if (this.state.authData?.username == null) {
        authCurrentSession(authData?.attributes["custom:last_session"], isNewSession)
      }
      if (authData?.username != null) {
        this.setState({authData: authData})
      }      
    })
    .catch(err => 
      console.error('AUTH - ERROR AUTH', err)
    )
  }

  render() {    
    if (this.state.error) {
      return  <ErrorFormModal 
                show={true} 
                userEmail={this.state.authData?.attributes?.email ?? ""}
                error={this.state.error}
                errorInfo={this.state.errorInfo?.componentStack} 
              />
    }
    return (
      <>
        {this.state.authData?.username != null ?
          <>
            <RouterContainer authData={this.state.authData} />
            <ToastContainer
              position="top-right"
              autoClose={7000}
              limit={10}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </> :
        <AppRouter basename={process.env.REACT_APP_BASENAME}>
          <Switch>
            <Route path="/privacy" component={PrivacyContainer} />
            <Route path="/eula" component={EulaContainer} />
            <Route path="/privacy-policy" component={PrivacyPolicyContainer} />
            <Route path="/" component={SignInContainer} />
            <Route path="*" component={NotFound} />
          </Switch>
        </AppRouter>
        }
      </>
    )
  } 
}

const mapStateToProps = store => {
  return {
    account: store.account
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleUserSignOut : () => dispatch(handleUserSignOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
