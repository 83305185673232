import {
  eventCreate,
  eventRead,
  eventUpdate,
  eventDelete,
  eventObserve,
  deleteEventObservers,
  eventReadCopytrading
} from "../../services/api/event"

export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST'
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS'
export const EVENT_CREATE_FAIL = 'EVENT_CREATE_FAIL'

export const EVENT_READ_REQUEST = 'EVENT_READ_REQUEST'
export const EVENT_READ_SUCCESS = 'EVENT_READ_SUCCESS'
export const EVENT_READ_FAIL = 'EVENT_READ_FAIL'

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST'
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL'

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST'
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL'

export function handleEventCreate(owner, topic, event, payload) {
  return dispatch => {
    dispatch({
      type: EVENT_CREATE_REQUEST,
      payload: { owner: owner, topic: topic, event: event, payload: payload },
    })
    eventCreate(owner, topic, event, payload)
    .then(event => {    
      dispatch({
        type: EVENT_CREATE_SUCCESS,
        payload: event,        
      })
    })
    .catch(err => {
      dispatch({
        type: EVENT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleEventRead(owner) {
  return dispatch => {
    dispatch({
      type: EVENT_READ_REQUEST,
      payload: owner,
    })
    eventRead(owner)
    .then(data => {
      dispatch({
        type: EVENT_READ_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {      
      dispatch({
        type: EVENT_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleEventUpdate(owner, eventId, payload) {
  return dispatch => {
    dispatch({
      type: EVENT_UPDATE_REQUEST,
      payload: { owner: owner, eventId: eventId, payload: payload },
    })
    eventUpdate(owner, eventId, payload)
    .then(data => {
      dispatch({
        type: EVENT_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: EVENT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleEventDelete(owner, eventId) {
  return dispatch => {
    dispatch({
      type: EVENT_DELETE_REQUEST,
      payload: { owner: owner, eventId: eventId },
    })
    eventDelete(owner, eventId)
    .then(data => {
      dispatch({
        type: EVENT_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: EVENT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function observeEvent(owner) {
  return dispatch => {
    eventObserve(owner)
  }
}

export function handleEventObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: EVENT_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: EVENT_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: EVENT_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleEventObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        dispatch({
          type: EVENT_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: EVENT_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: EVENT_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleEventUnsubscribe() {
  return dispatch => {
    deleteEventObservers()
  }
}