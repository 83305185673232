import { workspaceCreate, workspaceRead, workspaceUpdate, workspaceDelete }  from '../../services/api/workspace'
import { toastSuccess, toastError } from "../../shared/platform/Toast"

export const WORKSPACE_CREATE_REQUEST = 'WORKSPACE_CREATE_REQUEST'
export const WORKSPACE_CREATE_SUCCESS = 'WORKSPACE_CREATE_SUCCESS'
export const WORKSPACE_CREATE_FAIL = 'WORKSPACE_CREATE_FAIL'

export const WORKSPACE_READ_REQUEST = 'WORKSPACE_READ_REQUEST'
export const WORKSPACE_READ_SUCCESS = 'WORKSPACE_READ_SUCCESS'
export const WORKSPACE_READ_FAIL = 'WORKSPACE_READ_FAIL'

export const WORKSPACE_UPDATE_REQUEST = 'WORKSPACE_UPDATE_REQUEST'
export const WORKSPACE_UPDATE_SUCCESS = 'WORKSPACE_UPDATE_SUCCESS'
export const WORKSPACE_UPDATE_FAIL = 'WORKSPACE_UPDATE_FAIL'

export const WORKSPACE_DELETE_REQUEST = 'WORKSPACE_DELETE_REQUEST'
export const WORKSPACE_DELETE_SUCCESS = 'WORKSPACE_DELETE_SUCCESS'
export const WORKSPACE_DELETE_FAIL = 'WORKSPACE_DELETE_FAIL'

export const WORKSPACE_CREATE_SELECT = 'WORKSPACE_CREATE_SELECT'

export function handleWorkspaceCreate(owner, user, name, position) {
  return dispatch => {
    dispatch({
      type: WORKSPACE_CREATE_REQUEST,
      payload: { owner: owner, user: user, name: name, position: position},
    })
    workspaceCreate(owner, user, name, position)
    .then(data => {
      dispatch({
        type: WORKSPACE_CREATE_SUCCESS,
        payload: data,        
      })
      toastSuccess('toast_text_3')
    })
    .catch(err => {
      dispatch({
        type: WORKSPACE_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
      toastError('toast_text_4')
    })
  }
}

export function handleWorkspaceRead(owner) {
  return dispatch => {
    dispatch({
      type: WORKSPACE_READ_REQUEST,
      payload: owner,
    })
    workspaceRead(owner)
    .then(data => {
      dispatch({
        type: WORKSPACE_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: WORKSPACE_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleWorkspaceUpdate(owner, workspaceId, name, position, lastActiveWidgetId) {
  return dispatch => {
    dispatch({
      type: WORKSPACE_UPDATE_REQUEST,
      payload: { owner: owner, workspaceId: workspaceId, name: name, position: position, lastActiveWidgetId: lastActiveWidgetId},
    })
    workspaceUpdate(owner, workspaceId, name, position, lastActiveWidgetId)
    .then(data => {
      dispatch({
        type: WORKSPACE_UPDATE_SUCCESS,
        payload: data,        
      })
      toastSuccess('toast_text_5')
    })
    .catch(err => {
      dispatch({
        type: WORKSPACE_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
      toastError('toast_text_6')
    })
  }
}

export function handleWorkspaceDelete(owner, workspaceId) {
  return dispatch => {
    dispatch({
      type: WORKSPACE_DELETE_REQUEST,
      payload: { owner: owner, workspaceId: workspaceId },
    })
    workspaceDelete(owner, workspaceId)
    .then(data => {
      dispatch({
        type: WORKSPACE_DELETE_SUCCESS,
        payload: data,
      })
      toastSuccess('toast_text_7')
    })
    .catch(err => {
      dispatch({
        type: WORKSPACE_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
      toastError('toast_text_8')
    })
  }
}

export function handleWorkspaceSelect(workspace) {
  if (workspace == null) { return }
  return dispatch => {
    dispatch({
      type: WORKSPACE_CREATE_SELECT,
      payload: workspace,
    })
  }
}