import React from 'react';
import { Button, Heading, View, useAuthenticator, useTheme } from '@aws-amplify/ui-react';

const AuthHeader = ({ children }) => (
  <View style={{ paddingBottom: '20px' }}>
    <Heading level={4}>{children}</Heading>
  </View>
)

const SignInHeader = () => <AuthHeader>Sign in to your account</AuthHeader>

const SignInFooter = () => {
  const { tokens } = useTheme();
  const { toResetPassword } = useAuthenticator();

  return (
    <View textAlign="center" padding={tokens.space.small}>
      Forgot your password?
      <Button
        fontWeight="normal"
        onClick={toResetPassword}
        size="small"
        variation="link"
      >
        Reset Password
      </Button>
    </View>
  )
}

const ForgotPasswordHeader = () => <AuthHeader>Reset your password</AuthHeader>

const ConfirmResetPasswordHeader = () =>  <AuthHeader>Reset your password</AuthHeader>

const ConfirmResetPasswordFooter = () => {
  const { tokens } = useTheme();
  const { toSignIn } = useAuthenticator();

  return (
    <View textAlign="center" padding={tokens.space.zero}>
      <Button
        fontWeight="normal"
        onClick={toSignIn}
        size="small"
        variation="link"
      >
        Back to Sign in
      </Button>
    </View>
  )
}

const SignUpHeader = () => <AuthHeader>Create a new account</AuthHeader>

const SignUpFooter = () => {
  const { tokens } = useTheme();
  const { toSignIn } = useAuthenticator();

  return (
    <View textAlign="center" padding={tokens.space.small}>
      Have an account?
      <Button
        fontWeight="normal"
        onClick={toSignIn}
        size="small"
        variation="link"
      >
        Back to Sign in
      </Button>
    </View>
  )
}

const ConfirmSignUpHeader = () => <AuthHeader>Verification code was sent to your email</AuthHeader>

export { SignInHeader, SignInFooter, ForgotPasswordHeader, ConfirmResetPasswordHeader, ConfirmResetPasswordFooter, SignUpHeader, SignUpFooter, ConfirmSignUpHeader }
