import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import cn from "classname"

import { withTranslation } from 'react-i18next';

import "./DisclamerModal.scss"

class DisclamerModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: ""
    }
  }

  render() {
    const { t } = this.props;
    
    return (
      <Modal 
        {...this.props}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal__modal"
      >
        <div className={cn("n-modal", "disclamer-modal")}>
          <div className="n-modal__close" onClick={this.props.onHide}>
            <svg
              width="20rem"
              height="20rem"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
              />
            </svg>
          </div>
          <div className="n-modal__header">
            <div className="n-modal__header__title-red">{t("copytrading_modal_disclamer_title")}</div>
          </div>
          <div className="n-modal__wrapper">
            {this.props.data.configCopytrading}
          </div>
          <div className="n-modal__wrapper">
            <button 
              className="n-modal__btns__confirm-red"
              onClick={() => {
                this.props.data.onContinue()
                this.props.onHide()
              }} 
            >
              {t('copytrading_modal_disclamer_button')}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(DisclamerModal)