import { userProductCreate, getUserProductRead, userProductDelete, userProductObserve }  from '../../services/api/userproduct'

export const USER_PRODUCT_CREATE_REQUEST = 'USER_PRODUCT_CREATE_REQUEST'
export const USER_PRODUCT_CREATE_SUCCESS = 'USER_PRODUCT_CREATE_SUCCESS'
export const USER_PRODUCT_CREATE_FAIL = 'USER_PRODUCT_CREATE_FAIL'

export const USER_PRODUCT_READ_REQUEST = 'USER_PRODUCT_READ_REQUEST'
export const USER_PRODUCT_READ_SUCCESS = 'USER_PRODUCT_READ_SUCCESS'
export const USER_PRODUCT_READ_FAIL = 'USER_PRODUCT_READ_FAIL'

export const USER_PRODUCT_UPDATE_REQUEST = 'USER_PRODUCT_UPDATE_REQUEST'
export const USER_PRODUCT_UPDATE_SUCCESS = 'USER_PRODUCT_UPDATE_SUCCESS'
export const USER_PRODUCT_UPDATE_FAIL = 'USER_PRODUCT_UPDATE_FAIL'

export const USER_PRODUCT_DELETE_REQUEST = 'USER_PRODUCT_DELETE_REQUEST'
export const USER_PRODUCT_DELETE_SUCCESS = 'USER_PRODUCT_DELETE_SUCCESS'
export const USER_PRODUCT_DELETE_FAIL = 'USER_PRODUCT_DELETE_FAIL'

export function handleUserProductCreate(user, product, trial) {
  return dispatch => {
    dispatch({
      type: USER_PRODUCT_CREATE_REQUEST,
      payload: { user: user, product: product, trial: trial },
    })
    userProductCreate(user, product, trial)
    .then(data => {
      dispatch({
        type: USER_PRODUCT_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: USER_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserProductRead(userId) {
  return dispatch => {
    dispatch({
      type: USER_PRODUCT_READ_REQUEST,
      payload: {userId : userId},
    })
    getUserProductRead(userId)
    .then(data => {
      dispatch({
        type: USER_PRODUCT_READ_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {      
      dispatch({
        type: USER_PRODUCT_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserProductDelete(userProductId) {
  return dispatch => {
    dispatch({
      type: USER_PRODUCT_DELETE_REQUEST,
      payload: { userProductId: userProductId },
    })
    userProductDelete(userProductId)
    .then(data => {
      dispatch({
        type: USER_PRODUCT_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: USER_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function observeUserProduct(userId) {
  return dispatch => {
    userProductObserve(userId)
  }
}

export function handleUserProductObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: USER_PRODUCT_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: USER_PRODUCT_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: USER_PRODUCT_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleUserProductObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        dispatch({
          type: USER_PRODUCT_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: USER_PRODUCT_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: USER_PRODUCT_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}