import { reactionCreate, reactionRead, reactionUpdate, reactionDelete }  from '../../services/api/reaction'

export const REACTION_CREATE_REQUEST = 'REACTION_CREATE_REQUEST'
export const REACTION_CREATE_SUCCESS = 'REACTION_CREATE_SUCCESS'
export const REACTION_CREATE_FAIL = 'REACTION_CREATE_FAIL'

export const REACTION_READ_REQUEST = 'REACTION_READ_REQUEST'
export const REACTION_READ_SUCCESS = 'REACTION_READ_SUCCESS'
export const REACTION_READ_FAIL = 'REACTION_READ_FAIL'

export const REACTION_UPDATE_REQUEST = "REACTION_UPDATE_REQUEST";
export const REACTION_UPDATE_SUCCESS = "REACTION_UPDATE_SUCCESS";
export const REACTION_UPDATE_FAIL = "REACTION_UPDATE_FAIL";

export const REACTION_DELETE_REQUEST = 'REACTION_DELETE_REQUEST'
export const REACTION_DELETE_SUCCESS = 'REACTION_DELETE_SUCCESS'
export const REACTION_DELETE_FAIL = 'REACTION_DELETE_FAIL'

export function handleReactionCreate(owner, user, reaction, message) {
  return dispatch => {
    dispatch({
      type: REACTION_CREATE_REQUEST,
      payload: { owner: owner, user: user, reaction: reaction, message: message },
    })
    reactionCreate(owner, user, reaction, message)
    .then(data => {
      dispatch({
        type: REACTION_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: REACTION_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleReactionRead(owner) {
  return dispatch => {
    dispatch({
      type: REACTION_READ_REQUEST,
      payload: owner,
    })
    reactionRead(owner)
    .then(data => {
      dispatch({
        type: REACTION_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: REACTION_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleReactionUpdate(reactionId, reaction) {
  return dispatch => {
    dispatch({
      type: REACTION_UPDATE_REQUEST, payload: { reactionId: reactionId, reaction: reaction }
    });
    reactionUpdate(reactionId, reaction)
      .then(data => {
        dispatch({
          type: REACTION_UPDATE_SUCCESS, payload: data
        });
      })
      .catch(err => {
        dispatch({
          type: REACTION_UPDATE_FAIL, error: true, payload: new Error(err)
        });
      });
  };
}

export function handleReactionDelete(owner, reactionId) {
  return dispatch => {
    dispatch({
      type: REACTION_DELETE_REQUEST,
      payload: { owner: owner, reactionId: reactionId },
    })
    reactionDelete(owner, reactionId)
    .then(data => {
      dispatch({
        type: REACTION_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: REACTION_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}