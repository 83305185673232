import { Modal } from "react-bootstrap"
import React, { Component } from "react"
// import FileViewer from "react-file-viewer"
// import DocViewer from "react-doc-viewer";
import mammoth from 'mammoth';

import './doc.scss'
class DOC extends Component {
  constructor(props) {
    super(props)
    this.state = {
      docView: ''
    }
  }

  componentDidMount() {
    const jsonFile = new XMLHttpRequest();
    jsonFile.open('GET', this.props.data.url, true);
    jsonFile.send();
    jsonFile.responseType = 'arraybuffer';
    jsonFile.onreadystatechange = () => {
      if (jsonFile.readyState === 4 && jsonFile.status === 200) {
        mammoth.convertToHtml(
          { arrayBuffer: jsonFile.response },
          { includeDefaultStyleMap: true },
        )
        .then((result) => {
          const docEl = document.createElement('div');
          docEl.className = 'document-container';
          docEl.innerHTML = result.value;
          document.getElementById('docx').innerHTML = docEl.outerHTML;
          this.setState({ docView: document.getElementById('docx').innerHTML = docEl.outerHTML })
        })
        .catch((err) => {
          console.error('alexei: something went wrong', err);
        })
        .done();
      }
    };
  }
  
  onError = e => {
    console.error(e, "error in file-viewer");
  };

  render() {
    return (
      <Modal {...this.props} className={'modal-dialog--fullscreen'} fullscreen>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div   
            style={{
              width: "100%",
              height: "83vh"
            }}
          >
            {this.props.data.url && 
              <div className="pg-viewer-wrapper">
                <div className="pg-viewer" id="pg-viewer">
                  <div dangerouslySetInnerHTML={{ __html: this.state.docView }} />
                </div>
              </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default DOC