import {
  SHOW_MODAL,
} from '../actions/ModalActions'

const initialState = {
  presented: null,
  data: null,
}

export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, presented: action.payload?.modal, data: action.payload?.data }
    default:
      return state
  }
}