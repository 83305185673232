import React, { Component } from "react"

import { storageRead } from "../../services/storage/storage"
import * as K from "../../utils/Constant"

import logo from "./econq-logo.svg"

import "../../vendor/styles/pages/authentication.scss"
import "./AuthTheme.scss"

class AuthTheme extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyLogo: null,
      companyName: '',
      companyDescription: '',
      backgroundAuthorizationTheme: '',
      colorCompanyLogoDescription: '',
      colorCompanyName: '',
      backgroundFormAuthorizationTheme: '',
      colorTitleFormAuthorizationTheme: ''
    }
  }

  componentDidMount() {
    this.fetchStorageData()
  }

  fetchStorageData = () => {
    this.fetchCompanyLogo()
    this.fetchCompanyName()
    this.fetchCompanyDescription()
    this.fetchBackgraundAuthTeheme()
    this.fetchColorCompanyLogoDescription()
    this.fetchColorCompanyName()
    this.fetchBackgraundFormAuthTeheme()
    this.fetchColorTitleFormAuthTeheme()
  }
  fetchCompanyLogo() {
    const key = K.DEFAULT_SET_COMPANY_LOGO_CONFIG_KEY
    storageRead(key)
      .then(data => {
        this.setState({ companyLogo: data })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  fetchCompanyName() {
    const key = K.DEFAULT_SET_COMPANY_NAME_CONFIG_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ companyName: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  fetchCompanyDescription() {
    const key = K.DEFAULT_SET_DESCRIPTION_COMPANY_LOGO_CONFIG_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ companyDescription: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  fetchBackgraundAuthTeheme() {
    const key = K.DEFAULT_SET_BACKGROUND_AUTH_THEME_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ backgroundAuthorizationTheme: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  fetchColorCompanyLogoDescription() {
    const key = K.DEFAULT_SET_COLOR_COMPANY_DESCRIPTION_AUTH_THEME_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ colorCompanyLogoDescription: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  fetchColorCompanyName() {
    const key = K.DEFAULT_SET_COLOR_COMPANY_NAME_AUTH_THEME_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ colorCompanyName: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err)) 
  }
  fetchBackgraundFormAuthTeheme() {
    const key = K.DEFAULT_SET_BACKGROUND_FORM_AUTH_THEME_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ backgroundFormAuthorizationTheme: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  fetchColorTitleFormAuthTeheme() {
    const key = K.DEFAULT_SET_COLOR_TITLE_FORM_AUTH_THEME_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ colorTitleFormAuthorizationTheme: xmlHttp.responseText })
      })
      .catch(err => console.error("READ FILE ERROR ", err))
  }
  
  render() {
    const amplifyPrimaryColor = '#26B4FF'
    const amplifyPrimaryTin = '#26B'
    const amplifyPrimaryShade = '#26B4FF'
    const { 
      backgroundAuthorizationTheme, 
      colorCompanyLogoDescription, 
      colorCompanyName, 
      backgroundFormAuthorizationTheme,
      colorTitleFormAuthorizationTheme
    } = this.state

    return (
      <div 
        style={{
          background: backgroundAuthorizationTheme,
          "--amplify-background-color": backgroundFormAuthorizationTheme,
          "--amplify-secondary-color": colorTitleFormAuthorizationTheme,
          "--amplify-primary-color": amplifyPrimaryColor,
          "--amplify-primary-tin": amplifyPrimaryTin,
          "--amplify-primary-shade": amplifyPrimaryShade
        }}
        className="authentication-wrapper authentication-3"
      >
        <div className="authentication-inner">
          <div className="d-none d-lg-flex col-lg-6 align-items-center p-5 theme-bg-white">
            <div className="w-100">
              <h4 className="text-center mt-5 mb-0">
                <div className="authentication-form d-flex align-items-center flex-column text-center">
                  <img
                    alt=""
                    className="authentication-form__image align-items-center text-center"
                    src={this.state.companyLogo}
                  />
                  <div className="authentication-form__logo-name text-center" style={{color: colorCompanyName}}>
                    {this.state.companyName}
                  </div>
                  <div className="authentication-form__logo-description text-center" style={{color: colorCompanyLogoDescription}}>
                    {this.state.companyDescription}
                  </div>
                  <div className="authentication-form__bottom-logo text-center" style={{color: colorCompanyName}}>
                    Powered by
                  </div>
                  <div className="authentication-form__bottom-description text-center">
                    <a
                      href="https://www.econq.com"
                      title="Powered by Econq"
                      target="_blank"
                    >
                      <img src={logo} alt={"logo"} />
                    </a>
                  </div>
                  <div className="authentication-form__privacy-policy text-center">
                    <a
                      href="/privacy"
                      title="Powered by Econq"
                      target="_blank"
                      style={{color: colorCompanyLogoDescription}}
                    >
                      Privacy policy
                    </a>
                  </div>
                </div>
              </h4>
            </div>
          </div>

          <div className="d-flex col-lg-12 align-items-center theme-bg-white">
            <div className="d-flex col-sm-12 col-md-12 col-lg-12 px-0 px-xl-12 mx-auto">
              <div className="authentication-inner">
                <div className="w-100 amplify-form-custom">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AuthTheme
