import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

import './ModalProgressBar.scss'

class ModalProgressBar extends Component {
  render() {
    return (
      <Modal {...this.props} centered size="lg" aria-labelledby="contained-modal-title-vcenter" className="modal-progress-bar">
        <div className="loader">Loading...</div>
        {/* <ProgressBar now={this.props.fileProgress} label={`${this.props.fileProgress}%`} /> */}
      </Modal>
    );
  }
}

export default ModalProgressBar