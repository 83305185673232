import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listProducts, getProduct } from "../../graphql/queries";
import { createProduct, deleteProduct, updateProduct } from "../../graphql/mutations";
import { onCreateProduct, onUpdateProduct, onDeleteProduct } from "../../graphql/subscriptions";
import * as K from '../../utils/Constant'
import CurrencyFormatter from 'currencyformatter.js';
import { uuidv4 } from "../../utils/Helpres"

import { loadList } from "./shared"
import { validateProductUpdate } from "../validation/products"
const action = listProducts
const attribute = "listProducts"

export const HUB_OBSERVER_PRODUCT = "product"

let subscriptions = []

export const productCreate = async (bundle, name, description, isPublic, item, features, products, price, currency, media) => {
  try {
    const foundProducts = await loadList(action, attribute, { bundle: { eq: bundle } }, 1)
    const foundProduct = foundProducts?.find(pr => pr != null)
    if (foundProduct != null) {
      console.error('Product already exist:', foundProduct)
      return foundProduct
    }
  } catch (err) {
    console.error('error fetching products', err)
  }
  if (bundle == null || name == null || isPublic == null || price == null || currency == null || (item == null && features == null && products == null)) { 
    console.error('Product API: Required fields cannot be empty!')
    return null
  }
  try {
    const response = await API.graphql(graphqlOperation(createProduct, {input: {
      bundle: bundle, 
      name: name,
      description: description, 
      public: isPublic, 
      item: item, 
      features: features, 
      products: products, 
      price: price, 
      currency: currency,
      media: media,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createProduct
  } catch (err) {
    console.error('error creating product:', err)
    return null
  }
}

export const myProductRead = async (ids) => {
  try {
    const filter = {
      or: ids?.map(itemId => {
        return {
          id: {
            eq: itemId,
          },
        }
      }) ?? [],
    }
    const products = await loadList(action, attribute, filter, ids?.length)
    return products
  } catch (err) { 
    console.error('error fetching products', err)
    return null
  }
}

export const paidProductRead = async () => {
  try {
    const products = await loadList(action, attribute, { public: { eq: true }}, 0)
    return products
  } catch (err) {
    console.error('error fetching feature products', err)
    return null
  }
}

export const productRead = async () => {
  try {
    const products = await loadList(action, attribute, null, 0)
    return products
  } catch (err) { 
    console.error('error fetching products', err)
    return null
  }
}

export const productUpdate = async (productId, name, description, features, isPublic, price, trial, item, products, media) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getProduct, 
      { id: productId }
    ))
    original = exist?.data?.getProduct
  } catch (err) { console.error('error fetching product:', err) }
  if (original?.id === productId) {
    if (!validateProductUpdate(original, productId, name, description, features, isPublic, price, trial, item, products, media)) { return }
    try {
      const response = await API.graphql(graphqlOperation(updateProduct, {input: {
        id: productId, 
        name: name,
        description: description, 
        features: features,
        item: item,
        products: products,
        public: isPublic,
        media: media ?? original?.media,
        price: price,
        trial: trial,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateProduct
    } catch (err) {
      console.error('error updating product:', err)
      return
    }
  } else {
    return null
  }
}

export const productDelete = async (productId) => {
  await API.graphql(graphqlOperation(deleteProduct, { input: { id: productId }}));
  return productId
}

export const productObserve = async (productId) => {
  if ( productId == null || subscriptions.some(sub => sub.productId === productId) ) { return }
  const createSubscription = API.graphql(graphqlOperation(onCreateProduct, { id: productId } )).subscribe({
    next: response => {
      const product = response.value.data.onCreateProduct
      Hub.dispatch(HUB_OBSERVER_PRODUCT, {event: 'create', data: product, message:'create product observe' });  
    },
  })
  const createEvent = 'INSERT' 
  subscriptions.push({productId, createSubscription, createEvent})
  const updateSubscription = API.graphql(graphqlOperation(onUpdateProduct, { id: productId } )).subscribe({
    next: response => {
      const product = response.value.data.onUpdateProduct
      Hub.dispatch(HUB_OBSERVER_PRODUCT, {event: 'update', data: product, message:'create product observe' });  
    },
  })
  const updateEvent = 'UPDATE'
  subscriptions.push({productId, updateSubscription, updateEvent})  
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteProduct, { id: productId } )).subscribe({
    next: response => {
      const product = response.value.data.onDeleteProduct
      Hub.dispatch(HUB_OBSERVER_PRODUCT, {event: 'delete', data: product, message:'create product observe' });  
    },
  })
  const deleteEvent = 'DELETE'  
  subscriptions.push({productId, deleteSubscription, deleteEvent})
}

export async function deleteProductsObservers(productId) {
  if (productId != null) {
    for (let sub of subscriptions) {
      if (sub.productId === productId && sub.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
  }
}

export async function replaceAllProductCurrency(currency) {
  const symbol = CurrencyFormatter.getFormatDetails({currency: currency})?.symbol
  if (symbol == null) { return }
  try {
    const products = await loadList(action, attribute, null, 0)
    const result = products?.filter(product => product?.currency !== currency)?.forEach(product => {
      try {
        API.graphql(graphqlOperation(updateProduct, {input: {
            id: product?.id,
            currency: currency,
            updatedAt: new Date().toISOString() }}))
      } catch (err) {
        console.error('error updating product:', err)
      }
    })
  } catch (err) {
    console.error('error fetching products', err)
  }
}

export async function fillDefaultProducts(permissions) {
  var products =[]
  var product = null
  product = await productCreate(K.ADMIN_PLAN, "Admin plan", "Admin product - maximum permissions", false, null, fillAdminPlanPermissions(permissions), null, 0, K.CURRENCY_USD)
  if (product != null && product?.bundle === K.ADMIN_PLAN) { products.push(product) }

  product = await productCreate(K.BASE_PLAN, "Base plan", "Base product - minimum permissions", false, null, fillBasePlanPermissions(permissions), null, 0, K.CURRENCY_USD)
  if (product != null && product?.bundle === K.BASE_PLAN) { products.push(product) }

  const freeBundle = `${K.PRODUCT_PREFIX_PLAN}-${uuidv4()}`
  product = await productCreate(freeBundle, "Public free plan", "Free product - minimum permissions", true, null, fillBasePlanPermissions(permissions), null, 0, K.CURRENCY_USD)
  if (product != null && product?.bundle === freeBundle) { products.push(product) }

  const paid10Bundle = `${K.PRODUCT_PREFIX_PLAN}-${uuidv4()}`
  product = await productCreate(paid10Bundle, "Public 10 plan", "Paid 10 product - more permissions", true, null, fillPublic10PlanPermissions(permissions), null, 10, K.CURRENCY_USD)
  if (product != null && product?.bundle === paid10Bundle) { products.push(product) }

  const paid25Bundle = `${K.PRODUCT_PREFIX_PLAN}-${uuidv4()}`
  product = await productCreate(paid25Bundle, "Public 25 plan", "Paid 25 product - extra more permissions", true, null, fillPublic25PlanPermissions(permissions), null, 25, K.CURRENCY_USD)
  if (product != null && product?.bundle === paid25Bundle) { products.push(product) }

  return products
}

function fillAdminPlanPermissions(permissions) {
  var planPerms = []
  // Create Admin role permissions
  for (let p of permissions) {
    if (p.module === K.FEATURE_MODULE) {
      if (p.name === K.FEATURE_USER_INCOGNITO_MODE) { planPerms.push( { position: 0, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL) { planPerms.push( { position: 1, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS) { planPerms.push( { position: 2, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY) { planPerms.push( { position: 3, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS) { planPerms.push( { position: 4, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS) { planPerms.push( { position: 5, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE) { planPerms.push( { position: 6, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE) { planPerms.push( { position: 7, permissionId: p.id, permission: p.name, enabled: true, policy: "100000000" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES) { planPerms.push( { position: 8, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_RICH_MESSAGES) { planPerms.push( { position: 9, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES) { planPerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES) { planPerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS) { planPerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: true, policy: "1000000" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL) { planPerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM) { planPerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER) { planPerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API) { planPerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS) { planPerms.push( { position: 17, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
    }
  }
  return planPerms
}

function fillBasePlanPermissions(permissions) {
  var planPerms = []
  // Create Retail role permissions
  for (let p of permissions) {
    if (p.module === K.FEATURE_MODULE) {
      if (p.name === K.FEATURE_USER_INCOGNITO_MODE) { planPerms.push( { position: 0, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL) { planPerms.push( { position: 1, permissionId: p.id, permission: p.name, enabled: true, policy: "0" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS) { planPerms.push( { position: 2, permissionId: p.id, permission: p.name, enabled: true, policy: "5" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY) { planPerms.push( { position: 3, permissionId: p.id, permission: p.name, enabled: true, policy: "10" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS) { planPerms.push( { position: 4, permissionId: p.id, permission: p.name, enabled: true, policy: "1" }) }
      if (p.name === K.FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS) { planPerms.push( { position: 5, permissionId: p.id, permission: p.name, enabled: true, policy: "0" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE) { planPerms.push( { position: 6, permissionId: p.id, permission: p.name, enabled: true, policy: "160" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE) { planPerms.push( { position: 7, permissionId: p.id, permission: p.name, enabled: true, policy: "0" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES) { planPerms.push( { position: 8, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_RICH_MESSAGES) { planPerms.push( { position: 9, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES) { planPerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: true, policy: "0" }) }
      if (p.name === K.FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES) { planPerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS) { planPerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: true, policy: "1" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL) { planPerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM) { planPerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER) { planPerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API) { planPerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS) { planPerms.push( { position: 17, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
    }    
  }
  return planPerms
}

function fillPublic10PlanPermissions(permissions) {
  var planPerms = []
  // Create Retail role permissions
  for (let p of permissions) {
    if (p.module === K.FEATURE_MODULE) {
      if (p.name === K.FEATURE_USER_INCOGNITO_MODE) { planPerms.push( { position: 0, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL) { planPerms.push( { position: 1, permissionId: p.id, permission: p.name, enabled: true, policy: "1" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS) { planPerms.push( { position: 2, permissionId: p.id, permission: p.name, enabled: true, policy: "5" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY) { planPerms.push( { position: 3, permissionId: p.id, permission: p.name, enabled: true, policy: "100" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS) { planPerms.push( { position: 4, permissionId: p.id, permission: p.name, enabled: true, policy: "5" }) }
      if (p.name === K.FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS) { planPerms.push( { position: 5, permissionId: p.id, permission: p.name, enabled: true, policy: "5" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE) { planPerms.push( { position: 6, permissionId: p.id, permission: p.name, enabled: true, policy: "160" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE) { planPerms.push( { position: 7, permissionId: p.id, permission: p.name, enabled: true, policy: "0" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES) { planPerms.push( { position: 8, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_RICH_MESSAGES) { planPerms.push( { position: 9, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES) { planPerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: true, policy: "0" }) }
      if (p.name === K.FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES) { planPerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS) { planPerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: true, policy: "3" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL) { planPerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM) { planPerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER) { planPerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API) { planPerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS) { planPerms.push( { position: 17, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
    }    
  }
  return planPerms
}

function fillPublic25PlanPermissions(permissions) {
  var planPerms = []
  // Create Retail role permissions
  for (let p of permissions) {
    if (p.module === K.FEATURE_MODULE) {
      if (p.name === K.FEATURE_USER_INCOGNITO_MODE) { planPerms.push( { position: 0, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_USERS_IN_A_PRIVATE_CHANNEL) { planPerms.push( { position: 1, permissionId: p.id, permission: p.name, enabled: true, policy: "5" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_OWN_PRIVATE_CHANNELS) { planPerms.push( { position: 2, permissionId: p.id, permission: p.name, enabled: true, policy: "20" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_DEPTH_OF_VISIBLE_MESSAGES_HISTORY) { planPerms.push( { position: 3, permissionId: p.id, permission: p.name, enabled: true, policy: "1000" }) }
      if (p.name === K.FEATURE_CHANNEL_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_PUBLIC_CHANNELS) { planPerms.push( { position: 4, permissionId: p.id, permission: p.name, enabled: true, policy: "10" }) }
      if (p.name === K.FEATURE_LABEL_MAXIMUM_NUMBER_OF_LABELS) { planPerms.push( { position: 5, permissionId: p.id, permission: p.name, enabled: true, policy: "20" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_LENGTH_OF_A_PLAIN_MESSAGE) { planPerms.push( { position: 6, permissionId: p.id, permission: p.name, enabled: true, policy: "500" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_IMAGE_SIZE) { planPerms.push( { position: 7, permissionId: p.id, permission: p.name, enabled: true, policy: "512000" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_NON_IMAGE_MEDIA_FILES) { planPerms.push( { position: 8, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_MESSAGE_SENDING_RICH_MESSAGES) { planPerms.push( { position: 9, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_MESSAGE_MAXIMUM_NUMBER_OF_AVAILABLE_USER_TEMPLATES) { planPerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: true, policy: "3" }) }
      if (p.name === K.FEATURE_MESSAGE_USING_FACTORY_TEMPLATES_IN_RICH_MESSAGES) { planPerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_EXTRA_MAXIMUM_NUMBER_OF_SUBSCRIBED_FREE_EXTRAS) { planPerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: true, policy: "3" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_EMAIL) { planPerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_TELEGRAM) { planPerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: true, policy: "true" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_BROKER) { planPerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_FORWARDING_MESSAGES_TO_API) { planPerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
      if (p.name === K.FEATURE_INTEGRATION_RETRIEVING_MESSAGES_VIA_RSS) { planPerms.push( { position: 17, permissionId: p.id, permission: p.name, enabled: true, policy: "false" }) }
    }    
  }
  return planPerms
}