import { configCreate, configRead, configUpdate, configDelete } from "../../services/api/config";

export const CONFIGS_CREATE_REQUEST = "CONFIGS_CREATE_REQUEST";
export const CONFIGS_CREATE_SUCCESS = "CONFIGS_CREATE_SUCCESS";
export const CONFIGS_CREATE_FAIL = "CONFIGS_CREATE_FAIL";

export const CONFIGS_READ_REQUEST = "CONFIGS_READ_REQUEST";
export const CONFIGS_READ_SUCCESS = "CONFIGS_READ_SUCCESS";
export const CONFIGS_READ_FAIL = "CONFIGS_READ_FAIL";

export const CONFIGS_UPDATE_REQUEST = "CONFIGS_UPDATE_REQUEST";
export const CONFIGS_UPDATE_SUCCESS = "CONFIGS_UPDATE_SUCCESS";
export const CONFIGS_UPDATE_FAIL = "CONFIGS_UPDATE_FAIL";

export const CONFIGS_DELETE_REQUEST = "CONFIGS_DELETE_REQUEST";
export const CONFIGS_DELETE_SUCCESS = "CONFIGS_DELETE_SUCCESS";
export const CONFIGS_DELETE_FAIL = "CONFIGS_DELETE_FAIL";

export function handleConfigCreate(title, detail, content) {
    return dispatch => {
        dispatch({
            type: CONFIGS_CREATE_REQUEST, payload: { title: title, detail: detail, content: content }
        });
        configCreate(title, detail, content)
            .then(data => {
                dispatch({
                    type: CONFIGS_CREATE_SUCCESS, payload: data
                });
            })
            .catch(err => {
                dispatch({
                    type: CONFIGS_CREATE_FAIL, error: true, payload: new Error(err)
                });
            });
    };
}

export function handleConfigsRead() {
    return dispatch => {
        dispatch({
            type: CONFIGS_READ_REQUEST, payload: null
        });
        configRead()
            .then(data => {
                dispatch({
                    type: CONFIGS_READ_SUCCESS, payload: data
                });
            })
            .catch(err => {
                dispatch({
                    type: CONFIGS_READ_FAIL, error: true, payload: new Error(err)
                });
            });
    };
}

export function handleConfigUpdate(configId, title, detail, content, callback) {
    return dispatch => {
        dispatch({
            type: CONFIGS_UPDATE_REQUEST, payload: { configId: configId, title: title, detail: detail, content: content }
        });
        configUpdate(configId, title, detail, content)
            .then(data => {
                dispatch({
                    type: CONFIGS_UPDATE_SUCCESS, payload: data
                });
                callback && callback();
            })
            .catch(err => {
                dispatch({
                    type: CONFIGS_UPDATE_FAIL, error: true, payload: new Error(err)
                });
                callback && callback();
            });
    };
}

export function handleConfigDelete(configId) {
    return dispatch => {
        dispatch({
            type: CONFIGS_DELETE_REQUEST, payload: { configId: configId }
        });
        configDelete(configId)
            .then(data => {
                dispatch({
                    type: CONFIGS_DELETE_SUCCESS, payload: data
                });
            })
            .catch(err => {
                dispatch({
                    type: CONFIGS_DELETE_FAIL, error: true, payload: new Error(err)
                });
            });
    };
}