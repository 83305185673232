export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'

export function createTemplate(template) {
  return dispatch => {
    dispatch({
      type: CREATE_TEMPLATE,
      payload: { template: template },
    })
  }
}
