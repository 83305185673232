import { API } from "aws-amplify"

export const apiBaseRequest = async (route ,action, payload) => {
  if (route == null || action == null || payload == null) {
    console.error('Rest Api: Failed request on rest api: empty params')
    return
  }

  const apiName = 'restapi';
  const path = route;
  const params = {
    body: {
      action: action,
      body: payload
    }
  };
  const response = await API.post(apiName, path, params);
  return response
};
