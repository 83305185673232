import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'


import { withTranslation } from 'react-i18next'

class AlertShareLinkModal extends Component {

  toggleModal = () => {
    this.props.onHide()
  }

  closeModalAndOpenGuide = () => {
    this.props.data.onAction()
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    return (
      <Modal {...this.props}
             onHide={this.toggleModal}
             centered size="md"
             aria-labelledby="contained-modal-title-vcenter"
             className="n-modal__modal">
        <div className="n-modal">
          <div className="n-modal__wrapper">
            <div className="n-modal__close" onClick={this.toggleModal}>
              <svg
                width="20rem"
                height="20rem"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                />
              </svg>
            </div>
            <div className="n-modal__header">
              <div className="n-modal__header__icon">
                <svg width="40rem" height="40rem" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 38C29.9 38 38 29.9 38 20C38 10.1 29.9 2 20 2C10.1 2 2 10.1 2 20C2 29.9 10.1 38 20 38Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                  <path d="M17 13.9L27.4 20.1L17 26.3V13.9Z" stroke="#203B60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div className="n-modal__header__title">
                {t("modal_channel_text_31")}
              </div>
              <div className="n-modal__line" />
              <div className="n-modal__content n-modal__content--flex">
                <div className="n-modal__content__title">
                {t("modal_channel_text_32")}
                </div>
              </div>
              <div className="n-modal__btns">
                <div className='n-modal__btns__wrapper'>
                  <div className="button-lg" onClick={this.closeModalAndOpenGuide}>{t("modal_channel_text_33")}</div>
                  <div className="button-lg-outline" onClick={this.toggleModal}>{t("modal_channel_text_34")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(AlertShareLinkModal)