import * as TRADESYNC from "../actions/TradesyncActions"

const initialState = {
  programMy: null,
  accounts: [],
  accountsSubscribed: [],
  brokers: [],
  brokerServers: [],
  copiers: [],
  analysis: [],
  webhooks: [],
  trades: [],
  reports: [],
  typeCreatingAccount: '',
  creatingAccountStatus: false,
  isPendingAfterCreatingAccount: false,
  creatingAccountError: false
}

export function tradesyncReducer(state = initialState, action) {
  switch (action.type) {
    case TRADESYNC.COPYTRADING_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        accounts: action.payload.programs,
        accountsSubscribed: action.payload.programSubscribed,
        programMy: action.payload.programMy,
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_GET_SUCCESS:
      if (state.accounts.some(item => item.id === action.payload?.id)) {
        return state
      }
      return { 
        ...state, accounts: [...state.accounts.concat(action.payload)] 
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_CREATE_START:
      return { 
        ...state, creatingAccountStatus: "pending" 
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_CREATE_SUCCESS:
      if (state.accounts.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, 
        accounts: [...state.accounts, action.payload], 
        creatingAccountStatus: "success",
        isPendingAfterCreatingAccount: true
      }
    case TRADESYNC.COPYTRADING_TYPE_CREATE_ACCOUNT:
      return {
        ...state, 
        typeCreatingAccount: action.payload
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_CREATE_FAIL:
      return {
        ...state,
        creatingAccountStatus: "fail",
        creatingAccountError: action.payload?.message ? action.payload?.message : "Internal error",
        isPendingAfterCreatingAccount: false
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state, accounts: state.accounts.map(item => item.id === action.payload?.id ? {
          ...item,
          name: action.payload?.name
        } : item)
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state, 
        programMy: state?.programMy?.id === action.payload ? null : state?.programMy,
        creatingAccountStatus: "success", 
        accounts: state.accounts.filter(item => item.id !== action.payload),
        isPendingAfterCreatingAccount: false
      }
    case TRADESYNC.COPYTRADING_ACCOUNT_DELETE_FAIL:
      return {
        ...state,
        creatingAccountStatus: "fail",
        creatingAccountError: action.payload?.message ? action.payload?.message : "Internal error",
        isPendingAfterCreatingAccount: false
      }
    case TRADESYNC.COPYTRADING_BROKER_LIST_SUCCESS:
      return {
        ...state, brokers: action.payload
      }
    case TRADESYNC.COPYTRADING_BROKER_SERVER_LIST_SUCCESS:
      return {
        ...state, brokerServers: action.payload
      }
    case TRADESYNC.COPYTRADING_COPIER_LIST_SUCCESS:
      return {
        ...state, copiers: action.payload
      }
    case TRADESYNC.COPYTRADING_COPIER_CREATE_SUCCESS:
      if (state.copiers.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, copiers: [...state.copiers, action.payload]
      }
    case TRADESYNC.COPYTRADING_COPIER_DELETE_SUCCESS:
      return {
        ...state, copiers: state.copiers.filter(item => item.id !== action.payload)
      }
    case TRADESYNC.COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS:
      return {
        ...state, analysis: action.payload
      }
    case TRADESYNC.COPYTRADING_WEBHOOK_LIST_SUCCESS:
      return {
        ...state, webhooks: action.payload
      }
    case TRADESYNC.COPYTRADING_WEBHOOK_CREATE_SUCCESS:
      if (state.webhooks.some(item => item.id === action.payload?.id)) {
        return state
      }
      return {
        ...state, webhooks: [...state.webhooks, action.payload]
      }
    case TRADESYNC.COPYTRADING_WEBHOOK_DELETE_SUCCESS:
      return {
        ...state, webhooks: state.webhooks.filter(item => item.id !== action.payload)
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_LIST_SUCCESS:
      const isTrades = action.payload?.some(item => item?.model === "trade")
      const isReports = action.payload?.some(item => item?.model === "report")
      if (isTrades) {
        return {
          ...state, trades: action.payload?.filter(item => item?.model === "trade")
        }
      } else if (isReports) {
        return {
          ...state, reports: action.payload?.filter(item => item?.model === "report")
        }
      } else {
        return state
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_CREATE_SUCCESS:
      if (state.trades.some(item => item.id === action.payload?.id) || state.reports.some(item => item.id === action.payload?.id)) {
        return state
      }
      if (action.payload?.model === "trade") {
        return {
          ...state, trades: [...state.trades, action.payload]
        }
      } else if (action.payload?.model === "report") {
        return {
          ...state, reports: [...state.reports, action.payload]
        }
      } else {
        return  state
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_DELETE_SUCCESS:
      return {
        ...state,
        trades: state.trades.filter(item => item.id !== action.payload),
        reports: state.reports.filter(item => item.id !== action.payload),
      }
    case TRADESYNC.COPYTRADING_TRADESYNC_RESET_DATA:
      return {
        ...state, 
        creatingAccountStatus: false,
        isPendingAfterCreatingAccount: false,
        creatingAccountError: false
      }
      case TRADESYNC.COPYTRADING_TRADESYNC_FETCH_MY_ACCOUNT:
      return {
        ...state,
        programMy: action.payload,
      }
    default:
      return state
  }
}