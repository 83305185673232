import { API, graphqlOperation } from 'aws-amplify';
import { listWorkspaces, getWorkspace } from "../../graphql/queries";
import { createWorkspace, deleteWorkspace, updateWorkspace } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listWorkspaces
const attribute = "listWorkspaces"

export const HUB_OBSERVER_WORKSPACE = "workspace"

export const workspaceCreate = async (owner, user, name, position) => {
  try {
    const filter = { owner: { eq: owner }, name: { eq: name } }
    const workspaces = await loadList(action, attribute, filter, 1)
    if (workspaces?.length > 0) {
      console.error('Workspace already exist!')
      return
    }
  } catch (err) { console.error('error fetching workspaces') }
  if (owner == null || user == null || name == null || position == null) { 
    console.error('Workspace API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createWorkspace, {input: {
      owner: owner, 
      name: name,
      lastActiveWidgetId: null,
      position: position,
      workspaceUserId: user.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createWorkspace
  } catch (err) {
    console.error('error creating workspace:', err)
    return
  }  
}

export const workspaceRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const workspaces = await loadList(action, attribute, filter, 0)
    return workspaces
  } catch (err) { 
    console.error('error fetching workspaces')
    return null
  }
}

export const workspaceUpdate = async (owner, workspaceId, name, position, lastActiveWidgetId) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getWorkspace, 
      { id: workspaceId }
    ))
    original = exist?.data?.getWorkspace
  } catch (err) { console.error('error fetching workspace:', err) }
  if (original?.id === workspaceId) {
    if (owner == null || workspaceId == null || name == null || position == null) { 
      console.error('Workspace API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateWorkspace, {input: {
        id: workspaceId,
        name: name,
        lastActiveWidgetId: lastActiveWidgetId,
        position: position,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateWorkspace        
    } catch (err) {
      console.error('error updating workspace:', err)
      return
    }
  } else {
    return null
  }  
}

export const workspaceDelete = async (owner, workspaceId) => {
  await API.graphql(graphqlOperation(deleteWorkspace, { input: { id: workspaceId }}));
  return workspaceId
}