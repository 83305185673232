import React, { Component } from 'react'
import { Modal, Button, Container} from 'react-bootstrap'


import { withTranslation } from 'react-i18next'

class AlertNewChannel extends Component {

  toggleModal = () => {
    this.props.onHide()
  }

  render() {
    
    const { t } = this.props

    const { channel } = this.props.data

    return (
      <Modal {...this.props}
             onHide={this.toggleModal}
             centered size="md"
             aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body>
          <Container className="text-center c-container">
            <div className="aside-menu__modal">
              <h3>{t("modal_channel_text_23")} <b>{channel?.name}</b></h3>
              <blockquote className="blockquote">
                <p className="mb-0">{t("modal_channel_text_24")}</p>
              </blockquote>
              <div>
                <Button size="md" variant="default" onClick={this.toggleModal}>{t("modal_channel_text_25")}</Button>
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(AlertNewChannel)