import { Component } from 'react'
import { Hub } from 'aws-amplify'
import * as K from "../../../utils/Constant"

class GoToMyAccount extends Component {
  GoToMyAccount() {
    Hub.dispatch(K.HUB_GOTOMYACCOUNT, { event: 'NoCardOnFile', data: null, message:'' })
    this.props.onHide()   
  }
}

export default GoToMyAccount