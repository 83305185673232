import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listRoleProducts, getRoleProduct } from "../../graphql/queries";
import { createRoleProduct, deleteRoleProduct, updateRoleProduct } from "../../graphql/mutations";
import { onCreateRoleProduct, onDeleteRoleProduct, onUpdateRoleProduct } from "../../graphql/subscriptions";
import * as K from "../../utils/Constant"
// import { productCreate } from "./product"

import { loadList } from "./shared"
const action = listRoleProducts
const attribute = "listRoleProducts"

export const HUB_OBSERVER_ROLE_PRODUCT = "roleProduct"

let subscriptions = []

export const roleProductCreate = async (role, product) => {
  try {
    const filter = { roleId: { eq: role?.id }, productId: { eq: product?.id } }
    const roleProducts = await loadList(action, attribute, filter, 1)
    if (roleProducts?.length > 0) {
      console.error('RoleProduct already exist!')
      return
    }
  } catch (err) { console.error('error fetching roleProducts', err) }
  if (role == null || product == null) { 
    console.error('RoleProduct API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createRoleProduct, {input: {
      roleId: role.id,
      productId: product.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createRoleProduct
  } catch (err) {
    console.error('error creating roleProduct:', err)
    return
  }  
}

export const getRoleProductRead = async (roleId) => {
  try {
    if (roleId == null) { 
      console.error('RoleProduct API: Required fields cannot be empty!')
      return null
    }
    const filter = { roleId: { eq: roleId } }
    const roleProducts = await loadList(action, attribute, filter, 0)
    return roleProducts
  } catch (err) { 
    console.error('error fetching roleProducts')
    return null
  }
}

export const roleProductRead = async () => {
  try {
    const roleProducts = await loadList(action, attribute, null, 0)
    return roleProducts
  } catch (err) { 
    console.error('error fetching roleProducts')
    return null
  }
}

export const roleProductUpdate = async (roleProductId, product) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getRoleProduct, 
      { id: roleProductId }
    ))
    original = exist?.data?.getRoleProduct
  } catch (err) { console.error('error fetching roleProduct:', err) }
  if (original?.id === roleProductId) {
    if ( product == null) { 
      console.error('Role Product API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateRoleProduct, {input: {
        id: roleProductId, 
        productId: product.id,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateRoleProduct
    } catch (err) {
      console.error('error updating roleProduct:', err)
      return
    }
  } else {
    return null
  }
}

export const roleProductDelete = async (roleProductId) => {
  try {
    await API.graphql(graphqlOperation(deleteRoleProduct, { input: { id: roleProductId }}));
    return roleProductId
  } catch (err) { 
    console.error('error delete roleProduct:', err)
    return roleProductId
  }       
}

export const roleProductObserve = async (roleId) => {
  if ( roleId == null || subscriptions.some(sub => sub.roleId === roleId) ) { return }
  const createEvent = 'INSERT'
  const createSubscription = API.graphql(graphqlOperation(onCreateRoleProduct, { roleId: roleId } )).subscribe({
    next: response => {
      const roleProduct = response.value.data.onCreateRoleProduct
      Hub.dispatch(HUB_OBSERVER_ROLE_PRODUCT, {event: createEvent, data: roleProduct, message:'create roleProduct observe' });  
    },
  })   
  subscriptions.push({roleId, createSubscription, createEvent})
  const updateEvent = 'UPDATE'
  const updateSubscription = API.graphql(graphqlOperation(onUpdateRoleProduct, { roleId: roleId } )).subscribe({
    next: response => {
      const roleProduct = response.value.data.onUpdateRoleProduct
      Hub.dispatch(HUB_OBSERVER_ROLE_PRODUCT, {event: updateEvent, data: roleProduct, message:'update roleProduct observe' });  
    },
  })   
  subscriptions.push({roleId, updateSubscription, updateEvent})
  const deleteEvent = 'DELETE'
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteRoleProduct, { roleId: roleId } )).subscribe({
    next: response => {
      const roleProduct = response.value.data.onDeleteRoleProduct
      Hub.dispatch(HUB_OBSERVER_ROLE_PRODUCT, {event: deleteEvent, data: roleProduct, message:'delete roleProduct observe' });
    },
  })
  subscriptions.push({roleId, deleteSubscription, deleteEvent})
}

export async function deleteRoleProductObservers(roleId) {
  if (roleId != null) {
    for (let sub of subscriptions) {
      if (sub.roleId === roleId && sub.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
  }          
}

export async function fillDefaultRoleProduct(roles, products) {
  const adminPlan = products?.find(el => el?.bundle === K.ADMIN_PLAN)
  const basePlan = products?.find(el => el?.bundle === K.BASE_PLAN)

  var roleProducts =[]
  var roleProduct = null

  for (let role of roles) {
    if (role?.name === K.PLATFORM_ADMIN_ROLE) {
      roleProduct = await roleProductCreate(role, adminPlan)
    } else {
      roleProduct = await roleProductCreate(role, basePlan)
    }
    if (roleProduct != null) { roleProducts.push(roleProduct) }
  }
  return roleProducts
}