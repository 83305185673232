import React from 'react'

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/dist/styles.css';

import AuthTheme from '../../components/auth/AuthTheme';
import { useTranslation } from 'react-i18next';
import { ConfirmResetPasswordFooter, ConfirmResetPasswordHeader, ConfirmSignUpHeader, ForgotPasswordHeader, SignInFooter, SignInHeader, SignUpFooter, SignUpHeader } from '../../components/auth/components';

const getFormFields = t => ({
  signIn: {
    username: {
      label: 'Email Address',
      labelHidden: false,
      placeholder: 'Enter your email adress',
      required: true,
      order: 1,
    },
    password: {
      label: 'Password:',
      labelHidden: false,
      placeholder: 'Enter your Password:',
      isRequired: true,
      order: 2,
    },
  },
  resetPassword: {
    username: {
      label: 'Email Address',
      labelHidden: false,
      placeholder: 'Enter your email adress',
      required: true,
      order: 1,
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: 'Verification code',
      placeholder: 'Enter code:',
      isRequired: true,
      labelHidden: false,
      order: 1,
    },
    password: {
      label: 'New password',
      placeholder: 'Enter your new password:',
      isRequired: true,
      labelHidden: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm new password',
      placeholder: 'Confirm your new password:',
      isRequired: true,
      labelHidden: false,
      order: 3,
    }
  },
  signUp: {
    email: {
      label: 'Email Address',
      labelHidden: false,
      placeholder: 'Enter your email adress',
      required: true,
      order: 1,
    },
    password: {
      label: 'New password',
      placeholder: 'Enter your password:',
      isRequired: true,
      labelHidden: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm new password',
      placeholder: 'Confirm your password:',
      isRequired: true,
      labelHidden: false,
      order: 3,
    }
  },
  confirmSignUp: {
    confirmation_code: {
      label: 'Verification code',
      placeholder: 'Enter code:',
      isRequired: true,
      labelHidden: false,
      order: 1,
    },
  },
})

const SignInContainer = () => {
  // const { t } = useTranslation()

const components = {
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter
  },
  SignUp: {
    Header: SignUpHeader,
    Footer: SignUpFooter
  },
  ResetPassword: {
    Header: ForgotPasswordHeader
  },
  ConfirmResetPassword: {
    Header: ConfirmResetPasswordHeader,
    Footer: ConfirmResetPasswordFooter
  },
  ConfirmSignUp: {
    Header: ConfirmSignUpHeader,
    Footer: SignUpFooter
  },
}

  return (
    <AuthTheme>
      <Authenticator
        loginMechanisms={['email']}
        formFields={getFormFields()} 
        components={components}
        initialState="signIn"
        // hideSignUp={true}
      >
        {({ signOut, user }) => (
          <main>
            <span>Loading ...</span>
          </main>
        )}
      </Authenticator>
    </AuthTheme>
  )
}

const SignInContainerWrap = () => (
  <Authenticator.Provider>
    <SignInContainer />
  </Authenticator.Provider>
)

export default SignInContainerWrap