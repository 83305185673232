import {
  CONFIGS_CREATE_REQUEST,
  CONFIGS_CREATE_SUCCESS,
  CONFIGS_CREATE_FAIL,
  CONFIGS_READ_SUCCESS,
  CONFIGS_UPDATE_SUCCESS, 
  CONFIGS_DELETE_SUCCESS
} from '../actions/ConfigsActions'
import { DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY, FIRST_MESSAGE_CATEGORY_CONFIG_TITLE } from "../../utils/Constant"

const initialState = {
  list: [],
  DEFAULT_MESSAGE_CATEGORY: null,
  MESSAGE_CATEGORIES: [],
  error: '',
  isFetching: false,
}

export function configsReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIGS_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case CONFIGS_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state, 
        list: [...state.list, action.payload] 
      }

    case CONFIGS_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.configs }

    case CONFIGS_READ_SUCCESS:
      return { ...state, isFetching: false, 
        list: [...state.list.concat(action.payload?.filter(newItem => !state.list.some(oldItem => oldItem.id === newItem.id) ) )],
        DEFAULT_MESSAGE_CATEGORY: action.payload?.
        find(el => el?.detail === DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY &&
                   el?.title === FIRST_MESSAGE_CATEGORY_CONFIG_TITLE) ?? state.DEFAULT_MESSAGE_CATEGORY,
        MESSAGE_CATEGORIES: action.payload?.
        filter(el => el?.detail === DEFAULT_MESSAGE_CATEGORY_CONFIG_KEY)?.
        sort((a, b) => a.title > b.title && 1 || -1) ?? state.MESSAGE_CATEGORIES
      }

    case CONFIGS_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          title: action.payload?.title, 
          detail: action.payload?.detail,
          content: action.payload?.content
        } : item)
      }
    case CONFIGS_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}