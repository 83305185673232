import { Modal } from "react-bootstrap"
import React, { Component } from "react"

class XLSX extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  
  render() {
    return (
      <Modal {...this.props} className={'modal-dialog--fullscreen'} fullscreen>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <object
            style={{
              width: "100%",
              height: "83vh"
            }}
            className="res-obj" type="text/html" data={this.props.data.url}></object>
          {this.props.data.children}
        </Modal.Body>
      </Modal>
    )
  }
}

export default XLSX