import * as PRODUCT from "../actions/ProductActions"
import * as K from "../../utils/Constant"

const initialState = {
  singleProducts: [],
  featureProducts: [],
  packageProducts: [],
}

export const productReducer = (state = initialState, action) => {
  switch (action.type) {    
    case PRODUCT.PRODUCT_LIST_SUCCESS:
      return { ...state,
        featureProducts: action.payload?.filter(pr => pr?.bundle?.startsWith(K.PRODUCT_PREFIX_PLAN) ),
        singleProducts: action.payload?.filter(pr => pr?.bundle?.startsWith(K.PRODUCT_PREFIX_SINGLE) ),
        packageProducts : action.payload?.filter(pr => pr?.bundle?.startsWith(K.PRODUCT_PREFIX_PACKAGE) )
      }
    default: return state
  }
}