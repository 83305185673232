import { API, graphqlOperation } from 'aws-amplify';
import { listStreams, getStream } from "../../graphql/queries"
import { createStream, deleteStream, updateStream } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listStreams
const attribute = "listStreams"

export const HUB_OBSERVER_STREAM = "stream"

export const streamCreate = async (owner, user, channel, trial) => {
  try {
    const filter = { owner: { eq: owner }, streamChannelId: { eq: channel.id } }
    const streams = await loadList(action, attribute, filter, 1)
    if (streams?.length > 0) {
      console.error('Stream already exist!')
      return
    }
  } catch (err) { console.error('error fetching streams') }
  if (owner == null || user == null || channel == null) {
    console.error('Stream API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createStream, {input: {
      owner: owner, 
      streamUserId: user.id,
      streamChannelId: channel.id,
      trial: trial,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createStream
  } catch (err) {
    console.error('error creating stream:', err)
    return
  }  
}

export const streamRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const streams = await loadList(action, attribute, filter, 0)
    return streams
  } catch (err) { 
    console.error('error fetching streams')
    return null
  }
}

export const streamGet = async (streamId) => {
  const exist = await API.graphql(graphqlOperation(getStream,
    { id: streamId }
  ))
  const original = exist?.data?.getStream
  return original
}

export const channelSubscribers = async (channelId) => {
  try {
    const filter = {
      streamChannelId: {
        eq: channelId
      }
    }
    const streams = await loadList(action, attribute, filter, 0)
    return streams
  } catch (err) {
    console.error('error fetching streams')
    return null
  }
}

export const streamUpdate = async (owner, streamId, lastReadedTime) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getStream, 
      { id: streamId }
    ))
    original = exist?.data?.getStream
  } catch (err) { console.error('error fetching stream:', err) }
  if (original?.id === streamId) {
    if (owner == null || streamId == null) {
      console.error('Stream API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateStream, {input: {
        id: streamId,
        lastReadedTime: lastReadedTime,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateStream
    } catch (err) {
      console.error('error updating stream:', err)
      return
    }
  } else {
    return null
  }  
}

export const streamDelete = async (owner, streamId) => {
  try {
    await API.graphql(graphqlOperation(deleteStream, { input: { id: streamId }}));
    return streamId
  } catch (err) { 
    console.error('error delete stream:', err)
    return streamId
  }       
}