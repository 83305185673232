import { Storage } from 'aws-amplify';

export const storageCreate = async (fileId, file, contentType, progressCallback) => {
  const storageItem = await Storage.put(fileId, file, { contentType: contentType, progressCallback: progressCallback });
  return storageItem
}

export const storageRead = async (fileId, isDownload, isText) => {
  if (!isDownload) {
    const storageItem = await Storage.get(fileId);
    return storageItem
  } else {
    const storageItem = await Storage.get(fileId, {download: true, cacheControl: 'no-cache'});
    try {
      const data = isText ? await storageItem.Body.text() : storageItem.Body
      return data
    } catch (e) {
      return null
    }
  }
}

export const storageDelete = async (fileId) => {
  await Storage.remove(fileId)
  return fileId
}