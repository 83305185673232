import React, { Component } from "react"

import * as K from "../../utils/Constant"

import { storageRead } from "../../services/storage/storage"

import './privacy-container.scss'

class PrivacyPolicyContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      privacy: ""
    }        
  }

  componentDidMount() {
    this.fetchPrivacy()
  }

  fetchPrivacy() {
    const key = K.DEFAULT_PRIVACY_POLICY_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ privacy: JSON.parse(xmlHttp.responseText) })
      })
      .catch(err => console.error("READ PRIVACY POLICY ERROR ", err))
  }

  render() {
    const { privacy } = this.state
    return (
      <section className="privacy" style={{ whiteSpace: 'pre-line' }}>
        <div className="privacy-container">
          <span dangerouslySetInnerHTML={{ 
            __html: privacy
            }} 
          />
        </div>
      </section>
    )
  }
}

export default PrivacyPolicyContainer
