import { hideEmail } from "./Helpres"
import { messageCreate } from "../services/api/message"
import { apiEmailTrigger, apiEmailRequest } from "../services/rest/apiEmail"


export const triggerEmail = (action, owner, attributes) => {
  apiEmailRequest(action, owner, attributes)
    .then(response => {
      // console.log('triggerEmail - email was sent', response);
    })
    .catch(err =>
      console.error('triggerEmail - ERROR AUTH', err)
    );
}
export const triggerEmailSignUp = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.SignUp, owner, attributes);
}

export const triggerEmailPublicChannelAdded = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.PublicChannelAdded, owner, attributes);
}

export const triggerEmailPublicChannelApproved = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.PublicChannelApproved, owner, attributes);
}

export const triggerEmailPublicChannelBlocked = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.PublicChannelBlocked, owner, attributes);
}

export const triggerEmailSubscription = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.Subscription, owner, attributes);
}

export const triggerEmailSubscriptionCancel = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.SubscriptionCancel, owner, attributes);
}

export const triggerEmailTSAccountStatus = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.TSAccountStatus, owner, attributes);
}

export const triggerEmailProgramAdded = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.ProgramAdded, owner, attributes);
}

export const triggerEmailProgramApproved = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.ProgramApproved, owner, attributes);
}

export const triggerEmailProgramBlocked = (owner, attributes) => {
  triggerEmail( apiEmailTrigger.ProgramBlocked, owner, attributes);
}

export const triggerWelcomeMessage = (channel, account, category) => {
  const message = `${hideEmail(account?.user?.username)} has just joined the channel`
  const content = {
    threadId: null,
    category: category?.id,
    title: null,
    comment: message,
    color: null,
    media: null,
    table: null,
  }
  const owner = channel?.owner
  const user = channel?.user
  triggerWelcomeMessageInChannel(owner, user, channel, content, null)
}

export const triggerWelcomeMessageInChannel = (owner, user, channel, content, guid) => {
  messageCreate(owner, user, channel, content, guid)
}