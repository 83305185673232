export const SHOW_MODAL = 'SHOW_MODAL'

export function handleShowModal(modal, data) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      payload: { modal: modal, data: data },
    })
  }
}
