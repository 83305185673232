import {
  SESSION_READ_SUCCESS,
  SESSION_UPDATE_REQUEST,
  SESSION_UPDATE_SUCCESS,
  SESSION_UPDATE_FAIL
} from '../actions/SessionActions'

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_UPDATE_REQUEST:
      return { ...state, isFetching: true, error: '' }
    case SESSION_UPDATE_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.filter(oldSession => oldSession?.id !== action.payload?.id)?.concat(action.payload)] }
    case SESSION_UPDATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }
    case SESSION_READ_SUCCESS:
      return { ...state, isFetching: false, list: action.payload }
    default:
      return state
  }
}