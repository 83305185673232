import * as ADMIN_BRANDING from "../actions/admin/AdminBrandingActions"
import * as ADMIN_USER from "../actions/admin/AdminUserActions"
import * as ADMIN_ROLE from "../actions/admin/AdminRoleActions"
import * as ADMIN_USER_ROLE from "../actions/admin/AdminUserRoleActions"
import * as ADMIN_CHANNEL from "../actions/admin/AdminChannelActions"
import * as ADMIN_EXTRA from "../actions/admin/AdminExtraActions"
import * as ADMIN_PRODUCT from "../actions/admin/AdminProductActions"
import * as ADMIN_USER_PRODUCT from "../actions/admin/AdminUserProductActions"
import * as ADMIN_ROLE_PRODUCT from "../actions/admin/AdminRoleProductActions"
import * as ADMIN_REACTION from "../actions/admin/AdminReactionActions"

const initialState = {
  title: [],
  users: [],
  roles: [],
  products: [],
  userRoles: [],
  roleProducts: [],
  userProducts: [],
  channels: [],
  extra: [],
  reactions: [],
}

export const adminPanel = (state = initialState, action) => {
  switch (action.type) {    
    case ADMIN_BRANDING.CREATE_ADMIN_PANEL_TITLE:
      return { ...state, title: [...state.title, action.payload] }
    case ADMIN_USER.ADMIN_USER_LIST_SUCCESS:
      return { ...state, users: action.payload }
    case ADMIN_USER.ADMIN_USER_UPDATE_SUCCESS:
      return { ...state, users: state.users.map(item => item.id === action.payload?.id ? action.payload : item) }
    case ADMIN_ROLE.ADMIN_ROLE_LIST_SUCCESS:
      return { ...state, roles: action.payload }
    case ADMIN_ROLE.ADMIN_ROLE_CREATE_SUCCESS:
      return { ...state, roles: [...state.roles.push(action.payload)] }
    case ADMIN_ROLE.ADMIN_ROLE_UPDATE_SUCCESS:
      return { ...state, roles: state.roles.map(item => item.id === action.payload?.id ? action.payload : item)}
    case ADMIN_ROLE.ADMIN_ROLE_DELETE_SUCCESS:
        return { ...state, roles: [...state.roles.filter(el => el.id !== action.payload)] }
    case ADMIN_USER_ROLE.ADMIN_USER_ROLE_LIST_SUCCESS:
      return { ...state, userRoles: action.payload }
    case ADMIN_USER_ROLE.ADMIN_USER_ROLE_UPDATE_SUCCESS:
      return { ...state, userRoles: state.userRoles.map(item => item.id === action.payload?.id ? { ...item, role: action.payload?.role} : item)}
    case ADMIN_CHANNEL.ADMIN_CHANNEL_MANAGEMENT_LIST_SUCCESS:
      return { ...state, channels: action.payload }
    case ADMIN_CHANNEL.ADMIN_CHANNEL_MANAGEMENT_UPDATE_SUCCESS:
      return { ...state, channels: state.channels.map(item => item.id === action.payload?.id ? action.payload : item)}
    case ADMIN_EXTRA.ADMIN_EXTRA_MANAGEMENT_LIST_SUCCESS:
      return { ...state, extra: action.payload }
    case ADMIN_EXTRA.ADMIN_EXTRA_MANAGEMENT_UPDATE_SUCCESS:
      return { ...state, extra: state.extra.map(item => item.id === action.payload?.id ? action.payload : item)}
    case ADMIN_PRODUCT.ADMIN_PRODUCT_LIST_SUCCESS:
      return { ...state, products: action.payload }
    case ADMIN_PRODUCT.ADMIN_PRODUCT_CREATE_SUCCESS:
      return { ...state, products: [...state.products, action.payload] }
    case ADMIN_PRODUCT.ADMIN_PRODUCT_UPDATE_SUCCESS:
      return { ...state, products: state.products.map(item => item.id === action.payload?.id ? action.payload : item)}
    case ADMIN_PRODUCT.ADMIN_PRODUCT_DELETE_SUCCESS:
      return { ...state, products: [...state.products.filter(el => el.id !== action.payload)] }
    case ADMIN_USER_PRODUCT.ADMIN_USER_PRODUCT_CREATE_SUCCESS:
      return { ...state, userProducts: [...state.userProducts, action.payload] }
    case ADMIN_USER_PRODUCT.ADMIN_USER_PRODUCT_LIST_SUCCESS:
      return { ...state, userProducts: action.payload }
    case ADMIN_USER_PRODUCT.ADMIN_USER_PRODUCT_UPDATE_SUCCESS:
      return { ...state, userProducts: state.userProducts.map(item => item.id === action.payload?.id ? { ...item, userProduct: action.payload?.product} : item)}
    case ADMIN_USER_PRODUCT.ADMIN_USER_PRODUCT_DELETE_SUCCESS:
      return { ...state, userProducts: [...state.userProducts.filter(el => el.id !== action.payload)] }
    case ADMIN_ROLE_PRODUCT.ADMIN_ROLE_PRODUCT_CREATE_SUCCESS:
      return { ...state, roleProducts: [...state.roleProducts, action.payload] }
    case ADMIN_ROLE_PRODUCT.ADMIN_ROLE_PRODUCT_LIST_SUCCESS:
      return { ...state, roleProducts: action.payload }
    case ADMIN_ROLE_PRODUCT.ADMIN_ROLE_PRODUCT_UPDATE_SUCCESS:
      return { ...state, roleProducts: state.roleProducts.map(item => item.id === action.payload?.id ? { ...item, roleProduct: action.payload?.role} : item)}
    case ADMIN_ROLE_PRODUCT.ADMIN_ROLE_PRODUCT_DELETE_SUCCESS:
      return { ...state, roleProducts: [...state.roleProducts.filter(el => el.id !== action.payload)] }
    case ADMIN_REACTION.ADMIN_REACTION_MANAGEMENT_LIST_SUCCESS:
      return { ...state, reactions: action.payload }
    case ADMIN_REACTION.ADMIN_REACTION_MANAGEMENT_UPDATE_SUCCESS:
      return { ...state, reactions: state.reactions.map(item => item.id === action.payload?.id ? action.payload : item)}
    default: return state
  }
}