import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom";
import { Navbar, Nav, FormControl, Dropdown } from 'react-bootstrap'
import { handleUserSignOut } from '../../store/actions/UserActions'
import { userColors } from '../../utils/UserColors'

import layoutHelpers from './helpers'

class AdminNavbar extends Component {
  constructor(props) {
    super(props)
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  handleUserLogout() {
    this.props.handleUserSignOut()
    this.props.history.push("/");
  }

  render() {
    // const { title } = this.props
    return (
      <Navbar bg="dark" expand="lg" className="layout-navbar align-items-lg-center container-p-x">
        <Navbar.Brand as={NavLink} to="/" className="app-brand demo d-lg-none py-0 mr-4">
          <span className="app-brand-logo demo bg-primary">
            <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><defs><linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse"><stop stopOpacity=".25" offset="0"></stop><stop stopOpacity=".1" offset=".3"></stop><stop stopOpacity="0" offset=".9"></stop></linearGradient><linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlinkHref="#a"></linearGradient><linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlinkHref="#a"></linearGradient></defs><path style={{fill: '#fff'}} transform="translate(-.1)" d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"></path><path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z" fill="url(#a)"></path><path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z" fill="url(#e)"></path><path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z" fill="url(#d)"></path></svg>
          </span>
          <span className="app-brand-text demo font-weight-normal ml-2"></span>
        </Navbar.Brand>
        {this.props.sidenavToggle && (
          <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
            <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
              <i className="ion ion-md-menu text-large align-middle"></i>
            </Nav.Item>
          </Nav>
        )}
        <Navbar.Toggle />
        <Navbar.Collapse>
          <hr className="d-lg-none w-100 my-2" />
          <Nav className="align-items-lg-center">
            <label className="nav-item navbar-text navbar-search-box p-0 active">
              <i className="ion ion-ios-search navbar-icon align-middle"></i>
              <span className="navbar-search-input pl-2">
                <FormControl className="navbar-text mx-2" placeholder="Search..." style={{width: '200px'}} />
              </span>
            </label>
          </Nav>
          <Nav className="align-items-lg-center ml-auto">
            <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
              <Dropdown.Toggle as={Nav.Link}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  {/* <img src={`${process.env.PUBLIC_URL}/img/avatars/4.png`} className="d-block ui-w-30 rounded-circle" alt="User" /> */}
                  <div className="circle-radius" style={{ backgroundColor: userColors.generateUserColor(this.props.account?.user?.email) }}></div>
                  <span className="px-1 mr-lg-2 ml-2 ml-lg-0">{this.props.account?.user?.name ?? this.props.account?.user?.username ?? this.props.account?.user?.email}</span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleUserLogout()}><i className="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

AdminNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

AdminNavbar.defaultProps = {
  sidenavToggle: true
}
const mapStateToProps = store => {
  return {
    account: store.user,
    title: store.adminPanel.title
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleUserSignOut: () => dispatch(handleUserSignOut()),
  }
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(AdminNavbar)

