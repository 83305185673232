import React from 'react'
import { Modal } from 'react-bootstrap'

import GoToMyAccount from './GoToMyAccount'

class NoCardOnFile extends GoToMyAccount {

  render() {
    return (
      <Modal {...this.props} centered  aria-labelledby="contained-modal-title-vcenter" className="welcome-screen__modal">
        <div className="welcome-screen text-center">
          <Modal.Body>
            <div className="welcome-screen__container">
            <div className="welcome-screen__container__title">No card on file</div>
              <div className="welcome-screen__container__subtitle-smal">You don’t have any card stored on file to make purchases. Please go to My Account window in My Profile and add a valid card.</div>
              <div className="d-flex justify-content-end">
                <div className="welcome-screen__container__btn-skip" onClick={this.props.onHide}>Cancel</div>
                <div className="pl-4 welcome-screen__container__btn-next" onClick={this.GoToMyAccount.bind(this)}>
                  <div onClick={this.props.onHide}>Go To My Account</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default NoCardOnFile

