import {
  STREAM_CREATE_REQUEST,
  STREAM_CREATE_SUCCESS,
  STREAM_CREATE_FAIL,
  STREAM_READ_SUCCESS,
  STREAM_UPDATE_SUCCESS,
  STREAM_DELETE_SUCCESS, STREAM_SUBSCRIBERS_SUCCESS, STREAM_GET_SUCCESS
} from "../actions/StreamActions"

const initialState = {
  list: [],
  error: '',
  isFetching: false,
  subscribers: [],
}

export function streamReducer(state = initialState, action) {
  switch (action.type) {
    case STREAM_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case STREAM_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state, isFetching: false, list: [...state.list, action.payload] 
      }

    case STREAM_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case STREAM_READ_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload)] }

    case STREAM_GET_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat([action.payload])] }

    case STREAM_SUBSCRIBERS_SUCCESS:
      var subscribers = state.subscribers
      var channelId = action.payload?.find(el => el != null)?.streamChannelId
      if (channelId != null) {
        let item = {
          channelId: channelId,
          streams: action.payload
        }
        subscribers = subscribers?.filter(oldItem => oldItem?.channelId !== channelId)?.concat(item)
      }
      return { ...state, subscribers: subscribers }

    case STREAM_UPDATE_SUCCESS:
      return { ...state, isFetching: false, list: state.list.map(item => item.id === action.payload?.id ? action.payload : item) }
    case STREAM_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}