import {
  widgetCreate,
  widgetRead,
  widgetUpdate,
  widgetDelete,
  widgetObserve,
  widgetGet
} from "../../services/api/widget";

import { toastSuccess, toastError } from "../../shared/platform/Toast";

export const WIDGET_CREATE_REQUEST = 'WIDGET_CREATE_REQUEST';
export const WIDGET_CREATE_SUCCESS = 'WIDGET_CREATE_SUCCESS';
export const WIDGET_CREATE_FAIL = 'WIDGET_CREATE_FAIL';

export const WIDGET_READ_REQUEST = 'WIDGET_READ_REQUEST';
export const WIDGET_READ_SUCCESS = 'WIDGET_READ_SUCCESS';
export const WIDGET_READ_FAIL = 'WIDGET_READ_FAIL';

export const WIDGET_GET_REQUEST = 'WIDGET_GET_REQUEST';
export const WIDGET_GET_SUCCESS = 'WIDGET_GET_SUCCESS';
export const WIDGET_GET_FAIL = 'WIDGET_GET_FAIL';

export const WIDGET_UPDATE_REQUEST = 'WIDGET_UPDATE_REQUEST';
export const WIDGET_UPDATE_SUCCESS = 'WIDGET_UPDATE_SUCCESS';
export const WIDGET_UPDATE_FAIL = 'WIDGET_UPDATE_FAIL';

export const WIDGET_DELETE_REQUEST = 'WIDGET_DELETE_REQUEST';
export const WIDGET_DELETE_SUCCESS = 'WIDGET_DELETE_SUCCESS';
export const WIDGET_DELETE_FAIL = 'WIDGET_DELETE_FAIL';

export function handleWidgetCreate(owner, workspace, stream, top, left, width, height, minWidth, minHeight, content) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: WIDGET_CREATE_REQUEST,
        payload: { owner: owner, workspace: workspace, stream: stream, top: top, left: left, width: width, height: height, minWidth: minWidth, minHeight: minHeight, content: content},
      });
      widgetCreate(owner, workspace, stream, top, left, width, height, minWidth, minHeight, content)
      .then(data => {
        dispatch({
          type: WIDGET_CREATE_SUCCESS,
          payload: data,        
        });
        toastSuccess('toast_text_9');
        resolve();
      })
      .catch(err => {
        dispatch({
          type: WIDGET_CREATE_FAIL,
          error: true,
          payload: new Error(err),
        });
        toastError('toast_text_10');
        reject();
      });
    });
  };
}

export function handleWidgetRead(owner, workspaceId) {
  return dispatch => {
    dispatch({
      type: WIDGET_READ_REQUEST,
      payload: { owner: owner, workspaceId: workspaceId},
    });
    widgetRead(owner, workspaceId)
    .then(data => {
      dispatch({
        type: WIDGET_READ_SUCCESS,
        payload: data,        
      });      
    })
    .catch(err => {
      dispatch({
        type: WIDGET_READ_FAIL,
        error: true,
        payload: new Error(err),
      });
    });
  };
}

export function handleWidgetGet(widgetId) {
  return dispatch => {
    dispatch({
      type: WIDGET_GET_REQUEST,
      payload: widgetId,
    });
    widgetGet(widgetId)
      .then(data => {
        dispatch({
          type: WIDGET_GET_SUCCESS,
          payload: data,
        });
      })
      .catch(err => {
        dispatch({
          type: WIDGET_GET_FAIL,
          error: true,
          payload: new Error(err),
        });
      });
  };
}

export function handleWidgetUpdate(owner, workspaceId, widgetId, top, left, width, height, minWidth, minHeight, visible, isFullHeight) {
  return dispatch => {
    dispatch({
      type: WIDGET_UPDATE_REQUEST,
      payload: { owner: owner, workspaceId: workspaceId, widgetId: widgetId, top: top, left: left, width: width, height: height, minWidth: minWidth, minHeight: minHeight, visible: visible},
    }); 
    widgetUpdate(owner, workspaceId, widgetId, top, left, width, height, minWidth, minHeight, isFullHeight, visible)
    .then(data => {
      dispatch({
        type: WIDGET_UPDATE_SUCCESS,
        payload: data,        
      });
    })
    .catch(err => {
      dispatch({
        type: WIDGET_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      });
    });
  };
}

export function handleWidgetDelete(owner, widgetId) {
  return dispatch => {
    dispatch({
      type: WIDGET_DELETE_REQUEST,
      payload: { owner: owner, widgetId: widgetId },
    });
    widgetDelete(owner, widgetId)
    .then(data => {
      dispatch({
        type: WIDGET_DELETE_SUCCESS,
        payload: data,
      });
    })
    .catch(err => {
      dispatch({
        type: WIDGET_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      });
    });
  };
}

export function observeWidget(owner, workspaceId) {
  return dispatch => {
    widgetObserve(owner, workspaceId);
  };
}

export function handleWidgetObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: WIDGET_CREATE_SUCCESS,
          payload: data.element,
        });   
        break;
      case 'UPDATE':
        dispatch({
          type: WIDGET_UPDATE_SUCCESS,
          payload: data.element,
        });   
        break;
      case 'DELETE':
        dispatch({
          type: WIDGET_DELETE_SUCCESS,
          payload: data.element,
        });   
        break;      
      default:
        break;
    }    
  };
}

export function handleWidgetObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        dispatch({
          type: WIDGET_CREATE_SUCCESS,
          payload: data,
        });   
        break;
      case 'UPDATE':
        dispatch({
          type: WIDGET_UPDATE_SUCCESS,
          payload: data,
        });   
        break;
      case 'DELETE':
        dispatch({
          type: WIDGET_DELETE_SUCCESS,
          payload: data,
        });   
        break;      
      default:
        break;
    }    
  };
}