import { isBlankOrNull } from "./Helpres"
import tinycolor  from 'tinycolor2'

class UserColors {
  generateUserColor(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  } 
}

const userColors = new UserColors()

// 
export { userColors }

export const getContrastOrNull = (hexcolor) => {
  
  // dark color cards    
  if (hexcolor === '#FF3333' || hexcolor === '#ff3333') return '#FFFFFF'
  if (hexcolor === '#FF9933' || hexcolor === '#ff9933') return '#FFFFFF'
  if (hexcolor === '#FFFF00' || hexcolor === '#ffff00') return '#FFFFFF'
  if (hexcolor === '#73E600' || hexcolor === '#73e600') return '#FFFFFF'
  if (hexcolor === '#00CC66' || hexcolor === '#00cc66') return '#FFFFFF'
  if (hexcolor === '#00D4E6' || hexcolor === '#00d4e6') return '#FFFFFF'
  if (hexcolor === '#00B0E6' || hexcolor === '#00b0e6') return '#FFFFFF'
  if (hexcolor === '#0000CC' || hexcolor === '#0000cc') return '#FFFFFF'
  if (hexcolor === '#E6BE00' || hexcolor === '#e6be00') return '#FFFFFF'
  
  // light color cards    
  if (hexcolor === '#FFCCCC' || hexcolor === '#ffcccc') return '#db6d6d'
  if (hexcolor === '#FFCC99' || hexcolor === '#ffcc99') return '#dba874'
  if (hexcolor === '#FFFFCC' || hexcolor === '#ffffcc') return '#d6d66d'
  if (hexcolor === '#BFFF80' || hexcolor === '#bfff80') return '#8ac84d'
  if (hexcolor === '#CCFFE5' || hexcolor === '#ccffe5') return '#67cd99'
  if (hexcolor === '#CCFBFF' || hexcolor === '#ccfbff') return '#60bdc5'
  if (hexcolor === '#B3EDFF' || hexcolor === '#b3edff') return '#48a9c7'
  if (hexcolor === '#CCCCFF' || hexcolor === '#ccccff') return '#6262ce'

  if (hexcolor === null) return '#4E5155'
  // try {
  //   if (hexcolor.slice(0, 1) === '#') {
  //     hexcolor = hexcolor.slice(1);
  //   }
  //   var r = parseInt(hexcolor.substr(0,2),16);
  //   var g = parseInt(hexcolor.substr(2,2),16);
  //   var b = parseInt(hexcolor.substr(4,2),16);
  //   var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  // } catch {} 
  // return (yiq <= 128) ? 'white' : null;
}

export const onChangeTheme = (color) => {
  function convertColor(color) {
    /* Check for # infront of the value, if it's there, strip it */
    if(color.substring(0,1) === '#') {
      color = color.substring(1);
    }
    var rgbColor = {};
    /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
    rgbColor.r = parseInt(color.substring(0,2),16);
    rgbColor.g = parseInt(color.substring(2,4),16);
    rgbColor.b = parseInt(color.substring(4),16);

    return `${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`
  }

  if (!isBlankOrNull(color)) {
    const colors = tinycolor(color).monochromatic(15).sort(function(a,b) { return a.toHsv().v > b.toHsv().v ? 1 : -1});
    const allColors = colors.map(t => t.toHexString());

    // RGB Examle: 225,225,225
    const colorRGB0 = convertColor(allColors[0])
    const colorRGB1 = convertColor(allColors[1])
    const colorRGB2 = convertColor(allColors[2])
    const colorRGB3 = convertColor(allColors[3])
    const colorRGB4 = convertColor(allColors[4])
    const colorRGB5 = convertColor(allColors[5])
    const colorRGB6 = convertColor(allColors[6])
    const colorRGB7 = convertColor(allColors[7])
    const colorRGB8 = convertColor(allColors[8])
    const colorRGB9 = convertColor(allColors[9])
    const colorRGB10 = convertColor(allColors[10])
    const colorRGB11 = convertColor(allColors[11])
    const colorRGB12 = convertColor(allColors[12])
    const colorRGB13 = convertColor(allColors[13])
    const colorRGB14 = convertColor(allColors[14])

    document.documentElement.style.setProperty('--colorRGB0',`${colorRGB0}`); // 2, 3, 5
    document.documentElement.style.setProperty('--colorRGB1',`${colorRGB1}`); // 7, 14, 22
    document.documentElement.style.setProperty('--colorRGB2',`${colorRGB2}`); // 13, 25, 39
    document.documentElement.style.setProperty('--colorRGB3',`${colorRGB3}`); // 18, 36, 56
    document.documentElement.style.setProperty('--colorRGB4',`${colorRGB4}`); // 24, 47, 73
    document.documentElement.style.setProperty('--colorRGB5',`${colorRGB5}`); // 29, 57, 90
    document.documentElement.style.setProperty('--colorRGB6',`${colorRGB6}`); // 35, 68, 107
    document.documentElement.style.setProperty('--colorRGB7',`${colorRGB7}`); // 41, 79, 124
    document.documentElement.style.setProperty('--colorRGB8',`${colorRGB8}`); // 46, 90, 141
    document.documentElement.style.setProperty('--colorRGB9',`${colorRGB9}`); // 52, 101, 158
    document.documentElement.style.setProperty('--colorRGB10',`${colorRGB10}`); // 57, 111, 175
    document.documentElement.style.setProperty('--colorRGB11',`${colorRGB11}`); // 63, 122, 192
    document.documentElement.style.setProperty('--colorRGB12',`${colorRGB12}`); // 68, 133, 209
    document.documentElement.style.setProperty('--colorRGB13',`${colorRGB13}`); // 74, 144, 226
    document.documentElement.style.setProperty('--colorRGB14',`${colorRGB14}`); // 80, 155, 243

    // HEX Examle: #4279c6
    const colorHEX0 = allColors[0]
    const colorHEX1 = allColors[1]
    const colorHEX2 = allColors[2]
    const colorHEX3 = allColors[3]
    const colorHEX4 = allColors[4]
    const colorHEX5 = allColors[5]
    const colorHEX6 = allColors[6]
    const colorHEX7 = allColors[7]
    const colorHEX8 = allColors[8]
    const colorHEX9 = allColors[9]
    const colorHEX10 = allColors[10]
    const colorHEX11 = allColors[11]
    const colorHEX12 = allColors[12]
    const colorHEX13 = allColors[13]
    const colorHEX14 = allColors[14]

    document.documentElement.style.setProperty('--colorHEX0',`${colorHEX0}`); // #04070b
    document.documentElement.style.setProperty('--colorHEX1',`${colorHEX1}`); // #09111c
    document.documentElement.style.setProperty('--colorHEX2',`${colorHEX2}`); // #0f1b2d
    document.documentElement.style.setProperty('--colorHEX3',`${colorHEX3}`); // #15263e
    document.documentElement.style.setProperty('--colorHEX4',`${colorHEX4}`); // #1a304f
    document.documentElement.style.setProperty('--colorHEX5',`${colorHEX5}`); // #203b60
    document.documentElement.style.setProperty('--colorHEX6',`${colorHEX6}`); // #264571
    document.documentElement.style.setProperty('--colorHEX7',`${colorHEX7}`); // #2c4f82
    document.documentElement.style.setProperty('--colorHEX8',`${colorHEX8}`); // #315a93
    document.documentElement.style.setProperty('--colorHEX9',`${colorHEX9}`); // #3764a4
    document.documentElement.style.setProperty('--colorHEX10',`${colorHEX10}`); // #3d6eb5
    document.documentElement.style.setProperty('--colorHEX11',`${colorHEX11}`); // #4279c6
    document.documentElement.style.setProperty('--colorHEX12',`${colorHEX12}`); // #4883d7
    document.documentElement.style.setProperty('--colorHEX13',`${colorHEX13}`); // #4e8de8
    document.documentElement.style.setProperty('--colorHEX14',`${colorHEX14}`); // #5498f9
  }
}

export const generateColorTextAvatar = (str) => {
  const hex = generaterColor(str)
  const rgb = hexToRgb(hex)
  const LabColorText = rgb2lab(rgb[0], rgb[1], rgb[2])
  
  LabColorText[0] = (LabColorText[0] + 50) % 100
  
  const LabToRGBColorText = lab2rgb(LabColorText)
  return `rgba(${LabToRGBColorText[0]}, ${LabToRGBColorText[1]}, ${LabToRGBColorText[2]}, 1)`
}

export const generationTextColorFromBackgroundColor = (hex) => {
  if (hex === null) { return }
  const rgb = hexToRgb(hex)
  const LabColorText = rgb2lab(rgb[0], rgb[1], rgb[2])
  
  LabColorText[0] = (LabColorText[0] + 50) % 100
  
  const LabToRGBColorText = lab2rgb(LabColorText)
  return `rgba(${LabToRGBColorText[0]}, ${LabToRGBColorText[1]}, ${LabToRGBColorText[2]}, 1)`
}


function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

function rgb2lab(colorText1, colorText2, colorText3){
  var r = colorText1 / 255,
      g = colorText2 / 255,
      b = colorText3 / 255,
      x, y, z;

  r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

  x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
  y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
  z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;

  return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}

function lab2rgb(lab){
  var y = (lab[0] + 16) / 116,
      x = lab[1] / 500 + y,
      z = y - lab[2] / 200,
      r, g, b;

  x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16/116) / 7.787);
  y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16/116) / 7.787);
  z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16/116) / 7.787);

  r = x *  3.2406 + y * -1.5372 + z * -0.4986;
  g = x * -0.9689 + y *  1.8758 + z *  0.0415;
  b = x *  0.0557 + y * -0.2040 + z *  1.0570;

  r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1/2.4) - 0.055) : 12.92 * r;
  g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1/2.4) - 0.055) : 12.92 * g;
  b = (b > 0.0031308) ? (1.055 * Math.pow(b, 1/2.4) - 0.055) : 12.92 * b;

  return [Math.max(0, Math.min(1, r)) * 255, 
          Math.max(0, Math.min(1, g)) * 255, 
          Math.max(0, Math.min(1, b)) * 255]
}

function generaterColor(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
} 