import { userProductCreate, userProductRead, userProductUpdate, userProductDelete }  from '../../../services/api/userproduct'

export const ADMIN_USER_PRODUCT_CREATE_SUCCESS = "ADMIN_USER_PRODUCT_CREATE_SUCCESS"
export const ADMIN_USER_PRODUCT_CREATE_FAIL = "ADMIN_USER_PRODUCT_CREATE_FAIL"

export const ADMIN_USER_PRODUCT_LIST_SUCCESS = "ADMIN_USER_PRODUCT_LIST_SUCCESS"
export const ADMIN_USER_PRODUCT_LIST_FAIL = "ADMIN_USER_PRODUCT_LIST_FAIL"

export const ADMIN_USER_PRODUCT_UPDATE_SUCCESS = 'ADMIN_USER_PRODUCT_UPDATE_SUCCESS'
export const ADMIN_USER_PRODUCT_UPDATE_FAIL = 'ADMIN_USER_PRODUCT_UPDATE_FAIL'

export const ADMIN_USER_PRODUCT_DELETE_SUCCESS = 'ADMIN_USER_PRODUCT_DELETE_SUCCESS'
export const ADMIN_USER_PRODUCT_DELETE_FAIL = 'ADMIN_USER_PRODUCT_DELETE_FAIL'

export function handleAdminUserProductCreate(user, product) {
  return dispatch => {
    userProductCreate(user, product)
    .then(data => {
      dispatch({
        type: ADMIN_USER_PRODUCT_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_USER_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminUserProductRead() {
  return dispatch => {
    userProductRead()
    .then(data => {
      dispatch({  
        type: ADMIN_USER_PRODUCT_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_USER_PRODUCT_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminUserProductUpdate(userProductId, product) {
  return dispatch => {
    userProductUpdate(userProductId, product)
    .then(data => {
      dispatch({  
        type: ADMIN_USER_PRODUCT_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_USER_PRODUCT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminUserProductDelete(userProductId) {
  return dispatch => {
    userProductDelete(userProductId)
    .then(data => {          
      dispatch({  
        type: ADMIN_USER_PRODUCT_DELETE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_USER_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}