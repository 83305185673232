import {
  WORKSPACE_CREATE_REQUEST,
  WORKSPACE_CREATE_SUCCESS,
  WORKSPACE_CREATE_FAIL,
  WORKSPACE_READ_REQUEST,
  WORKSPACE_READ_SUCCESS,
  WORKSPACE_READ_FAIL,
  WORKSPACE_UPDATE_SUCCESS,
  WORKSPACE_DELETE_SUCCESS,
  WORKSPACE_CREATE_SELECT
} from '../actions/WorkspaceActions'
import * as K from "../../utils/Constant"

const initialState = {
  list: [],
  active: null,
  default: null,
  error: '',
  isFetching: false,
}

export function workspaceReducer(state = initialState, action) {
  switch (action.type) {
    case WORKSPACE_CREATE_REQUEST:
      return { ...state, error: '' }

    case WORKSPACE_CREATE_SUCCESS:      
      return { 
        ...state, list: [...state.list, action.payload], active: action.payload }

    case WORKSPACE_CREATE_FAIL:
      return { ...state, error: action.payload?.message }

    case WORKSPACE_READ_REQUEST:
      return { ...state, isFetching: false, error: '' }

    case WORKSPACE_READ_SUCCESS:
      return { ...state, isFetching: true,
        list: action.payload,
        active: action.payload?.sort(function(a,b){ return new Date(b.updatedAt) - new Date(a.updatedAt) })?.find(el => el != null),
        default: action.payload?.find(w => w?.name === K.DEFAULT_WORKSPACE_NAME)
      }

    case WORKSPACE_READ_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case WORKSPACE_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          name: action.payload?.name, 
          position: action.payload?.position,
          lastActiveWidgetId: action.payload?.lastActiveWidgetId } : item),
        active: action.payload
      }
    case WORKSPACE_DELETE_SUCCESS: 
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload),
        active: state.list?.sort(function(a,b){ return new Date(b.updatedAt) - new Date(a.updatedAt) })?.find(el => el?.id !== action.payload )
      }

    case WORKSPACE_CREATE_SELECT:
      return {...state, active: action.payload }

    default:
      return state
  }
}