import { roleCreate, roleRead, roleDelete, roleUpdate }  from '../../../services/api/role'
import { getRoleProductRead, roleProductCreate } from "../../../services/api/roleproduct"
import { ADMIN_ROLE_PRODUCT_CREATE_SUCCESS } from "./AdminRoleProductActions"

export const ADMIN_ROLE_CREATE_SUCCESS = "ADMIN_ROLE_CREATE_SUCCESS"
export const ADMIN_ROLE_CREATE_FAIL = "ADMIN_ROLE_CREATE_FAIL"

export const ADMIN_ROLE_UPDATE_SUCCESS = "ADMIN_ROLE_UPDATE_SUCCESS"
export const ADMIN_ROLE_UPDATE_FAIL = "ADMIN_ROLE_UPDATE_FAIL"

export const ADMIN_ROLE_DELETE_SUCCESS = "ADMIN_ROLE_DELETE_SUCCESS"
export const ADMIN_ROLE_DELETE_FAIL = "ADMIN_ROLE_DELETE_FAIL"

export const ADMIN_ROLE_LIST_SUCCESS = 'ADMIN_ROLE_LIST_SUCCESS'
export const ADMIN_ROLE_LIST_FAIL = 'ADMIN_ROLE_LIST_FAIL'

export function handleAdminRoleCreate(name, description, baseRole) {
  return dispatch => {
    roleCreate(name, description, baseRole?.permissions)
    .then(newRole => {
      getRoleProductRead(baseRole?.id)
        .then(roleProducts => {
          const product = roleProducts?.find(el => el != null)?.product
          roleProductCreate(newRole, product)
            .then(newRoleProduct => {
              dispatch({
                type: ADMIN_ROLE_PRODUCT_CREATE_SUCCESS,
                payload: newRoleProduct,
              })
            })
            .catch(err => console.error('Role product not created', err))
        })
        .catch(err => console.error('Role product not found', err))

      dispatch({  
        type: ADMIN_ROLE_CREATE_SUCCESS,
        payload: newRole,
      })      
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRoleUpdate(role, name, description, permissions) {
  return dispatch => {
    roleUpdate(role?.id, name, description, permissions)
    .then(data => {
      dispatch({  
        type: ADMIN_ROLE_UPDATE_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRolePermissionsUpdate(role, permissions) {
  return dispatch => {
    roleUpdate(role?.id, role?.name, role?.description, permissions)
    .then(data => {
      dispatch({  
        type: ADMIN_ROLE_UPDATE_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRoleDelete(roleId) {
  return dispatch => {
    roleDelete(roleId)
    .then(data => {          
      dispatch({  
        type: ADMIN_ROLE_DELETE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRoleRead() {
  return dispatch => {
    roleRead()
    .then(data => {
      dispatch({  
        type: ADMIN_ROLE_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}