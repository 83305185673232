import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listRoles, getRole } from "../../graphql/queries";
import { createRole, deleteRole, updateRole } from "../../graphql/mutations";
import { onCreateRole, onUpdateRole, onDeleteRole } from "../../graphql/subscriptions";
import * as K from '../../utils/Constant'

import { loadList } from "./shared"
const action = listRoles
const attribute = "listRoles"

export const HUB_OBSERVER_ROLE = "role"

let subscriptions = []

export const roleCreate = async (name, description, permissions) => {
  try {
    const filter = { name: { eq: name } }
    const roles = await loadList(action, attribute, filter, 1)
    if (roles?.length > 0) {
      console.error('Role already exist!')
      return
    }
  } catch (err) { console.error('error fetching roles') }
  if (name == null || description == null || permissions == null) { 
    console.error('Role API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createRole, {input: {
      name: name,
      description: description, 
      permissions: permissions,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createRole
  } catch (err) {
    console.error('error creating role:', err)
    return
  }
}

export const roleGet = async (roleId) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getRole, 
      { id: roleId }
    ))
    original = exist?.data?.getRole
  } catch (err) { console.error('error fetching role:', err) }
  if (original?.id === roleId) {
    return original
  } else {
    return null 
  }
}

export const roleRead = async () => {
  try {
    const roles = await loadList(action, attribute, null, 0)
    return roles
  } catch (err) { 
    console.error('error fetching roles', err)
    return null
  }
}

export const roleUpdate = async (roleId, name, description, permissions) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getRole, 
      { id: roleId }
    ))
    original = exist?.data?.getRole
  } catch (err) { console.error('error fetching role:', err) }
  if (original?.id === roleId) {
    if (name == null || roleId == null || description == null || permissions == null) { 
      console.error('Role API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateRole, {input: {
        id: roleId, 
        name: name,
        description: description,
        permissions: permissions,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateRole
    } catch (err) {
      console.error('error updating role:', err)
      return
    }
  } else {
    return null
  }
}

export const roleDelete = async (roleId) => {
  await API.graphql(graphqlOperation(deleteRole, { input: { id: roleId }}));
  return roleId
}

export const roleObserve = async (roleId) => {
  if ( roleId == null || subscriptions.some(sub => sub.roleId === roleId) ) { return }
  const createSubscription = API.graphql(graphqlOperation(onCreateRole, 
    { filter: { id: { eq: roleId } } }
  )).subscribe({
    next: response => {
      const role = response.value.data.onCreateRole
      Hub.dispatch(HUB_OBSERVER_ROLE, {event: 'create', data: role, message:'create role observe' });  
    },
  })
  const createEvent = 'INSERT' 
  subscriptions.push({roleId, createSubscription, createEvent})
  const updateSubscription = API.graphql(graphqlOperation(onUpdateRole, 
    { filter: { id: { eq: roleId } } }
  )).subscribe({
    next: response => {
      const role = response.value.data.onUpdateRole
      Hub.dispatch(HUB_OBSERVER_ROLE, {event: 'update', data: role, message:'create role observe' });  
    },
  })
  const updateEvent = 'UPDATE'
  subscriptions.push({roleId, updateSubscription, updateEvent})  
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteRole, 
    { filter: { id: { eq: roleId } } }
  )).subscribe({
    next: response => {
      const role = response.value.data.onDeleteRole
      Hub.dispatch(HUB_OBSERVER_ROLE, {event: 'delete', data: role, message:'create role observe' });  
    },
  })
  const deleteEvent = 'DELETE'  
  subscriptions.push({roleId, deleteSubscription, deleteEvent})
}

export async function deleteRolesObservers(roleId) {
  if (roleId != null) {
    for (let sub of subscriptions) {
      if (sub.roleId === roleId && sub.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
  }
}

export async function fillDefaultRoles(permissions) {
  var roles =[]
  var role = null
  role = await roleCreate(K.PLATFORM_ADMIN_ROLE, "Primary role - maximum permissions", fillAdminRolePermissions(permissions))
  if (role != null && role?.name === K.PLATFORM_ADMIN_ROLE) { roles.push(role) }
  role = await roleCreate(K.PLATFORM_MODERATOR_ROLE, "Secondary role - many permissions", fillModeratorRolePermissions(permissions))
  if (role != null && role?.name === K.PLATFORM_MODERATOR_ROLE) { roles.push(role) }
  role = await roleCreate(K.PLATFORM_INSTRUCTOR_ROLE, "Tertially role - few permissions", fillInstructorRolePermissions(permissions))
  if (role != null && role?.name === K.PLATFORM_INSTRUCTOR_ROLE) { roles.push(role) }
  role = await roleCreate(K.PLATFORM_RETAIL_ROLE, "Quaternary role - minimum permissions", fillRetailRolePermissions(permissions))
  if (role != null && role?.name === K.PLATFORM_RETAIL_ROLE) { roles.push(role) }
  return roles
}

function fillAdminRolePermissions(permissions) {
  var rolePerms = []
  // Create Admin role permissions
  for (let p of permissions) {
    if (p.module === K.PLATFORM_MODULE) {
      if (p.name === K.PLATFORM_ADMIN_PANEL) { rolePerms.push({ position: 10, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_DASHBOARD) { rolePerms.push({ position: 11, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_USERS) { rolePerms.push({ position: 12, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_ROLES_AND_PERMISSIONS) { rolePerms.push({ position: 13, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_CHANNEL_MANAGEMENT) { rolePerms.push({ position: 14, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_PRODUCTS) { rolePerms.push({ position: 15, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_EXTRAS) { rolePerms.push({ position: 16, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_INTEGRATIONS) { rolePerms.push({ position: 17, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_CUSTOMIZATIONS) { rolePerms.push({ position: 18, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
    } else if (p.module === K.USER_MODULE) {
      if (p.name === K.USER_CAN_HAVE_STRIPE_SUBACCOUNT) { rolePerms.push({ position: 100, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_HAVE_EXTERNAL_LINKS) { rolePerms.push({ position: 101, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.USER_CAN_MANAGE_TEMPLATE_WORKSPACE) { rolePerms.push({ position: 102, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.USER_CAN_ACCESS_PREMIUM_CONTENT) { rolePerms.push({ position: 103, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
    } else if (p.module === K.CHANNEL_MODULE) {
      if (p.name === K.CHANNEL_CAN_CREATE) { rolePerms.push( { position: 200, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "none", publicOwner: "true", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_POST) { rolePerms.push( { position: 201, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "true", publicGuest : "true" }) }) }
      if (p.name === K.CHANNEL_CAN_PUBLISH) { rolePerms.push( { position: 202, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "none", privateGuest: "true", publicOwner: "none", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO) { rolePerms.push( { position: 203, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "true", publicGuest : "true" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION) { rolePerms.push( { position: 204, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "true", publicGuest : "true" }) }) }
    } else if (p.module === K.MESSAGE_MODULE) {
      if (p.name === K.MESSAGE_CAN_EDIT_MESSAGES) { rolePerms.push( { position: 300, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ own: "true", other: "true" }) }) }
    }
  }
  return rolePerms
}

function fillModeratorRolePermissions(permissions) {
  var rolePerms = []
  // Create Moderator role permissions
  for (let p of permissions) {
    if (p.module === K.PLATFORM_MODULE) {
      if (p.name === K.PLATFORM_ADMIN_PANEL) { rolePerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_DASHBOARD) { rolePerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_USERS) { rolePerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_ROLES_AND_PERMISSIONS) { rolePerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_CHANNEL_MANAGEMENT) { rolePerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
      if (p.name === K.PLATFORM_PRODUCTS) { rolePerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_EXTRAS) { rolePerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_INTEGRATIONS) { rolePerms.push({ position: 17, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_CUSTOMIZATIONS) { rolePerms.push({ position: 18, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
    } else if (p.module === K.USER_MODULE) {
      if (p.name === K.USER_CAN_HAVE_STRIPE_SUBACCOUNT) { rolePerms.push( { position: 100, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_HAVE_EXTERNAL_LINKS) { rolePerms.push({ position: 101, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_MANAGE_TEMPLATE_WORKSPACE) { rolePerms.push({ position: 102, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_ACCESS_PREMIUM_CONTENT) { rolePerms.push({ position: 103, permissionId: p.id, permission: p.name, enabled: true, policy: "none" }) }
    } else if (p.module === K.CHANNEL_MODULE) {
      if (p.name === K.CHANNEL_CAN_CREATE) { rolePerms.push( { position: 200, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "none", publicOwner: "false", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_POST) { rolePerms.push( { position: 201, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "false", publicGuest : "true" }) }) }
      if (p.name === K.CHANNEL_CAN_PUBLISH) { rolePerms.push( { position: 202, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "none", privateGuest: "true", publicOwner: "none", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO) { rolePerms.push( { position: 203, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "false", privateGuest: "true", publicOwner: "false", publicGuest : "true" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION) { rolePerms.push( { position: 204, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "false", publicGuest : "true" }) }) }
    } else if (p.module === K.MESSAGE_MODULE) {
      if (p.name === K.MESSAGE_CAN_EDIT_MESSAGES) { rolePerms.push( { position: 300, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ own: "true", other: "true" }) }) }
    }
  }
  return rolePerms
}

function fillInstructorRolePermissions(permissions) {
  var rolePerms = []
  // Create Instructor role permissions
  for (let p of permissions) {
    if (p.module === K.PLATFORM_MODULE) {
      if (p.name === K.PLATFORM_ADMIN_PANEL) { rolePerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_DASHBOARD) { rolePerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_USERS) { rolePerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_ROLES_AND_PERMISSIONS) { rolePerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_CHANNEL_MANAGEMENT) { rolePerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_PRODUCTS) { rolePerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_EXTRAS) { rolePerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_INTEGRATIONS) { rolePerms.push({ position: 17, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_CUSTOMIZATIONS) { rolePerms.push({ position: 18, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
    } else if (p.module === K.USER_MODULE) {
      if (p.name === K.USER_CAN_HAVE_STRIPE_SUBACCOUNT) { rolePerms.push( { position: 100, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_HAVE_EXTERNAL_LINKS) { rolePerms.push({ position: 101, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_MANAGE_TEMPLATE_WORKSPACE) { rolePerms.push({ position: 102, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_ACCESS_PREMIUM_CONTENT) { rolePerms.push({ position: 103, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
    } else if (p.module === K.CHANNEL_MODULE) {
      if (p.name === K.CHANNEL_CAN_CREATE) { rolePerms.push( { position: 200, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "none", publicOwner: "false", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_POST) { rolePerms.push( { position: 201, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "true", publicGuest : "true" }) }) }
      if (p.name === K.CHANNEL_CAN_PUBLISH) { rolePerms.push( { position: 202, permissionId: p.id, permission: p.name, enabled: false, policy: JSON.stringify({ privateOwner: "none", privateGuest: "false", publicOwner: "none", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO) { rolePerms.push( { position: 203, permissionId: p.id, permission: p.name, enabled: false, policy: JSON.stringify({ privateOwner: "false", privateGuest: "false", publicOwner: "false", publicGuest : "false" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION) { rolePerms.push( { position: 204, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "false", publicOwner: "true", publicGuest : "false" }) }) }
    } else if (p.module === K.MESSAGE_MODULE) {
      if (p.name === K.MESSAGE_CAN_EDIT_MESSAGES) { rolePerms.push( { position: 300, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ own: "true", other: "false" }) }) }
    }
  }
  return rolePerms
}

function fillRetailRolePermissions(permissions) {
  var rolePerms = []
  // Create Retail role permissions
  for (let p of permissions) {
    if (p.module === K.PLATFORM_MODULE) {
      if (p.name === K.PLATFORM_ADMIN_PANEL) { rolePerms.push( { position: 10, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_DASHBOARD) { rolePerms.push( { position: 11, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_USERS) { rolePerms.push( { position: 12, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_ROLES_AND_PERMISSIONS) { rolePerms.push( { position: 13, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_CHANNEL_MANAGEMENT) { rolePerms.push( { position: 14, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_PRODUCTS) { rolePerms.push( { position: 15, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_EXTRAS) { rolePerms.push( { position: 16, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_INTEGRATIONS) { rolePerms.push({ position: 17, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.PLATFORM_CUSTOMIZATIONS) { rolePerms.push({ position: 18, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
    } else if (p.module === K.USER_MODULE) {
      if (p.name === K.USER_CAN_HAVE_STRIPE_SUBACCOUNT) { rolePerms.push( { position: 100, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_HAVE_EXTERNAL_LINKS) { rolePerms.push({ position: 101, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_MANAGE_TEMPLATE_WORKSPACE) { rolePerms.push({ position: 102, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
      if (p.name === K.USER_CAN_ACCESS_PREMIUM_CONTENT) { rolePerms.push({ position: 103, permissionId: p.id, permission: p.name, enabled: false, policy: "none" }) }
    } else if (p.module === K.CHANNEL_MODULE) {
      if (p.name === K.CHANNEL_CAN_CREATE) { rolePerms.push( { position: 200, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "none", publicOwner: "false", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_POST) { rolePerms.push( { position: 201, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "true", publicGuest : "true" }) }) }
      if (p.name === K.CHANNEL_CAN_PUBLISH) { rolePerms.push( { position: 202, permissionId: p.id, permission: p.name, enabled: false, policy: JSON.stringify({ privateOwner: "none", privateGuest: "false", publicOwner: "none", publicGuest : "none" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_PUBLIC_CHANNEL_INFO) { rolePerms.push( { position: 203, permissionId: p.id, permission: p.name, enabled: false, policy: JSON.stringify({ privateOwner: "false", privateGuest: "false", publicOwner: "false", publicGuest : "false" }) }) }
      if (p.name === K.CHANNEL_CAN_EDIT_CHANNEL_DESCRIPTION) { rolePerms.push( { position: 204, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ privateOwner: "true", privateGuest: "true", publicOwner: "true", publicGuest : "true" }) }) }
    } else if (p.module === K.MESSAGE_MODULE) {
      if (p.name === K.MESSAGE_CAN_EDIT_MESSAGES) { rolePerms.push( { position: 300, permissionId: p.id, permission: p.name, enabled: true, policy: JSON.stringify({ own: "false", other: "false" }) }) }
    }
  }
  return rolePerms
}