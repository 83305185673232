import { settingCreate, settingRead, settingUpdate, settingDelete }  from '../../services/api/settings'

export const SETTINGS_CREATE_REQUEST = 'SETTINGS_CREATE_REQUEST'
export const SETTINGS_CREATE_SUCCESS = 'SETTINGS_CREATE_SUCCESS'
export const SETTINGS_CREATE_FAIL = 'SETTINGS_CREATE_FAIL'

export const SETTINGS_READ_REQUEST = 'SETTINGS_READ_REQUEST'
export const SETTINGS_READ_SUCCESS = 'SETTINGS_READ_SUCCESS'
export const SETTINGS_READ_FAIL = 'SETTINGS_READ_FAIL'

export const SETTINGS_UPDATE_REQUEST = 'SETTINGS_UPDATE_REQUEST'
export const SETTINGS_UPDATE_SUCCESS = 'SETTINGS_UPDATE_SUCCESS'
export const SETTINGS_UPDATE_FAIL = 'SETTINGS_UPDATE_FAIL'

export const SETTINGS_DELETE_REQUEST = 'SETTINGS_DELETE_REQUEST'
export const SETTINGS_DELETE_SUCCESS = 'SETTINGS_DELETE_SUCCESS'
export const SETTINGS_DELETE_FAIL = 'SETTINGS_DELETE_FAIL'

export function handleSettingsCreate(owner, user, title, detail, content) {
  return dispatch => {
    dispatch({
      type: SETTINGS_CREATE_REQUEST,
      payload: { owner: owner, user: user, title: title, detail: detail, content: content },
    })
    settingCreate(owner, user, title, detail, content)
    .then(data => {
      dispatch({
        type: SETTINGS_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: SETTINGS_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleSettingsRead(owner) {
  return dispatch => {
    dispatch({
      type: SETTINGS_READ_REQUEST,
      payload: owner,
    })
    settingRead(owner)
    .then(data => {
      dispatch({
        type: SETTINGS_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: SETTINGS_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleSettingsUpdate(owner, settingsId, title, detail, content) {
  return dispatch => {
    dispatch({
      type: SETTINGS_UPDATE_REQUEST,
      payload: { owner: owner, settingsId: settingsId, title: title, detail: detail, content: content },
    })
    settingUpdate(owner, settingsId, title, detail, content)
    .then(data => {
      dispatch({
        type: SETTINGS_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: SETTINGS_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleSettingsDelete(owner, settingsId) {
  return dispatch => {
    dispatch({
      type: SETTINGS_DELETE_REQUEST,
      payload: { owner: owner, settingsId: settingsId },
    })
    settingDelete(owner, settingsId)
    .then(data => {
      dispatch({
        type: SETTINGS_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: SETTINGS_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}