import { apiBaseRequest } from "./apiBase"

export const apiCopytrading = {
  Accounts: "apiCopytradingAccounts",
  Account: "apiCopytradingAccount",
  AccountCreate: "apiCopytradingAccountCreate",
  AccountUpdate: "apiCopytradingAccountUpdate",
  AccountDelete: "apiCopytradingAccountDelete",
  Copiers: "apiCopytradingCopiers",
  CopierCreate: "apiCopytradingCopierCreate",
  CopierUpdate: "apiCopytradingCopierUpdate",
  CopierDelete: "apiCopytradingCopierDelete",
  Brokers: "apiCopytradingBrokers",
  BrokerALlServers: "apiCopytradingBrokerALlServers",
  AnalysisAccounts: "apiCopytradingAnalysisAccounts",
  WebhookCreate: "apiCopytradingWebhookCreate",
  WebhookDelete: "apiCopytradingWebhookDelete",
  Webhooks: "apiCopytradingWebhooks",
  Subscribe: "apiCopytradingSubscribe",
  Unsubscribe: "apiCopytradingUnsubscribe",
  UpdateMM: "apiCopytradingMM",
  Programs: "apiCopytradingPrograms",
  ProgramAnalytics: "apiCopytradingProgramAnalytics",
  MyAccount: "apiCopytradingMyAccount",
  AdminDashboard: "apiCopytradingAdminDashboard",
  AdminAccounts: "apiCopytradingAdminAccounts",
  AdminSettings: "apiCopytradingAdminSettings"
}

export const apiCopytradingRequest = async (action, payload) => {
  if (action == null || payload == null) {
    console.error('Rest Api: Failed copytrading request: empty params');
    return null;
  }
  return await apiBaseRequest('/api', action, payload);
};