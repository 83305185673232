import { labelCreate, labelRead, labelUpdate, labelDelete } from '../../services/api/label'
import { tagDelete } from '../../services/api/tag'
import { TAG_DELETE_SUCCESS } from '../../store/actions/TagActions'

export const LABEL_CREATE_REQUEST = 'LABEL_CREATE_REQUEST'
export const LABEL_CREATE_SUCCESS = 'LABEL_CREATE_SUCCESS'
export const LABEL_CREATE_FAIL = 'LABEL_CREATE_FAIL'

export const LABEL_READ_REQUEST = 'LABEL_READ_REQUEST'
export const LABEL_READ_SUCCESS = 'LABEL_READ_SUCCESS'
export const LABEL_READ_FAIL = 'LABEL_READ_FAIL'

export const LABEL_UPDATE_REQUEST = 'LABEL_UPDATE_REQUEST'
export const LABEL_UPDATE_SUCCESS = 'LABEL_UPDATE_SUCCESS'
export const LABEL_UPDATE_FAIL = 'LABEL_UPDATE_FAIL'

export const LABEL_DELETE_REQUEST = 'LABEL_DELETE_REQUEST'
export const LABEL_DELETE_SUCCESS = 'LABEL_DELETE_SUCCESS'
export const LABEL_DELETE_FAIL = 'LABEL_DELETE_FAIL'

export function handleLabelCreate(owner, user, name) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LABEL_CREATE_REQUEST,
        payload: { owner: owner, user: user, name: name },
      })
      labelCreate(owner, user, name)
        .then(data => {
          dispatch({
            type: LABEL_CREATE_SUCCESS,
            payload: data,
          })
          resolve(data)
        })
        .catch(err => {
          dispatch({
            type: LABEL_CREATE_FAIL,
            error: true,
            payload: new Error(err),
          })
          reject(err)
        })
    })
  }
}

export function handleLabelRead(owner) {
  return dispatch => {
    dispatch({
      type: LABEL_READ_REQUEST,
      payload: owner,
    })
    labelRead(owner)
      .then(data => {
        dispatch({
          type: LABEL_READ_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: LABEL_READ_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}
export function handleLabelUpdate(owner, labelId, name) {
  return dispatch => {
    dispatch({
      type: LABEL_UPDATE_REQUEST,
      payload: { owner: owner, labelId: labelId, name: name },
    })
    labelUpdate(owner, labelId, name)
      .then(data => {
        dispatch({
          type: LABEL_UPDATE_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: LABEL_UPDATE_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleLabelDelete(owner, labelId, tags) {
  return dispatch => {
    dispatch({
      type: LABEL_DELETE_REQUEST,
      payload: { owner: owner, labelId: labelId },
    })
    labelDelete(owner, labelId)
      .then(data => {
        tags.map(tag => {
          return tagDelete(owner, tag.id)
            .then(data => {
              dispatch({
                type: TAG_DELETE_SUCCESS,
                payload: tag.id,
              })
            })
        })
        dispatch({
          type: LABEL_DELETE_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: LABEL_DELETE_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}