import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  handlePaymentListCustomers,
  handlePaymentListProduct,
  handlePaymentListPrice,
  handlePaymentListSubscription,
  handlePaymentReadMyAccount,
  handlePaymentListAccounts,
  handlePaymentListAccountTransfers, handlePaymentReadMyCustomer
} from "../../store/actions/BillingActions"
import { USER_CAN_HAVE_STRIPE_SUBACCOUNT } from "../../utils/Constant"

class BillingContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCreatedCustomer: false,
      isProductRead: false,
      isPlanRead: false,
      isSubscriptionRead: false,
      isListAccounts: false,
      isReadMyAccount: false,
      isReadAccountTransfers: false,
    }  
  }

  componentDidMount() {
    if (!this.state.isCreatedCustomer && this.props.account?.user?.email?.includes("@")) {
      this.onCustomerRead(this.props.account?.user?.email)
      this.setState({isCreatedCustomer: true})
    }
  }

  componentDidUpdate() {
    if (!this.state.isCreatedCustomer && this.props.account?.user?.email?.includes("@")) {
      this.onCustomerRead(this.props.account?.user?.email)
      this.setState({isCreatedCustomer: true})
    } else if (this.state.isCreatedCustomer && this.props.stripe?.customer != null && !this.state.isProductRead) {
      this.onProductRead(this.props.stripe?.customer?.id)
      this.setState({isProductRead: true})
    } else if (this.state.isProductRead && !this.state.isPlanRead) {
      this.onPriceRead(this.props.stripe?.customer?.id)
      this.setState({isPlanRead: true})
    } else if (this.state.isPlanRead && !this.state.isSubscriptionRead) {
      this.onSubscriptionRead(this.props.stripe?.customer?.id)
      this.setState({isSubscriptionRead: true})
    } else if (this.state.isSubscriptionRead && !this.state.isListAccounts) {
      this.onAccountList()
      this.setState({isListAccounts: true})
    } else if (this.state.isListAccounts && !this.state.isReadMyAccount && this.props.permissions.some(per => per === USER_CAN_HAVE_STRIPE_SUBACCOUNT)) {
      this.onAccountRead(this.props.account?.user?.email)
      this.setState({isReadMyAccount: true})
    } else if (this.state.isReadMyAccount && !this.state.isReadAccountTransfers) {
      this.onAccountTransfersRead(this.props.account?.user?.email)
      this.setState({isReadAccountTransfers: true})
    }
  }

  onCustomerRead = (email) => {
    this.props.handlePaymentReadMyCustomer(email)
  }

  onProductRead = () => {
    this.props.handlePaymentListProduct()
  }

  onPriceRead = () => {
    this.props.handlePaymentListPrice()
  }

  onSubscriptionRead = (customerId) => {
    this.props.handlePaymentListSubscription(customerId)
  }

  onAccountList = () => {
    this.props.handlePaymentListAccounts()
  }

  onAccountRead = (email) => {
    this.props.handlePaymentReadMyAccount(email)
  }

  onAccountTransfersRead = () => {
    this.props.handlePaymentListAccountTransfers()
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    account: store.user,
    configs: store.configs.list,
    stripe: store.stripe,
    permissions: store.permissions, 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handlePaymentListCustomers : (email) => dispatch(handlePaymentListCustomers(email)),
    handlePaymentReadMyCustomer : (email) => dispatch(handlePaymentReadMyCustomer(email)),
    handlePaymentListProduct : () => dispatch(handlePaymentListProduct()),
    handlePaymentListPrice : () => dispatch(handlePaymentListPrice()),
    handlePaymentListSubscription : (customerId) => dispatch(handlePaymentListSubscription(customerId)),
    handlePaymentListAccounts : () => dispatch(handlePaymentListAccounts()),
    handlePaymentReadMyAccount : (email) => dispatch(handlePaymentReadMyAccount(email)),
    handlePaymentListAccountTransfers : () => dispatch(handlePaymentListAccountTransfers()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingContainer)