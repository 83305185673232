import { tradesyncCreate, tradesyncRead } from "../../services/api/tradesync"
import { apiCopytrading, apiCopytradingRequest } from "../../services/rest/apiCopytrading"
import {
  handleChannelSubscribeResult,
  handleChannelUnsubscribeResult
} from "./ChannelActions"
import { toastSuccess, toastError } from "../../shared/platform/Toast"
import { triggerEmailProgramAdded, triggerEmailTSAccountStatus } from "../../utils/TriggerHelpers"

export const COPYTRADING_ACCOUNT_LIST_SUCCESS = "COPYTRADING_ACCOUNT_LIST_SUCCESS"
export const COPYTRADING_ACCOUNT_LIST_FAIL = "COPYTRADING_ACCOUNT_LIST_FAIL"
export const COPYTRADING_ACCOUNT_GET_SUCCESS = "COPYTRADING_ACCOUNT_GET_SUCCESS"
export const COPYTRADING_ACCOUNT_GET_FAIL = "COPYTRADING_ACCOUNT_GET_FAIL"
export const COPYTRADING_ACCOUNT_CREATE_START = "COPYTRADING_ACCOUNT_CREATE_START"
export const COPYTRADING_ACCOUNT_CREATE_SUCCESS = "COPYTRADING_ACCOUNT_CREATE_SUCCESS"
export const COPYTRADING_TYPE_CREATE_ACCOUNT = "COPYTRADING_TYPE_CREATE_ACCOUNT"
export const COPYTRADING_ACCOUNT_CREATE_FAIL = "COPYTRADING_ACCOUNT_CREATE_FAIL"
export const COPYTRADING_ACCOUNT_UPDATE_SUCCESS = "COPYTRADING_ACCOUNT_UPDATE_SUCCESS"
export const COPYTRADING_ACCOUNT_UPDATE_FAIL = "COPYTRADING_ACCOUNT_UPDATE_FAIL"
export const COPYTRADING_ACCOUNT_DELETE_SUCCESS = "COPYTRADING_ACCOUNT_DELETE_SUCCESS"
export const COPYTRADING_ACCOUNT_DELETE_FAIL = "COPYTRADING_ACCOUNT_DELETE_FAIL"

export const COPYTRADING_BROKER_LIST_SUCCESS = "COPYTRADING_BROKER_LIST_SUCCESS"
export const COPYTRADING_BROKER_LIST_FAIL = "COPYTRADING_BROKER_LIST_FAIL"
export const COPYTRADING_BROKER_SERVER_LIST_SUCCESS = "COPYTRADING_BROKER_SERVER_LIST_SUCCESS"
export const COPYTRADING_BROKER_SERVER_LIST_FAIL = "COPYTRADING_BROKER_SERVER_LIST_FAIL"

export const COPYTRADING_COPIER_LIST_SUCCESS = "COPYTRADING_COPIER_LIST_SUCCESS"
export const COPYTRADING_COPIER_LIST_FAIL = "COPYTRADING_COPIER_LIST_FAIL"
export const COPYTRADING_COPIER_CREATE_SUCCESS = "COPYTRADING_COPIER_CREATE_SUCCESS"
export const COPYTRADING_COPIER_CREATE_FAIL = "COPYTRADING_COPIER_CREATE_FAIL"
export const COPYTRADING_COPIER_DELETE_SUCCESS = "COPYTRADING_COPIER_DELETE_SUCCESS"
export const COPYTRADING_COPIER_DELETE_FAIL = "COPYTRADING_COPIER_DELETE_FAIL"

export const COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS = "COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS"
export const COPYTRADING_ANALYSIS_ACCOUNT_LIST_FAIL = "COPYTRADING_ANALYSIS_ACCOUNT_LIST_FAIL"

export const COPYTRADING_WEBHOOK_LIST_SUCCESS = "COPYTRADING_WEBHOOK_LIST_SUCCESS"
export const COPYTRADING_WEBHOOK_LIST_FAIL = "COPYTRADING_WEBHOOK_LIST_FAIL"

export const COPYTRADING_WEBHOOK_CREATE_SUCCESS = "COPYTRADING_WEBHOOK_CREATE_SUCCESS"
export const COPYTRADING_WEBHOOK_CREATE_FAIL = "COPYTRADING_WEBHOOK_CREATE_FAIL"
export const COPYTRADING_WEBHOOK_DELETE_SUCCESS = "COPYTRADING_WEBHOOK_DELETE_SUCCESS"
export const COPYTRADING_WEBHOOK_DELETE_FAIL = "COPYTRADING_WEBHOOK_DELETE_FAIL"

export const COPYTRADING_TRADESYNC_LIST_SUCCESS = "COPYTRADING_TRADESYNC_LIST_SUCCESS"
export const COPYTRADING_TRADESYNC_LIST_FAIL = "COPYTRADING_TRADESYNC_LIST_FAIL"
export const COPYTRADING_TRADESYNC_GET_SUCCESS = "COPYTRADING_TRADESYNC_GET_SUCCESS"
export const COPYTRADING_TRADESYNC_GET_FAIL = "COPYTRADING_TRADESYNC_GET_FAIL"
export const COPYTRADING_TRADESYNC_CREATE_SUCCESS = "COPYTRADING_TRADESYNC_CREATE_SUCCESS"
export const COPYTRADING_TRADESYNC_CREATE_FAIL = "COPYTRADING_TRADESYNC_CREATE_FAIL"
export const COPYTRADING_TRADESYNC_UPDATE_SUCCESS = "COPYTRADING_TRADESYNC_UPDATE_SUCCESS"
export const COPYTRADING_TRADESYNC_UPDATE_FAIL = "COPYTRADING_TRADESYNC_UPDATE_FAIL"
export const COPYTRADING_TRADESYNC_DELETE_SUCCESS = "COPYTRADING_TRADESYNC_DELETE_SUCCESS"
export const COPYTRADING_TRADESYNC_DELETE_FAIL = "COPYTRADING_TRADESYNC_DELETE_FAIL"
export const COPYTRADING_TRADESYNC_RESET_DATA = "COPYTRADING_TRADESYNC_RESET_DATA"
export const COPYTRADING_TRADESYNC_FETCH_MY_ACCOUNT = "COPYTRADING_TRADESYNC_FETCH_MY_ACCOUNT"

export function handleTradeSyncListAccounts({ owner } = {}) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!owner) {
        reject('owner is not provided!')
        return
      }
      apiCopytradingRequest(apiCopytrading.Programs, { owner })
        .then(accounts => {
            dispatch({
              type: COPYTRADING_ACCOUNT_LIST_SUCCESS,
              payload: accounts
              })
            resolve(accounts)
          })
        .catch(error => {
          console.error(error)
          dispatch({
            type: COPYTRADING_ACCOUNT_LIST_FAIL,
            error: true,
            payload: new Error(error)
          })
          reject(error)
      })
    })
  }
}

export function handleTradeSyncGetAccount(accountId) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.Account, {accountId: accountId})
      .then(account => {
        if (account?.id == null) {
          return
        }
        dispatch({
          type: COPYTRADING_ACCOUNT_GET_SUCCESS,
          payload: account
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_GET_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncCreateAccount(params, isMaster, owner) {
  return dispatch => {
    dispatch({
      type: COPYTRADING_ACCOUNT_CREATE_START
    })
    apiCopytradingRequest(apiCopytrading.AccountCreate, {params: params})
      .then(account => {
        if (account?.id != null && account?.account_name != null && account?.account_number != null) {
          dispatch({
            type: COPYTRADING_ACCOUNT_CREATE_SUCCESS,
            payload: account
          })
          toastSuccess('toast_text_21')
          const authorHash = account?.account_name?.split("___")[1]
          if (isMaster === true) {
            const programName = account?.account_name?.split("___")[0]
            triggerEmailProgramAdded(owner, {name: programName, authorHash: authorHash})
          }
          const accountNumber = account?.account_number
          const accountId = account?.id
          triggerEmailTSAccountStatus(owner, {accountId: accountId, accountNumber: accountNumber, authorHash: authorHash})
        } else {
          dispatch({
            type: COPYTRADING_ACCOUNT_CREATE_FAIL,
            error: true,
            payload: account
          })
          toastError('toast_text_22')
        }
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastError('toast_text_22')
    })
  }
}

export function handleTradeSyncCreateAccountPromise(params, isMaster, owner) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: COPYTRADING_ACCOUNT_CREATE_START
      })
      apiCopytradingRequest(apiCopytrading.AccountCreate, {params: params})
        .then(account => {
          if (account?.id != null && account?.account_name != null && account?.account_number != null) {
            dispatch({
              type: COPYTRADING_ACCOUNT_CREATE_SUCCESS,
              payload: account
            })
            toastSuccess('toast_text_21')
            const authorHash = account?.account_name?.split("___")[1]
            if (isMaster === true) {
              const programName = account?.account_name?.split("___")[0]
              triggerEmailProgramAdded(owner, {name: programName, authorHash: authorHash})
            }
            const accountNumber = account?.account_number
            const accountId = account?.id
            triggerEmailTSAccountStatus(owner, {accountId: accountId, accountNumber: accountNumber, authorHash: authorHash})
            resolve(account)
          } else {
            dispatch({
              type: COPYTRADING_ACCOUNT_CREATE_FAIL,
              error: true,
              payload: account
            })
            toastError('toast_text_22')
            reject()
          }
        }).catch(error => {
        console.error(error)
        dispatch({
          type: COPYTRADING_ACCOUNT_CREATE_FAIL,
          error: true,
          payload: new Error(error)
        })
        toastError('toast_text_22')
        reject(error)
      })
    })
  }
}

export function handleTradeTypeCreateAccount(type) {
  return dispatch => {
    dispatch({
      type: COPYTRADING_TYPE_CREATE_ACCOUNT,
      payload: type
    })
  }
}

export function handleTradeSyncUpdateAccount(accountId, params) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.AccountUpdate, {accountId: accountId, params: params})
      .then(account => {
        dispatch({
          type: COPYTRADING_ACCOUNT_UPDATE_SUCCESS,
          payload: account
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_ACCOUNT_UPDATE_FAIL,
        payload: null
      })
    })
  }
}

export function handleTradeSyncDeleteAccount(accountId) {
  return dispatch => {
    dispatch({
      type: COPYTRADING_ACCOUNT_CREATE_START
    })
    return new Promise((resolve, reject) => {
      apiCopytradingRequest(apiCopytrading.AccountDelete, {accountId: accountId})
        .then(response => {
          if (response == null) {
            return
          }

          if (!response) {
            dispatch({
              type: COPYTRADING_ACCOUNT_DELETE_FAIL,
              error: true,
              payload: response
            })
            toastError('toast_text_24')
            reject('toast_text_24')
            return
          }
          dispatch({
            type: COPYTRADING_ACCOUNT_DELETE_SUCCESS,
            payload: accountId
          })
          toastSuccess('toast_text_23')
          resolve(accountId)
        }).catch(error => {
        console.error(error)
        dispatch({
          type: COPYTRADING_ACCOUNT_DELETE_FAIL,
          error: true,
          payload: new Error(error)
        })
        toastError('toast_text_24')
      })
    })
  }
}

export function handleTradeSyncListBrokers() {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.Brokers, {})
      .then(brokers => {
        dispatch({
          type: COPYTRADING_BROKER_LIST_SUCCESS,
          payload: brokers
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_BROKER_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncListBrokerServers() {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.BrokerALlServers, {})
      .then(servers => {
        dispatch({
          type: COPYTRADING_BROKER_SERVER_LIST_SUCCESS,
          payload: servers
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_BROKER_SERVER_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncListCopiers(followerId) {
  if (followerId == null) {
    return
  }
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.Copiers, {params: { "follower_id": followerId }})
      .then(copiers => {
        dispatch({
          type: COPYTRADING_COPIER_LIST_SUCCESS,
          payload: copiers
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncCreateCopier(params) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.CopierCreate, {params: params})
      .then(copier => {
        if (copier?.id == null) {
          return
        }
        apiCopytradingRequest(apiCopytrading.CopierUpdate, {copierId: copier?.id, params: {
          "mode": "on",
            "copy_existing": "no",
            "copy_pending": "no",
            "copy_sl": "no",
            "copy_tp": "no"
        }})
        dispatch({
          type: COPYTRADING_COPIER_CREATE_SUCCESS,
          payload: copier
        })
        toastSuccess('toast_text_25')
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastSuccess('toast_text_26')
    })
  }
}

export function handleRestapiCopytradingSubscribe(owner, masterAccountId, workspaceId, riskType, riskValue) {
  return dispatch => {
    const params = {
      owner: owner,
      attributes: {
        masterAccountId: masterAccountId,
        workspaceId: workspaceId,
        riskType: riskType,
        riskValue: riskValue
      }
    };
    apiCopytradingRequest(apiCopytrading.Subscribe, params)
      .then(response => {
        const copier = response?.programSubscription
        if (copier?.id != null) {
          dispatch({
            type: COPYTRADING_COPIER_CREATE_SUCCESS,
            payload: copier
          })
          toastSuccess('toast_text_25')
        }
        dispatch(handleChannelUnsubscribeResult(response?.channelUnsubscribe))
        dispatch(handleChannelSubscribeResult(response?.channelSubscription))
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastError('toast_text_26')
    })
  }
}

export function handleRestapiCopytradingUnsubscribe(owner, masterAccountId, copierId) {
  return dispatch => {
    const params = {
      owner: owner,
        attributes: {
        masterAccountId: masterAccountId,
          copierId: copierId
      }
    };
    apiCopytradingRequest(apiCopytrading.Unsubscribe, params)
      .then(response => {
        const copierId = response?.programUnsubscribe
        dispatch(handleRestapiCopytradingUnsubscribeResult(copierId))
        dispatch(handleChannelUnsubscribeResult(response?.channelUnsubscribe))
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_DELETE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastError('toast_text_26')
    })
  }
}

export function handleRestapiCopytradingUnsubscribeResult(copierId) {
  return dispatch => {
    dispatch({
      type: COPYTRADING_COPIER_DELETE_SUCCESS,
      payload: copierId
    })
    toastSuccess('toast_text_41')
  }
}

export function handleTradeSyncUpdateCopier(copier, params) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.CopierUpdate, {copierId: copier?.id, params: params})
      .then(response => {
        //
      }).catch(error => {
      console.error(error)
    })
  }
}

export function handleTradeSyncDeleteCopier(copierId) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.CopierDelete, {copierId: copierId})
      .then(response => {
        if (response == null) {
          return
        }
        dispatch({
          type: COPYTRADING_COPIER_DELETE_SUCCESS,
          payload: copierId
        })
        toastSuccess('toast_text_27')
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_COPIER_DELETE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastError('toast_text_28')
    })
  }
}

export function handleTradeSyncAnalysisAccount(params = {}) {
  return dispatch => {
    const { programId } = params
  
    return new Promise((resolve, reject) => {
      if (!programId) {
        reject(new Error('programId is not provided'))
        return
      }
      apiCopytradingRequest(apiCopytrading.ProgramAnalytics, { programId })
        .then(data => resolve(data))
        .catch(error => reject(error))
    }) 
  }
}

export function handleTradeSyncAnalysisAccounts() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCopytradingRequest(apiCopytrading.ProgramAnalytics, {})
        .then(data => {
          dispatch({
            type: COPYTRADING_ANALYSIS_ACCOUNT_LIST_SUCCESS,
            payload: data
          })
          resolve(data)
        })
        .catch(error => {
          console.error(error)
          dispatch({
            type: COPYTRADING_ANALYSIS_ACCOUNT_LIST_FAIL,
            error: true,
            payload: new Error(error)
          })
          reject(error)
        })
    }) 
  }
}

export function handleTradeSyncMyAccount({ owner } = {}) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!owner) {
        reject('owner is not provided!')
        return
      }
      apiCopytradingRequest(apiCopytrading.MyAccount, { owner })
        .then(data => {
            dispatch({
              type: COPYTRADING_TRADESYNC_FETCH_MY_ACCOUNT,
              payload: data
            })
            resolve(data)
          })
        .catch(error => {
          console.error(error)
          reject(error)
          // dispatch({
          //   type: COPYTRADING_ACCOUNT_LIST_FAIL,
          //   error: true,
          //   payload: new Error(error)
          // })
      })
    })
  }
}

export function handleTradeSyncListWebhooks() {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.Webhooks, {})
      .then(webhooks => {
        dispatch({
          type: COPYTRADING_WEBHOOK_LIST_SUCCESS,
          payload: webhooks
        })
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_WEBHOOK_LIST_FAIL,
        error: true,
        payload: new Error(error)
      })
    })
  }
}

export function handleTradeSyncCreateWebhook(params) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.WebhookCreate, {params: params})
      .then(webhook => {
        dispatch({
          type: COPYTRADING_WEBHOOK_CREATE_SUCCESS,
          payload: webhook
        })
        toastSuccess('toast_text_29')
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_WEBHOOK_CREATE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastError('toast_text_30')
    })
  }
}

export function handleTradeSyncDeleteWebhook(webhookId) {
  return dispatch => {
    apiCopytradingRequest(apiCopytrading.WebhookDelete, {webhookId: webhookId})
      .then(response => {
        if (response == null) {
          return
        }
        dispatch({
          type: COPYTRADING_WEBHOOK_DELETE_SUCCESS,
          payload: webhookId
        })
        toastSuccess('toast_text_31')
      }).catch(error => {
      console.error(error)
      dispatch({
        type: COPYTRADING_WEBHOOK_DELETE_FAIL,
        error: true,
        payload: new Error(error)
      })
      toastError('toast_text_32')
    })
  }
}

// DYNAMO DB ACTIONS

export function handleTradesyncsCreate(id, model, filterStr, filterInt, item) {
  return dispatch => {
    tradesyncCreate(id, model, filterStr, filterInt, item)
      .then(data => {
        dispatch({
          type: COPYTRADING_TRADESYNC_CREATE_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        dispatch({
          type: COPYTRADING_TRADESYNC_CREATE_FAIL,
          error: true,
          payload: new Error(err)
        })
      })
  }
}

export function handleTradesyncsRead(model) {
  return dispatch => {
    tradesyncRead(model)
      .then(data => {
        dispatch({
          type: COPYTRADING_TRADESYNC_LIST_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        dispatch({
          type: COPYTRADING_TRADESYNC_LIST_FAIL,
          error: true,
          payload: new Error(err)
        })
      })
  }
}

export function handleTradesyncsResetData() {
  return dispatch => {
    dispatch({
      type: COPYTRADING_TRADESYNC_RESET_DATA,
      payload: null
    })
  }
}