import {
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_READ_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_SIGNOUT_SUCCESS
} from '../actions/UserActions'
import { 
  USER_ROLE_CREATE_SUCCESS,
  USER_ROLE_READ_SUCCESS, 
  USER_ROLE_DELETE_SUCCESS, 
  USER_ROLE_UPDATE_SUCCESS
} from '../actions/UserRoleActions'

import { 
  USER_PRODUCT_CREATE_SUCCESS,
  USER_PRODUCT_READ_SUCCESS, 
  USER_PRODUCT_DELETE_SUCCESS, 
  USER_PRODUCT_UPDATE_SUCCESS
} from '../actions/UserProductActions'

import { 
  ROLE_PRODUCT_CREATE_SUCCESS,
  ROLE_PRODUCT_READ_SUCCESS, 
  ROLE_PRODUCT_DELETE_SUCCESS, 
  ROLE_PRODUCT_UPDATE_SUCCESS
} from '../actions/RoleProductActions'

import { 
  PRODUCT_READ_SUCCESS
} from '../actions/ProductActions'

const initialState = {
  owner: '',
  user: null,
  roles: null,
  products: [],
  userProducts: [],
  roleProducts: [],
  error: '',
  isFetching: false,
}

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case USER_CREATE_SUCCESS:
      return { ...state, isFetching: false, owner: action.payload?.owner, user: action.payload?.user, attributes: action.payload?.attributes}

    case USER_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case USER_READ_SUCCESS:
      return { ...state, isFetching: false, owner: action.payload?.owner, user: action.payload?.user, attributes: action.payload?.attributes }

    case USER_UPDATE_SUCCESS:
      return { ...state, isFetching: false, owner: action.payload?.owner, user: action.payload?.user, attributes: state.attributes }

    case USER_DELETE_SUCCESS:
      return { ...state, isFetching: false, owner: action.payload?.owner, user: action.payload?.user }
    case USER_SIGNOUT_SUCCESS:
      return initialState

    case USER_ROLE_CREATE_SUCCESS:
      if (state.roles.some(item => item.id === action.payload?.id)) { return state }
      return { ...state, roles: [...state.roles, action.payload] }
    case USER_ROLE_READ_SUCCESS:
      return { ...state, roles: action.payload }
    case USER_ROLE_UPDATE_SUCCESS:
      return { ...state, roles: state.roles.map(item => item.id === action.payload?.id ? action.payload : item) }
    case USER_ROLE_DELETE_SUCCESS:
      return { ...state, roles: state.roles.filter(item => item.id !== action.payload) }

    case USER_PRODUCT_CREATE_SUCCESS:
      return { ...state, userProducts: [...state.userProducts, action.payload] }
    case USER_PRODUCT_READ_SUCCESS:
      return { ...state, userProducts: action.payload }
    case USER_PRODUCT_UPDATE_SUCCESS:
      return { ...state, userProducts: state.userProducts.map(item => item.id === action.payload?.id ? action.payload : item) }
    case USER_PRODUCT_DELETE_SUCCESS:
      return { ...state, userProducts: state.userProducts.filter(item => item.id !== action.payload?.id) }

    case ROLE_PRODUCT_CREATE_SUCCESS:
      return { ...state, roleProducts: [...state.roleProducts, action.payload] }
    case ROLE_PRODUCT_READ_SUCCESS:
      return { ...state, roleProducts: action.payload }
    case ROLE_PRODUCT_UPDATE_SUCCESS:
      return { ...state, roleProducts: state.roleProducts.map(item => item.id === action.payload?.id ? action.payload : item) }
    case ROLE_PRODUCT_DELETE_SUCCESS:
      return { ...state, roleProducts: state.roleProducts.filter(item => item.id !== action.payload?.id) }

    case PRODUCT_READ_SUCCESS:
      return { ...state, products: action.payload }
    default:
      return state
  }
}