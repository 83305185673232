import { reactionInAppropriateRead, reactionUpdate } from "../../../services/api/reaction"
import * as K from '../../../utils/Constant'
import { messageDelete, messageUpdate } from "../../../services/api/message"

export const ADMIN_REACTION_MANAGEMENT_LIST_SUCCESS = "ADMIN_REACTION_MANAGEMENT_LIST_SUCCESS"
export const ADMIN_REACTION_MANAGEMENT_LIST_FAIL = "ADMIN_REACTION_MANAGEMENT_LIST_FAIL"

export const ADMIN_REACTION_MANAGEMENT_UPDATE_SUCCESS = "ADMIN_REACTION_MANAGEMENT_UPDATE_SUCCESS"
export const ADMIN_REACTION_MANAGEMENT_UPDATE_FAIL = "ADMIN_REACTION_MANAGEMENT_UPDATE_FAIL"

export function handleAdminReactionRead() {
  return dispatch => {
    reactionInAppropriateRead()
    .then(data => {
      dispatch({  
        type: ADMIN_REACTION_MANAGEMENT_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_REACTION_MANAGEMENT_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminReactionUpdate(reactionId, reaction, status, message) {
  return dispatch => {
    reactionUpdate(reactionId, reaction, status)
      .then(data => {
        dispatch({
          type: ADMIN_REACTION_MANAGEMENT_UPDATE_SUCCESS, payload: data
        });
        if (status === K.STATUS_CANCELED) {
          messageUpdate(message?.owner, message?.id, message?.content, true)
          .then(data => {
              //
           })
          .catch(err => {
              console.error("message wasn't deleted", err)
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADMIN_REACTION_MANAGEMENT_UPDATE_FAIL, error: true, payload: new Error(err)
        });
      });
  };
}