import { API, graphqlOperation } from "aws-amplify"
import { AWS_API_INITIAL_LIMIT, AWS_API_LIMIT } from "../../utils/Constant"

export const loadList = async (action, attribute, filter, findFirstItemsCount) => {
  const customFilters = new Map()
  const savedItems = await loadListWithCustomFilters(action, attribute, customFilters, filter, findFirstItemsCount)
  return savedItems
}

export const loadListWithCustomFilters = async (action, attribute, customFilters, filter, findFirstItemsCount, handler) => {
  const nextLoad = async (filter, nextToken, limit) => {
    var parameters = {
      filter: filter,
      limit: limit,
      nextToken: nextToken,
    }
    var _ = customFilters?.forEach((value, key) => parameters[key] = value)
    const response = await API.graphql(graphqlOperation(action, parameters ))
    return response
  }

  var savedItems = []
  var response = await nextLoad(filter, null, AWS_API_INITIAL_LIMIT)
  var items = response?.data[attribute]?.items?.filter(item => item != null) ?? []
  var token = response?.data[attribute]?.nextToken
  if (items?.length > 0) {
    if (handler != null) { handler(items, null) }
    savedItems = [...savedItems, ...items]
  }
  while (token != null) {
    if (findFirstItemsCount > 0 && savedItems.length >= findFirstItemsCount) { break }
    response = await nextLoad(filter, token, AWS_API_LIMIT)
    items = response?.data[attribute]?.items?.filter(item => item != null)
    token = response?.data[attribute]?.nextToken
    if (items?.length > 0) { savedItems = [...savedItems, ...items] }
    if (findFirstItemsCount > 0 && savedItems.length >= findFirstItemsCount) { break }
  }
  return savedItems
}