import { API, graphqlOperation } from 'aws-amplify';
import { listExtras, getExtra } from "../../graphql/queries";
import { createExtra, deleteExtra, updateExtra } from "../../graphql/mutations";
import { isBlankOrNull } from "../../utils/Helpres"

import { loadList } from "./shared"
const action = listExtras
const attribute = "listExtras"

export const HUB_OBSERVER_EXTRA = "extra"

export const extraCreate = async (authorName, name, brief, description, info, categories, products, isPublic, rating, source, media) => {
  if (isBlankOrNull(authorName) || isBlankOrNull(name) || categories == null || products == null || isPublic == null || rating == null || isBlankOrNull(source)) {
    console.error('Extra API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createExtra, {input: {
      authorName: authorName,
      name: name?.trim(),
      brief: brief?.trim() ?? "",
      description: description?.trim() ?? "",
      info: info?.trim() ?? "",
      categories: categories,
      products: products,
      public: isPublic,
      rating: rating,
      source: source,
      media: media,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createExtra
  } catch (err) {
    console.error('error creating extra:', err)
    return
  }
}

export const extraRead = async () => {
  try {
    const extras = await loadList(action, attribute, null, 0)
    return extras
  } catch (err) { 
    console.error('error fetching extra', err)
    return null
  }  
}

export const extraUpdate = async (extraId, name, brief, description, info, categories, products, isPublic, rating, media, source) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getExtra, 
      { id: extraId }
    ))
    original = exist?.data?.getExtra
  } catch (err) { console.error('error fetching extra:', err) }
  if (original?.id === extraId) {
    if (extraId == null || isBlankOrNull(name) || categories == null || products == null || isPublic == null || rating == null || isBlankOrNull(source)) {
      console.error('Extra API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateExtra, {input: {
        id: extraId, 
        name: name?.trim(),
        brief: brief?.trim() ?? "",
        description: description?.trim() ?? "",
        info: info?.trim() ?? "",
        categories: categories,
        products: products,
        public: isPublic,
        rating: rating,
        source: source,
        media: media,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateExtra
    } catch (err) {
      console.error('error updating extra:', err)
      return
    }
  } else {
    return null
  }
}

export const extraDelete = async (extraId) => {
  await API.graphql(graphqlOperation(deleteExtra, { input: { id: extraId }}));
  return extraId
}

export const publishedExtraUpdate = async (extraId, name, brief, description, isPublic, products) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getExtra, 
      { id: extraId }
    ))
    original = exist?.data?.getExtra
  } catch (err) { console.error('error fetching extra:', err) }
  if (original?.id === extraId) {
    if (extraId == null || isBlankOrNull(name) || isPublic == null || products == null) {
      console.error('Extra API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateExtra, {input: {
        id: extraId, 
        name: name?.trim(),
        brief: brief?.trim() ?? "",
        description: description?.trim() ?? "",
        public: isPublic,
        products: products,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateExtra
    } catch (err) {
      console.error('error updating extra:', err)
      return
    }
  } else {
    return null
  }
}