import { HUB_OBSERVER_ROLE } from '../../services/api/role'
import { roleCreate, roleGet, roleRead, roleUpdate, roleDelete, roleObserve }  from '../../services/api/role'
import { Hub } from 'aws-amplify'

import { add, remove, clear } from "react-redux-permissions"
import * as K from '../../utils/Constant'

export const ROLE_CREATE_REQUEST = 'ROLE_CREATE_REQUEST'
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS'
export const ROLE_CREATE_FAIL = 'ROLE_CREATE_FAIL'

export const ROLE_GET_REQUEST = 'ROLE_GET_REQUEST'
export const ROLE_GET_SUCCESS = 'ROLE_GET_SUCCESS'
export const ROLE_GET_FAIL = 'ROLE_GET_FAIL'

export const ROLE_READ_REQUEST = 'ROLE_READ_REQUEST'
export const ROLE_READ_SUCCESS = 'ROLE_READ_SUCCESS'
export const ROLE_READ_FAIL = 'ROLE_READ_FAIL'

export const ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST'
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS'
export const ROLE_UPDATE_FAIL = 'ROLE_UPDATE_FAIL'

export const ROLE_DELETE_REQUEST = 'ROLE_DELETE_REQUEST'
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS'
export const ROLE_DELETE_FAIL = 'ROLE_DELETE_FAIL'

export function handleRoleCreate(name, description) {
  return dispatch => {
    dispatch({
      type: ROLE_CREATE_REQUEST,
      payload: { name: name, description: description },
    })
    roleCreate(name, description)
    .then(data => {
      dispatch({
        type: ROLE_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ROLE_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleRoleGet(roleId) {
  return dispatch => {
    dispatch({
      type: ROLE_GET_REQUEST,
      payload: roleId,
    })
    roleGet(roleId)
    .then(data => {
      Hub.dispatch(HUB_OBSERVER_ROLE, { event: HUB_OBSERVER_ROLE, data: data, message: HUB_OBSERVER_ROLE })
      dispatch({
        type: ROLE_GET_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {      
      dispatch({
        type: ROLE_GET_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleRoleRead() {
  return dispatch => {
    dispatch({
      type: ROLE_READ_REQUEST,
      payload: null,
    })
    roleRead()
    .then(data => {
      Hub.dispatch(HUB_OBSERVER_ROLE, { event: HUB_OBSERVER_ROLE, data: data, message: HUB_OBSERVER_ROLE })
      dispatch({
        type: ROLE_READ_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {      
      dispatch({
        type: ROLE_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleRoleUpdate(roleId, name, description) {
  return dispatch => {
    dispatch({
      type: ROLE_UPDATE_REQUEST,
      payload: { roleId: roleId, name: name, description: description },
    })
    roleUpdate(roleId, name, description)
    .then(data => {
      dispatch({
        type: ROLE_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ROLE_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleRoleDelete(roleId) {
  return dispatch => {
    dispatch({
      type: ROLE_DELETE_REQUEST,
      payload: { roleId: roleId },
    })
    roleDelete(roleId)
    .then(data => {
      dispatch({
        type: ROLE_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: ROLE_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function observeRole(roleId) {
  return dispatch => {
    roleObserve(roleId)
  }
}

export function handleRoleObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: ROLE_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: ROLE_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: ROLE_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleRoleObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'role':
        const permissions = data?.permissions.map(el => {
          return {
            position: el?.position,
            permissionId: el?.permissionId,
            permission: el?.permission,
            enabled: el?.enabled,
            policy: el?.policy === "none" ? null : JSON.parse(el?.policy)
          }
        })
        dispatch(applyRoles(permissions))
        break;
      case 'INSERT':
        dispatch({
          type: ROLE_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: ROLE_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: ROLE_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function applyRoles(rolePermissions) {
  return dispatch => {
    dispatch(clear())
    for (let rolePermission of rolePermissions) {
      if (rolePermission?.policy == null) {
        rolePermission?.enabled ? dispatch(add(rolePermission?.permission)) : dispatch(remove(rolePermission?.permission))
      } else {
        rolePermission?.enabled && rolePermission?.policy?.privateOwner === "true" ?
          dispatch(add(rolePermission?.permission?.concat(K.PRIVATE_POLICY)?.concat(K.OWNER_POLICY)))
          :
          dispatch(remove(rolePermission?.permission?.concat(K.PRIVATE_POLICY)?.concat(K.OWNER_POLICY)))

        rolePermission?.enabled && rolePermission?.policy?.privateGuest === "true" ?
          dispatch(add(rolePermission?.permission?.concat(K.PRIVATE_POLICY)?.concat(K.GUEST_POLICY)))
          :
          dispatch(remove(rolePermission?.permission?.concat(K.PRIVATE_POLICY)?.concat(K.GUEST_POLICY)))

        rolePermission?.enabled && rolePermission?.policy?.publicOwner === "true" ?
          dispatch(add(rolePermission?.permission?.concat(K.PUBLIC_POLICY)?.concat(K.OWNER_POLICY)))
        :
          dispatch(remove(rolePermission?.permission?.concat(K.PUBLIC_POLICY)?.concat(K.OWNER_POLICY)))

        rolePermission?.enabled && rolePermission?.policy?.publicGuest === "true" ?
          dispatch(add(rolePermission?.permission?.concat(K.PUBLIC_POLICY)?.concat(K.GUEST_POLICY)))
        :
          dispatch(remove(rolePermission?.permission?.concat(K.PUBLIC_POLICY)?.concat(K.GUEST_POLICY)))

        rolePermission?.enabled && rolePermission?.policy?.own === "true" ?
          dispatch(add(rolePermission?.permission?.concat(K.OWN_POLICY)))
        :
          dispatch(remove(rolePermission?.permission?.concat(K.OWN_POLICY)))

        rolePermission?.enabled && rolePermission?.policy?.other === "true" ?
          dispatch(add(rolePermission?.permission?.concat(K.OTHER_POLICY)))
        :
          dispatch(remove(rolePermission?.permission?.concat(K.OTHER_POLICY)))
      }
    }
  }
}

