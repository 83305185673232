/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($id: ID!) {
    onCreateUser(id: $id) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($id: ID!) {
    onDeleteUser(id: $id) {
      id
      owner
      email
      name
      phone
      country
      username
      info
      publicByName
      publicByUsername
      publicByEmail
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel($owner: String, $public: Boolean!) {
    onCreateChannel(owner: $owner, public: $public) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel($owner: String, $public: Boolean!) {
    onUpdateChannel(owner: $owner, public: $public) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel($owner: String, $public: Boolean!) {
    onDeleteChannel(owner: $owner, public: $public) {
      id
      owner
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      filterRules {
        channelId
        categories
        labels
        disable
      }
      status
      media
      shared
      channelUserId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExtra = /* GraphQL */ `
  subscription OnCreateExtra($public: Boolean!) {
    onCreateExtra(public: $public) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExtra = /* GraphQL */ `
  subscription OnUpdateExtra($public: Boolean!) {
    onUpdateExtra(public: $public) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExtra = /* GraphQL */ `
  subscription OnDeleteExtra($public: Boolean!) {
    onDeleteExtra(public: $public) {
      id
      authorName
      name
      brief
      description
      info
      categories
      products {
        itemId
        isPublic
        isPaid
      }
      public
      rating
      source
      media
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStream = /* GraphQL */ `
  subscription OnCreateStream($owner: String!) {
    onCreateStream(owner: $owner) {
      id
      owner
      streamUserId
      streamChannelId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStream = /* GraphQL */ `
  subscription OnUpdateStream($owner: String!) {
    onUpdateStream(owner: $owner) {
      id
      owner
      streamUserId
      streamChannelId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStream = /* GraphQL */ `
  subscription OnDeleteStream($owner: String!) {
    onDeleteStream(owner: $owner) {
      id
      owner
      streamUserId
      streamChannelId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      trial {
        createdAt
        endedAt
      }
      lastReadedTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWidget = /* GraphQL */ `
  subscription OnCreateWidget($owner: String!, $workspaceId: ID) {
    onCreateWidget(owner: $owner, workspaceId: $workspaceId) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      stream {
        id
        owner
        streamUserId
        streamChannelId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          products {
            itemId
            isPublic
            isPaid
          }
          public
          filterRules {
            channelId
            categories
            labels
            disable
          }
          status
          media
          shared
          channelUserId
          user {
            id
            owner
            email
            name
            phone
            country
            username
            info
            publicByName
            publicByUsername
            publicByEmail
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWidget = /* GraphQL */ `
  subscription OnUpdateWidget($owner: String!, $workspaceId: ID) {
    onUpdateWidget(owner: $owner, workspaceId: $workspaceId) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      stream {
        id
        owner
        streamUserId
        streamChannelId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          products {
            itemId
            isPublic
            isPaid
          }
          public
          filterRules {
            channelId
            categories
            labels
            disable
          }
          status
          media
          shared
          channelUserId
          user {
            id
            owner
            email
            name
            phone
            country
            username
            info
            publicByName
            publicByUsername
            publicByEmail
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWidget = /* GraphQL */ `
  subscription OnDeleteWidget($owner: String!, $workspaceId: ID) {
    onDeleteWidget(owner: $owner, workspaceId: $workspaceId) {
      id
      owner
      top
      left
      width
      height
      minWidth
      minHeight
      isFullHeight
      content
      visible
      workspaceId
      widgetWorkspaceId
      widgetStreamId
      workspace {
        id
        owner
        name
        position
        lastActiveWidgetId
        workspaceUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      stream {
        id
        owner
        streamUserId
        streamChannelId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        channel {
          id
          owner
          name
          brief
          description
          info
          categories
          products {
            itemId
            isPublic
            isPaid
          }
          public
          filterRules {
            channelId
            categories
            labels
            disable
          }
          status
          media
          shared
          channelUserId
          user {
            id
            owner
            email
            name
            phone
            country
            username
            info
            publicByName
            publicByUsername
            publicByEmail
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        trial {
          createdAt
          endedAt
        }
        lastReadedTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkspace = /* GraphQL */ `
  subscription OnCreateWorkspace($owner: String!) {
    onCreateWorkspace(owner: $owner) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkspace = /* GraphQL */ `
  subscription OnUpdateWorkspace($owner: String!) {
    onUpdateWorkspace(owner: $owner) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkspace = /* GraphQL */ `
  subscription OnDeleteWorkspace($owner: String!) {
    onDeleteWorkspace(owner: $owner) {
      id
      owner
      name
      position
      lastActiveWidgetId
      workspaceUserId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($channelId: ID!) {
    onCreateMessage(channelId: $channelId) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($channelId: ID!) {
    onUpdateMessage(channelId: $channelId) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($channelId: ID!) {
    onDeleteMessage(channelId: $channelId) {
      id
      owner
      content {
        threadId
        category
        title
        comment
        color
        media
        table
      }
      deleted
      channelId
      messageUserId
      messageChannelId
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      channel {
        id
        owner
        name
        brief
        description
        info
        categories
        products {
          itemId
          isPublic
          isPaid
        }
        public
        filterRules {
          channelId
          categories
          labels
          disable
        }
        status
        media
        shared
        channelUserId
        user {
          id
          owner
          email
          name
          phone
          country
          username
          info
          publicByName
          publicByUsername
          publicByEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserRole = /* GraphQL */ `
  subscription OnCreateUserRole($userId: ID!) {
    onCreateUserRole(userId: $userId) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserRole = /* GraphQL */ `
  subscription OnUpdateUserRole($userId: ID!) {
    onUpdateUserRole(userId: $userId) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserRole = /* GraphQL */ `
  subscription OnDeleteUserRole($userId: ID!) {
    onDeleteUserRole(userId: $userId) {
      id
      roleId
      userId
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoleProduct = /* GraphQL */ `
  subscription OnCreateRoleProduct($roleId: ID!) {
    onCreateRoleProduct(roleId: $roleId) {
      id
      productId
      roleId
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoleProduct = /* GraphQL */ `
  subscription OnUpdateRoleProduct($roleId: ID!) {
    onUpdateRoleProduct(roleId: $roleId) {
      id
      productId
      roleId
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoleProduct = /* GraphQL */ `
  subscription OnDeleteRoleProduct($roleId: ID!) {
    onDeleteRoleProduct(roleId: $roleId) {
      id
      productId
      roleId
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      role {
        id
        name
        description
        permissions {
          position
          permissionId
          permission
          enabled
          policy
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserProduct = /* GraphQL */ `
  subscription OnCreateUserProduct($userId: ID!) {
    onCreateUserProduct(userId: $userId) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserProduct = /* GraphQL */ `
  subscription OnUpdateUserProduct($userId: ID!) {
    onUpdateUserProduct(userId: $userId) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserProduct = /* GraphQL */ `
  subscription OnDeleteUserProduct($userId: ID!) {
    onDeleteUserProduct(userId: $userId) {
      id
      trial {
        createdAt
        endedAt
      }
      productId
      userId
      product {
        id
        bundle
        name
        description
        public
        item {
          itemId
          authorId
          authorName
          authorShare
        }
        features {
          position
          permissionId
          permission
          enabled
          policy
        }
        products {
          product
          bundle
          price
          discount
          packageShare
        }
        price
        trial {
          value
          unit
        }
        currency
        media
        createdAt
        updatedAt
      }
      user {
        id
        owner
        email
        name
        phone
        country
        username
        info
        publicByName
        publicByUsername
        publicByEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($owner: String!) {
    onCreateEvent(owner: $owner) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($owner: String!) {
    onUpdateEvent(owner: $owner) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($owner: String!) {
    onDeleteEvent(owner: $owner) {
      id
      owner
      topic
      event
      payload
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
    onCreateRole(filter: $filter) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
    onUpdateRole(filter: $filter) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
    onDeleteRole(filter: $filter) {
      id
      name
      description
      permissions {
        position
        permissionId
        permission
        enabled
        policy
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
    onCreateProduct(filter: $filter) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
    onUpdateProduct(filter: $filter) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
    onDeleteProduct(filter: $filter) {
      id
      bundle
      name
      description
      public
      item {
        itemId
        authorId
        authorName
        authorShare
      }
      features {
        position
        permissionId
        permission
        enabled
        policy
      }
      products {
        product
        bundle
        price
        discount
        packageShare
      }
      price
      trial {
        value
        unit
      }
      currency
      media
      createdAt
      updatedAt
    }
  }
`;
