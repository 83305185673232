import { apiBaseRequest } from "./apiBase"

export const apiPayments = {
  GetCurrency: "apiPaymentsGetCurrency",
  CreateCustomer: "apiPaymentsCreateCustomer",
  ListCustomers: "apiPaymentsListCustomers",
  GetCustomer: "apiPaymentsGetCustomer",
  DestroyCustomer: "apiPaymentsDestroyCustomer",
  GetBalance: "apiPaymentsGetBalance",
  GetCustomerPaymentMethod: "apiPaymentsGetCustomerPaymentMethod",
  AttachCustomerPaymentMethod: "apiPaymentsAttachCustomerPaymentMethod",
  CreateProduct: "apiPaymentsCreateProduct",
  ListProduct: "apiPaymentsListProduct",
  DestroyProduct: "apiPaymentsDestroyProduct",
  CreatePrice: "apiPaymentsCreatePrice",
  ListPrice: "apiPaymentsListPrice",
  DestroyPrice: "apiPaymentsDestroyPrice",
  CreateSubscription: "apiPaymentsCreateSubscription",
  UpdateSubscription: "apiPaymentsUpdateSubscription",
  ListSubscriptions: "apiPaymentsListSubscriptions",
  GetSubscription: "apiPaymentsGetSubscription",
  DestroySubscription: "apiPaymentsDestroySubscription",
  CreateCardToken: "apiPaymentsCreateCardToken",
  CreateCard: "apiPaymentsCreateCard",
  DestroyCard: "apiPaymentsDestroyCard",
  CreateAccount: "apiPaymentsCreateAccount",
  ListAccounts: "apiPaymentsListAccounts",
  GetAccount: "apiPaymentsGetAccount",
  DestroyAccount: "apiPaymentsDestroyAccount",
  ListAccountTransfers: "apiPaymentsListAccountTransfers",
  OpenCustomerPortalLink: "apiPaymentsOpenCustomerPortalLink",
  CreateAccountLink: "apiPaymentsCreateAccountLink",
  OpenAccountLink: "apiPaymentsOpenAccountLink",
  CreateSetupIntent: "apiPaymentsCreateSetupIntent"
}

export const apiPaymentRequest = async (action, payload) => {
  if (action == null || payload == null) {
    console.error('Rest Api: Failed payment request: empty params');
    return null;
  }
  return await apiBaseRequest('/api', action, payload);
};