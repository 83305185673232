import {
  CREATE_TEMPLATE,
} from '../actions/TemplateActions'

const initialState = {
  template: [],
  // error: '',
  // isFetching: false,
}

export function createTemplate(state = initialState, action) {
  switch (action.type) {
    case CREATE_TEMPLATE:
      return { 
        ...state,
        template: [...state.template.concat(action.payload)]
      }
    default:
      return state
  }
}