import {
  streamCreate,
  streamRead,
  streamUpdate,
  streamDelete,
  channelSubscribers,
  streamGet
} from "../../services/api/stream"
import { workspaceRead }  from '../../services/api/workspace'
import { widgetCreate }  from '../../services/api/widget'
import { WIDGET_CREATE_SUCCESS }  from '../actions/WidgetActions'

import { toastSuccess, toastError } from "../../shared/platform/Toast"

export const STREAM_CREATE_REQUEST = 'STREAM_CREATE_REQUEST'
export const STREAM_CREATE_SUCCESS = 'STREAM_CREATE_SUCCESS'
export const STREAM_CREATE_FAIL = 'STREAM_CREATE_FAIL'

export const STREAM_READ_REQUEST = 'STREAM_READ_REQUEST'
export const STREAM_READ_SUCCESS = 'STREAM_READ_SUCCESS'
export const STREAM_READ_FAIL = 'STREAM_READ_FAIL'

export const STREAM_GET_REQUEST = 'STREAM_GET_REQUEST'
export const STREAM_GET_SUCCESS = 'STREAM_GET_SUCCESS'
export const STREAM_GET_FAIL = 'STREAM_GET_FAIL'

export const STREAM_UPDATE_REQUEST = 'STREAM_UPDATE_REQUEST'
export const STREAM_UPDATE_SUCCESS = 'STREAM_UPDATE_SUCCESS'
export const STREAM_UPDATE_FAIL = 'STREAM_UPDATE_FAIL'

export const STREAM_DELETE_REQUEST = 'STREAM_DELETE_REQUEST'
export const STREAM_DELETE_SUCCESS = 'STREAM_DELETE_SUCCESS'
export const STREAM_DELETE_FAIL = 'STREAM_DELETE_FAIL'

export const STREAM_SUBSCRIBERS_SUCCESS = 'STREAM_SUBSCRIBERS_SUCCESS'
export const STREAM_SUBSCRIBERS_FAIL = 'STREAM_SUBSCRIBERS_FAIL'

export function handleStreamCreate(owner, user, channel, top, left, width, height, workspace, trial) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: STREAM_CREATE_REQUEST,
        payload: { owner: owner, user: user, channel: channel, top: top, left: left, width: width, height: height, trial: trial },
      })
      streamCreate(owner, user, channel, trial)
      .then(stream => {
        if (stream != null) { 
          workspaceRead(stream.owner)
            .then(workspaces => {
              if (stream != null && workspaces?.length > 0 && top != null && left != null && width != null && height != null) {
                widgetCreate(stream.owner, workspace, stream, top, left, width, height, 300, 300, stream.channel?.name)
                .then(widget => {    
                  dispatch({
                    type: WIDGET_CREATE_SUCCESS,
                    payload: widget,
                  })
                  toastSuccess('toast_text_9')
                  resolve()
                })            
              }
            })
        } else {
          toastError('toast_text_10')
          reject()
        }
        dispatch({
          type: STREAM_CREATE_SUCCESS,
          payload: stream,        
        })
      })
      .catch(err => {
        dispatch({
          type: STREAM_CREATE_FAIL,
          error: true,
          payload: new Error(err),
        })
        toastError('toast_text_10')
        reject()
      })
    })
  }
}

export function handleStreamRead(owner) {
  return dispatch => {
    dispatch({
      type: STREAM_READ_REQUEST,
      payload: owner,
    })
    streamRead(owner)
    .then(data => {
      dispatch({
        type: STREAM_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: STREAM_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleStreamGet(streamId) {
  return dispatch => {
    dispatch({
      type: STREAM_GET_REQUEST,
      payload: streamId,
    })
    streamGet(streamId)
      .then(data => {
        dispatch({
          type: STREAM_GET_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: STREAM_GET_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleStreamUpdate(owner, streamId, lastReadedTime) {
  return dispatch => {
    dispatch({
      type: STREAM_UPDATE_REQUEST,
      payload: { owner: owner, streamId: streamId, lastReadedTime: lastReadedTime },
    })
    streamUpdate(owner, streamId, lastReadedTime)
    .then(data => {
      dispatch({
        type: STREAM_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: STREAM_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleStreamDelete(owner, streamId) {
  return dispatch => {
    dispatch({
      type: STREAM_DELETE_REQUEST,
      payload: { owner: owner, streamId: streamId },
    })
    streamDelete(owner, streamId)
    .then(data => {
      dispatch({
        type: STREAM_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: STREAM_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleChannelSubscribers(channelId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      channelSubscribers(channelId)
        .then(streams => {
          dispatch({
            type: STREAM_SUBSCRIBERS_SUCCESS,
            payload: streams,
          })
          resolve(streams)
        })
        .catch(err => {
          dispatch({
            type: STREAM_SUBSCRIBERS_FAIL,
            error: true,
            payload: new Error(err),
          })
          reject(err)
        })
    })
  }
}