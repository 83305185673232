import { API, graphqlOperation } from 'aws-amplify';
import { listSettings, getSetting } from "../../graphql/queries";
import { createSetting, deleteSetting, updateSetting } from "../../graphql/mutations";

import { loadList } from "./shared"
const action = listSettings
const attribute = "listSettings"

export const HUB_OBSERVER_SETTINGS = "setting"

export const settingCreate = async (owner, user, title, detail, content) => {

  if (owner == null || user == null || title == null || detail == null || content == null) { 
    console.error('Setting API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createSetting, {input: {
      owner: owner, 
      title: title,
      detail: detail,
      content: content,
      settingsUserId: user.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createSetting
  } catch (err) {
    console.error('error creating setting:', err)
    return
  }
}

export const settingRead = async (owner) => {
  try {
    const filter = {
      owner: {
        eq: owner
      }
    }
    const setting = await loadList(action, attribute, filter, 0)
    return setting
  } catch (err) { 
    console.error('error fetching setting', err)
    return null
  }  
}

export const settingUpdate = async (owner, settingId, title, detail, content) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getSetting, 
      { id: settingId }
    ))
    original = exist?.data?.getSetting
  } catch (err) { console.error('error fetching setting:', err) }
  if (original?.id === settingId) {
    if (owner == null || settingId == null || title == null || detail == null || content == null) { 
      console.error('Setting API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateSetting, {input: {
        id: settingId, 
        title: title,
        detail: detail,      
        content: content,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateSetting
    } catch (err) {
      console.error('error updating setting:', err)
      return
    }
  } else {
    return null
  }
}

export const settingDelete = async (owner, settingId) => {
  await API.graphql(graphqlOperation(deleteSetting, { input: { id: settingId }}));
  return settingId
}
