export const CREATE_POST = 'CREATE_POST'
export const DELETE_POST = 'DELETE_POST'
export const DELETE_ALL_POSTS = 'DELETE_ALL_POSTS'

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_COLOR = 'UPDATE_COLOR'
export const UPDATE_TITLE = 'UPDATE_TITLE'
export const ADD_ROW_TABLE = 'ADD_ROW_TABLE'
export const UPDATE_ROW_TABLE = 'UPDATE_ROW_TABLE'
export const DELETE_ROW_TABLE = 'DELETE_ROW_TABLE'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'

export function createPost(post) {
  return dispatch => {
    dispatch({
      type: CREATE_POST,
      payload: post
    })      
  }
}

export function deletePost(id) {
  return dispatch => {
    dispatch({
      type: DELETE_POST,
      payload: id
    })
  }
}

export function deleteAllPosts() {
  return dispatch => {
    dispatch({
      type: DELETE_ALL_POSTS,
      payload: null
    })
  }
}

export function updateCategory(id) {
  return dispatch => {
    dispatch({
      type: UPDATE_CATEGORY,
      payload: id
    })
  }
}

export function updateColor(id) {
  return dispatch => {
    dispatch({
      type: UPDATE_COLOR,
      payload: id
    })  
  }
}

export function updateTitle(id) {
  return dispatch => {
    dispatch({
      type: UPDATE_TITLE,
      payload: id
    })      
  }
}

export function addRowTable(id, position, title, detail) {
  return dispatch => {
    dispatch({
      type: ADD_ROW_TABLE,
      payload: {id: id,  row: {position: position, title: title, detail: detail}},
    })    
  }  
}

export function updateRowTable(id, position, title, detail) {
  return dispatch => {
    dispatch({
      type: UPDATE_ROW_TABLE,
      payload: {id: id,  row: {position: position, title: title, detail: detail}},
    })    
  }  
}

export function deleteRowTable(id, position) {
  return dispatch => {
    dispatch({
      type: DELETE_ROW_TABLE,
      payload: {id: id,  row: {position: position}},
    })    
  }  
}

export function updateMedia(id) {
  return dispatch => {
    dispatch({
      type: UPDATE_MEDIA,
      payload: id
    })
  }
}

export function updateComment(id) {
  return dispatch => {
    dispatch({
      type: UPDATE_COMMENT,
      payload: id
    })    
  }
}
