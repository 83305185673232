import { isBlankOrNull } from "../../utils/Helpres"
import { STATUS_SUBMITTED } from "../../utils/Constant"

export const validateChannelUpdate = (original, owner, channelId, name, description, info, isPublic, status, filterRules, categories) => {
  if (owner == null || channelId == null || isBlankOrNull(name) || isPublic == null || status == null || categories == null) {
    console.error('Channel API: Required fields cannot be empty!')
    return false
  }
  return true
}

export const validatePublicChannelUpdate = (original, channelId, name, brief, description, media, isPublic, status, products) => {
  if (channelId == null || isBlankOrNull(name) || isBlankOrNull(brief) || isPublic == null || status == null || products == null) {
    console.error('Channel API: Required fields cannot be empty!')
    return false
  }
  if (original.public === true && isPublic === false) {
    console.error('Channel API: Cannot be NOT public because was published!')
    return false
  }
  if (original.status !== STATUS_SUBMITTED && status === STATUS_SUBMITTED) {
    console.error('Channel API: Cannot be SUBMITTED because has product!')
    return false
  }
  return true
}