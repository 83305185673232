import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listEvents, getEvent } from "../../graphql/queries";
import { createEvent, deleteEvent, updateEvent } from "../../graphql/mutations";
import { onCreateEvent, onUpdateEvent, onDeleteEvent } from "../../graphql/subscriptions";
import { isBlankOrNull } from "../../utils/Helpres"

import { loadList } from "./shared"
const action = listEvents
const attribute = "listEvents"

export const HUB_OBSERVER_EVENT = "event"

let subscriptions = []

export const eventCreate = async (owner, topic, event, payload) => {
  if (isBlankOrNull(owner) || isBlankOrNull(topic) || isBlankOrNull(event) || isBlankOrNull(payload)) {
    console.error('Event API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createEvent, {input: {
      owner: owner,
      topic: topic,
      event: event,
      payload: payload,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createEvent
  } catch (err) {
    console.error('error creating event:', err)
    return
  }
}

export const eventRead = async (owner) => {
  try {
    const filter = {
      or : [
        {
          topic: {
            eq: "plugin",
          },
          event: {
            eq: "copytrading"
          }
        },
        {
          owner: {
            eq: owner,
          }
        }
      ]
    }
    const events = await loadList(action, attribute, filter, 0)
    return events
  } catch (err) { 
    return null
  }  
}

export const eventUpdate = async (owner, eventId, payload) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getEvent, 
      { id: eventId }
    ))
    original = exist?.data?.getEvent
  } catch (err) { console.error('error fetching event:', err) }
  if (original?.id === eventId && original?.owner === owner) {
    if (isBlankOrNull(payload)) {
      console.error('Event API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateEvent, {input: {
        id: eventId, 
        payload: payload,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateEvent
    } catch (err) {
      console.error('error updating event:', err)
      return
    }
  } else {
    return null
  }
}

export const eventDelete = async (owner, eventId) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getEvent,
      { id: eventId }
    ))
    original = exist?.data?.getEvent
  } catch (err) { console.error('error fetching event:', err) }
  if (original?.id === eventId && original?.owner === owner) {
    try {
      await API.graphql(graphqlOperation(deleteEvent, { input: { id: eventId }}));
      return eventId
    } catch (err) {
      console.error('error delete event:', err)
      return eventId
    }
  } else {
    console.error('error delete event: Invalid ID or owner')
    return  null
  }
}

export const eventObserve = async (owner) => {
  if ( subscriptions.length === 3 ) { return }
  const createSubscription = API.graphql(graphqlOperation(onCreateEvent, { owner: owner } )).subscribe({
    next: response => {
      const event = response.value.data.onCreateEvent
      Hub.dispatch(HUB_OBSERVER_EVENT, {event: 'INSERT', data: event, message:'create event observe' });  
    },
  })
  const createEvent = 'INSERT'
  subscriptions.push({createSubscription, createEvent})
  const updateSubscription = API.graphql(graphqlOperation(onUpdateEvent, { owner: owner } )).subscribe({
    next: response => {
      const event = response.value.data.onUpdateEvent
      Hub.dispatch(HUB_OBSERVER_EVENT, {event: 'UPDATE', data: event, message:'create event observe' });  
    },
  })
  const updateEvent = 'UPDATE'
  subscriptions.push({updateSubscription, updateEvent})
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteEvent, { owner: owner } )).subscribe({
    next: response => {
      const event = response.value.data.onDeleteEvent
      Hub.dispatch(HUB_OBSERVER_EVENT, {event: 'DELETE', data: event, message:'create event observe' });  
    },
  })
  const deleteEvent = 'DELETE'  
  subscriptions.push({deleteSubscription, deleteEvent})
}

export async function deleteEventObservers() {
  for (let sub of subscriptions) {
    if (sub.subscription != null) {
        sub.subscription.unsubscribe()
    }
  }          
}