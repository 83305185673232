import { apiBaseRequest } from "./apiBase"

export const apiEmailTrigger = {
  SignUp: "triggerEmailSignUp",
  PublicChannelAdded: "triggerEmailPublicChannelAdded",
  PublicChannelApproved: "triggerEmailPublicChannelApproved",
  PublicChannelBlocked: "triggerEmailPublicChannelBlocked",
  Subscription: "triggerEmailSubscription",
  SubscriptionCancel: "triggerEmailSubscriptionCancel",
  TSAccountStatus: "triggerEmailTSAccountStatus",
  ProgramAdded: "triggerEmailProgramAdded",
  ProgramApproved: "triggerEmailProgramApproved",
  ProgramBlocked: "triggerEmailProgramBlocked"
}

export const apiEmailRequest = async (action, owner, attributes) => {
  if (action == null || attributes == null) {
    console.error('Rest Api: Failed request on trigger email: empty params');
    return null;
  }
  const payload = owner != null ? {
    owner: owner,
    attributes: attributes
  } : {
    attributes: attributes
  };
  return await apiBaseRequest('/sendemail', action, payload);
};