import React, { Component } from "react"
import { connect } from "react-redux"
import * as K from "../utils/Constant"
import { Hub } from "aws-amplify"

import { handleShowModal } from "../store/actions/ModalActions"

import NoCardOnFile from "../components/modal/permission-message/NoCardOnFile"
import AlertNewChannel from "../components/modal/channels/AlertNewChannel"
import ModalProgressBar from "../components/modal/progress-bar/ModalProgressBar"
import ShareLinkChannelModal from "../components/modal/channels/ShareLinkChannelModal"
import AlertShareLinkModal from "../components/modal/channels/AlertShareLinkModal"
import Queue from "../shared/queue/Queue"
import DisclamerModal from "../components/modal/disclaimer/DisclamerModal"
import XLSX from "../components/modal/preview/xlsx"
import DOC from "../components/modal/preview/doc"

class ModalContainer extends Component {
  constructor(props) {
    super(props)
    this.queue = new Queue()
  }
  
  componentDidMount() {
    Hub.listen(K.HUB_MODAL, this.renderModal)
  }
  
  componentDidUpdate() {
    this.showNextModal(false)
  }
  
  componentWillUnmount() {
    if (this.renderModal) Hub.remove(K.HUB_MODAL, this.renderModal)
  }
  
  renderModal = async ({ payload: { event, data, message } }) => {
    if (event === K.MODAL_FILE_PROGRESS) {
      if (data?.show) {
        this.showModal(event, data)
      } else {
        this.showModal(null, null)
      }
      return
    }
    this.queue.push([event, data])
    this.showNextModal(false)
  }
  
  showNextModal = (closePrevious) => {
    if (this.queue.length > 0 && (closePrevious || this.props.modal.presented == null)) {
      try {
        const nextModal = this.queue.shift()
        const data = nextModal[1]
        const modal = nextModal[0]
        if (modal && data) {
          this.showModal(modal, data)
        }
      } catch (e) {
        console.error("failed render modal", e)
      }
    } else if (closePrevious) {
      this.showModal(null, null)
    }
  }
  
  showModal = (modal, data) => {
    const modalCard = modal && K.MODALS.includes(modal) ? modal : null
    const dataCard = modalCard ? data : null
    this.props.handleShowModal(modalCard, dataCard)
  }
  
  closeModal = () => {
    this.showNextModal(true)
  }
  
  render() {
    const { presented, data } = this.props.modal
    return (
      <div>
        {this.props.children}
        
        {presented === K.MODAL_CHANNEL_PUBLIC_ADDED &&
          <AlertNewChannel show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_CHANNEL_SHARE_LINK &&
          <ShareLinkChannelModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_ALERT_SHARE_LINK &&
          <AlertShareLinkModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_FILE_PROGRESS &&
          <ModalProgressBar show={data?.show === true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_QUOTA_NO_PAYMENT_METHOD &&
          <NoCardOnFile show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_DISCLAMER &&
          <DisclamerModal show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_PREVIEW_XLSX_IN_MESSAGE &&
          <XLSX show={true} data={data} onHide={this.closeModal.bind(this)} />}
        {presented === K.MODAL_PREVIEW_DOC_IN_MESSAGE &&
          <DOC show={true} data={data} onHide={this.closeModal.bind(this)} />}
      </div>
    )
  }
  
}

const mapStateToProps = store => {
  return {
    modal: store.modal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleShowModal: (modal, data) => dispatch(handleShowModal(modal, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)

