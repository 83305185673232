import { userRoleRead, userRoleUpdate }  from '../../../services/api/userrole'

export const ADMIN_USER_ROLE_LIST_SUCCESS = "ADMIN_USER_ROLE_LIST_SUCCESS"
export const ADMIN_USER_ROLE_LIST_FAIL = "ADMIN_USER_ROLE_LIST_FAIL"

export const ADMIN_USER_ROLE_UPDATE_SUCCESS = 'ADMIN_USER_ROLE_UPDATE_SUCCESS'
export const ADMIN_USER_ROLE_UPDATE_FAIL = 'ADMIN_USER_ROLE_UPDATE_FAIL'

export function handleAdminUserRoleRead() {
  return dispatch => {
    userRoleRead()
    .then(data => {
      dispatch({  
        type: ADMIN_USER_ROLE_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_USER_ROLE_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminUserRoleUpdate(userRoleId, role) {
  return dispatch => {
    userRoleUpdate(userRoleId, role)
    .then(data => {
      dispatch({  
        type: ADMIN_USER_ROLE_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_USER_ROLE_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}