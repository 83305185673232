import { sessionCreate, sessionsList } from "../../services/api/session"

export const SESSION_READ_REQUEST = 'SESSION_READ_REQUEST'
export const SESSION_READ_SUCCESS = 'SESSION_READ_SUCCESS'
export const SESSION_READ_FAIL = 'SESSION_READ_FAIL'

export const SESSION_UPDATE_REQUEST = 'SESSION_UPDATE_REQUEST'
export const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS'
export const SESSION_UPDATE_FAIL = 'SESSION_UPDATE_FAIL'

export function handleSessionCreate(owner, user) {
  return dispatch => {
    // dispatch({
    //   type: SESSION_UPDATE_REQUEST,
    //   payload: { owner: owner, user: user},
    // })
    sessionCreate(owner, user)
    // .then(session => {
    //   dispatch({
    //     type: SESSION_UPDATE_SUCCESS,
    //     payload: session,
    //   })
    // })
    // .catch(err => {
    //   dispatch({
    //     type: SESSION_UPDATE_FAIL,
    //     error: true,
    //     payload: new Error(err),
    //   })
    // })
  }
}

export function handleSessionList() {
  return dispatch => {
    sessionsList()
    .then(data => {
      dispatch({
        type: SESSION_READ_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: SESSION_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}