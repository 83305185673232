import {
  roleProductCreate,
  getRoleProductRead,
  roleProductUpdate,
  roleProductDelete,
  roleProductRead
} from "../../../services/api/roleproduct"

export const ADMIN_ROLE_PRODUCT_CREATE_SUCCESS = "ADMIN_ROLE_PRODUCT_CREATE_SUCCESS"
export const ADMIN_ROLE_PRODUCT_CREATE_FAIL = "ADMIN_ROLE_PRODUCT_CREATE_FAIL"

export const ADMIN_ROLE_PRODUCT_LIST_SUCCESS = "ADMIN_ROLE_PRODUCT_LIST_SUCCESS"
export const ADMIN_ROLE_PRODUCT_LIST_FAIL = "ADMIN_ROLE_PRODUCT_LIST_FAIL"

export const ADMIN_ROLE_PRODUCT_UPDATE_SUCCESS = 'ADMIN_ROLE_PRODUCT_UPDATE_SUCCESS'
export const ADMIN_ROLE_PRODUCT_UPDATE_FAIL = 'ADMIN_ROLE_PRODUCT_UPDATE_FAIL'

export const ADMIN_ROLE_PRODUCT_DELETE_SUCCESS = 'ADMIN_ROLE_PRODUCT_DELETE_SUCCESS'
export const ADMIN_ROLE_PRODUCT_DELETE_FAIL = 'ADMIN_ROLE_PRODUCT_DELETE_FAIL'

export function handleAdminRoleProductCreate(role, product) {
  return dispatch => {
    roleProductCreate(role, product)
    .then(data => {
      dispatch({
        type: ADMIN_ROLE_PRODUCT_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_PRODUCT_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRoleProductList() {
  return dispatch => {
    roleProductRead()
      .then(data => {
        dispatch({
          type: ADMIN_ROLE_PRODUCT_LIST_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: ADMIN_ROLE_PRODUCT_LIST_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleAdminRoleProductRead(roleId) {
  return dispatch => {
    getRoleProductRead(roleId)
    .then(data => {
      dispatch({
        type: ADMIN_ROLE_PRODUCT_LIST_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_PRODUCT_LIST_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRoleProductUpdate(roleProductId, product) {
  return dispatch => {
    roleProductUpdate(roleProductId, product)
    .then(data => {
      dispatch({  
        type: ADMIN_ROLE_PRODUCT_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_PRODUCT_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleAdminRoleProductDelete(roleProductId) {
  return dispatch => {
    roleProductDelete(roleProductId)
    .then(data => {          
      dispatch({  
        type: ADMIN_ROLE_PRODUCT_DELETE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: ADMIN_ROLE_PRODUCT_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}