import { Hub, API, graphqlOperation } from 'aws-amplify';
import { listUserProducts, getUserProduct } from "../../graphql/queries";
import { createUserProduct, deleteUserProduct, updateUserProduct } from "../../graphql/mutations";
import { onCreateUserProduct, onDeleteUserProduct, onUpdateUserProduct } from "../../graphql/subscriptions";

import { loadList } from "./shared"
const action = listUserProducts
const attribute = "listUserProducts"

export const HUB_OBSERVER_USER_PRODUCT = "userProduct"

let subscriptions = []

export const userProductCreate = async (user, product, trial) => {
  try {
    const filter = { userId: { eq: user.id }, productId: { eq: product.id } }
    const userProducts = await loadList(action, attribute, filter, 1)
    if (userProducts?.length > 0) {
      console.error('UserProduct already exist!')
      return
    }
  } catch (err) { console.error('error fetching userProducts') }
  if (user == null || product == null) { 
    console.error('UserProduct API: Required fields cannot be empty!')
    return
  }
  try {
    const response = await API.graphql(graphqlOperation(createUserProduct, {input: {
      trial: trial,
      userId: user.id,
      productId: product.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString() }}))
      return response?.data?.createUserProduct
  } catch (err) {
    console.error('error creating userProduct:', err)
    return
  }  
}

export const getUserProductRead = async (userId) => {
  try {
    if (userId == null) { 
      console.error('UserProduct API: Required fields cannot be empty!')
      return null
    }
    const filter = {
      userId: { eq: userId }
    }
    const userProducts = await loadList(action, attribute, filter, 0)
    return userProducts
  } catch (err) { 
    console.error('error fetching userProducts')
    return null
  }
}

export const userProductRead = async () => {
  try {
    const userProducts = await loadList(action, attribute, null, 0)
    return userProducts
  } catch (err) { 
    console.error('error fetching userProducts')
    return null
  }
}

export const userProductUpdate = async (userProductId, product) => {
  var original = null
  try {
    const exist = await API.graphql(graphqlOperation(getUserProduct, 
      { id: userProductId }
    ))
    original = exist?.data?.getUserProduct
  } catch (err) { console.error('error fetching userProduct:', err) }
  if (original?.id === userProductId) {
    if ( product == null) { 
      console.error('User Product API: Required fields cannot be empty!')
      return
    }
    try {
      const response = await API.graphql(graphqlOperation(updateUserProduct, {input: {
        id: userProductId, 
        productId: product.id,
        updatedAt: new Date().toISOString() }}))
        return response?.data?.updateUserProduct
    } catch (err) {
      console.error('error updating userProduct:', err)
      return
    }
  } else {
    return null
  }
}

export const userProductDelete = async (userProductId) => {
  try {
    await API.graphql(graphqlOperation(deleteUserProduct, { input: { id: userProductId }}));
    return userProductId
  } catch (err) { 
    console.error('error delete userProduct:', err)
    return userProductId
  }       
}

export const userProductObserve = async (userId) => {
  if ( userId == null || subscriptions.some(sub => sub.userId === userId) ) { return }
  const createEvent = 'INSERT'
  const createSubscription = API.graphql(graphqlOperation(onCreateUserProduct, { userId: userId } )).subscribe({
    next: response => {
      const userProduct = response.value.data.onCreateUserProduct
      Hub.dispatch(HUB_OBSERVER_USER_PRODUCT, {event: createEvent, data: userProduct, message:'create userProduct observe' });  
    },
  })   
  subscriptions.push({userId, createSubscription, createEvent})
  const updateEvent = 'UPDATE'
  const updateSubscription = API.graphql(graphqlOperation(onUpdateUserProduct, { userId: userId } )).subscribe({
    next: response => {
      const userProduct = response.value.data.onUpdateUserProduct
      Hub.dispatch(HUB_OBSERVER_USER_PRODUCT, {event: updateEvent, data: userProduct, message:'update userProduct observe' });  
    },
  })   
  subscriptions.push({userId, updateSubscription, updateEvent})
  const deleteEvent = 'DELETE'
  const deleteSubscription = API.graphql(graphqlOperation(onDeleteUserProduct, { userId: userId } )).subscribe({
    next: response => {
      const userProduct = response.value.data.onDeleteUserProduct
      Hub.dispatch(HUB_OBSERVER_USER_PRODUCT, {event: deleteEvent, data: userProduct, message:'delete userProduct observe' });
    },
  })
  subscriptions.push({userId, deleteSubscription, deleteEvent})
}

export async function deleteUserProductObservers(userId) {
  if (userId != null) {
    for (let sub of subscriptions) {
      if (sub.userId === userId && sub.subscription != null) {
          sub.subscription.unsubscribe()
      }
    }
  }          
}