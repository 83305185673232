import {
  messageCreate,
  messageRead,
  messageUpdate,
  messageDelete,
  messageObserve,
  messageDeleteAllObservers
} from "../../services/api/message"

export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST'
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS'
export const MESSAGE_CREATE_FAIL = 'MESSAGE_CREATE_FAIL'

export const MESSAGE_READ_REQUEST = 'MESSAGE_READ_REQUEST'
export const MESSAGE_READ_SUCCESS = 'MESSAGE_READ_SUCCESS'
export const MESSAGE_READ_FAIL = 'MESSAGE_READ_FAIL'

export const MESSAGE_UPDATE_REQUEST = 'MESSAGE_UPDATE_REQUEST'
export const MESSAGE_UPDATE_SUCCESS = 'MESSAGE_UPDATE_SUCCESS'
export const MESSAGE_UPDATE_FAIL = 'MESSAGE_UPDATE_FAIL'

export const MESSAGE_DELETE_REQUEST = 'MESSAGE_DELETE_REQUEST'
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS'
export const MESSAGE_DELETE_FAIL = 'MESSAGE_DELETE_FAIL'

export function handleMessageCreate(owner, user, channel, content, guid) {
  return dispatch => {
    dispatch({
      type: MESSAGE_CREATE_REQUEST,
      payload: { owner: owner, user: user, channel: channel, content: content, guid: guid },
    })
    messageCreate(owner, user, channel, content, guid)
      .then(data => {
        dispatch({
          type: MESSAGE_CREATE_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: MESSAGE_CREATE_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleMessageRead(rule, limit, lastCreatedAt) {
  return dispatch => {
    dispatch({
      type: MESSAGE_READ_REQUEST,
      payload: rule,
    })
    messageRead(rule, limit, lastCreatedAt, (data, err) => {
      if (err != null) {
        dispatch({
          type: MESSAGE_READ_FAIL,
          error: true,
          payload: new Error(err),
        })
      } else {
        dispatch({
          type: MESSAGE_READ_SUCCESS,
          payload: data,
        })
      }
    })
  }
}

export function handleMessageUpdate(owner, messageId, content, isDeleted) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: MESSAGE_UPDATE_REQUEST,
        payload: { owner: owner, messageId: messageId, content: content },
      })
      messageUpdate(owner, messageId, content, isDeleted)
        .then(data => {
          dispatch({
            type: MESSAGE_UPDATE_SUCCESS,
            payload: data,
          })
          resolve(data)
        })
        .catch(err => {
          dispatch({
            type: MESSAGE_UPDATE_FAIL,
            error: true,
            payload: new Error(err),
          })
          reject(err)
        })
    })
  }
}

export function handleMessageDelete(owner, messageId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: MESSAGE_DELETE_REQUEST,
        payload: { owner: owner, messageId: messageId },
      })
      messageDelete(owner, messageId)
        .then(data => {
          dispatch({
            type: MESSAGE_DELETE_SUCCESS,
            payload: data,
          })
          resolve(data)
        })
        .catch(err => {
          dispatch({
            type: MESSAGE_DELETE_FAIL,
            error: true,
            payload: new Error(err),
          })
          reject(err)
        })
    })
  }
}

export function observeMessage(channelId, rules) {
  return dispatch => {
    messageObserve(channelId, rules)
  }
}

export function deleteAllMessageObservers() {
  return dispatch => {
    messageDeleteAllObservers()
  }
}

export function handleMessageObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT MSG':
        dispatch({
          type: MESSAGE_CREATE_SUCCESS,
          payload: data.element,
        })
        break;
      case 'UPDATE MSG':
        dispatch({
          type: MESSAGE_UPDATE_SUCCESS,
          payload: data.element,
        })
        break;
      case 'DELETE MSG':
        dispatch({
          type: MESSAGE_DELETE_SUCCESS,
          payload: data.element,
        })
        break;
      default:
        break;
    }
  }
}

export function handleMessageObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT MSG':
        dispatch({
          type: MESSAGE_CREATE_SUCCESS,
          payload: data,
        })
        break;
      case 'UPDATE MSG':
        dispatch({
          type: MESSAGE_UPDATE_SUCCESS,
          payload: data,
        })
        break;
      case 'DELETE MSG':
        dispatch({
          type: MESSAGE_DELETE_SUCCESS,
          payload: data,
        })
        break;
      default:
        break;
    }
  }
}