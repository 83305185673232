import {
  userUpdate, userDelete, userObserve,
  searchUserRead, searchUsersRead, HUB_OBSERVER_SEARCH_USER, userRead
} from "../../services/api/user"
import { Hub } from "aws-amplify"
import { restapiUserCreate } from "../../services/rest/apiAWS"
import { myUserRoleRead }  from '../../services/api/userrole'
import { fillDefaultRoles }  from '../../services/api/role'
import { fillDefaultProducts } from "../../services/api/product"
import { fillDefaultPermissions } from '../../services/api/permission'
import { fillDefaultRoleProduct }  from '../../services/api/roleproduct'
import { fillConfigs }  from '../../services/api/config'
import { authSignOut, authUpdateUserAttributes } from '../../services/auth'
import { USER_ROLE_READ_SUCCESS } from './UserRoleActions'
import { toastError, toastSuccess } from "../../shared/platform/Toast"
import { triggerEmailSignUp } from "../../utils/TriggerHelpers"

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'

export const USER_READ_REQUEST = 'USER_READ_REQUEST'
export const USER_READ_SUCCESS = 'USER_READ_SUCCESS'
export const USER_READ_FAIL = 'USER_READ_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'

export function setupPlatform() {
  fillConfigs()
  .then(configs => {
    fillDefaultPermissions()
    .then(perms => {
      fillDefaultRoles(perms)
        .then(roles => {
          fillDefaultProducts(perms)
            .then(products => {
              fillDefaultRoleProduct(roles, products)
                .then(roleProducts => {})
                .catch(err => {})
            })
            .catch(err => {})                                      
        })
        .catch(err => {})
    })
    .catch(err => {})
  }).catch(err => {})
}

export function handleUserCreate(owner, attributes) {
  return dispatch => {
    dispatch({
      type: USER_CREATE_REQUEST,
      payload: { owner: owner, username: attributes?.email},
    })
    userRead(owner)
      .then(user => {
        if (user?.id == null) {
          restapiUserCreate(owner, attributes?.email)
            .then(data => {
              dispatch(handleUserRead(owner, attributes))
              toastSuccess('toast_text_1')
              triggerEmailSignUp(owner, attributes);
            })
            .catch(err => {
              dispatch({
                type: USER_CREATE_FAIL,
                error: true,
                payload: new Error(err),
              })
              toastError('toast_text_2')
            })
        } else {
          dispatch(handleUserRead(owner, attributes))
        }
      }).catch(e => {
        console.error("Error find user", e)
      })
  }
}

export function handleSearchUserRead(filter) {
  // filters = {
  //   value: "name or username or phone", // equal
  // }
  return dispatch => {
    searchUserRead(filter)
      .then(data => {
        Hub.dispatch(HUB_OBSERVER_SEARCH_USER, { event: HUB_OBSERVER_SEARCH_USER, data: data, message: HUB_OBSERVER_SEARCH_USER })
      })
  }
}

export function handleSearchUsersRead(userIds) {
  return dispatch => {
    searchUsersRead(userIds)
      .then(data => {
        if (data?.length > 0) {
          Hub.dispatch(HUB_OBSERVER_SEARCH_USER, { event: HUB_OBSERVER_SEARCH_USER, data: data, message: HUB_OBSERVER_SEARCH_USER })
        }
      })
  }
}

export function handleUserRead(owner, attributes) {
  return dispatch => {
    dispatch({
      type: USER_READ_REQUEST,
      payload: owner,
    })
    userRead(owner)
    .then(data => {
      const userId = data?.id
      if (userId != null) {
        myUserRoleRead(userId)
        .then(userRoles => {
          dispatch({
            type: USER_ROLE_READ_SUCCESS,
            payload: userRoles
          })
        })
        .catch(err => {})
        dispatch({
          type: USER_READ_SUCCESS,
          payload: {owner: owner, user: data, attributes: attributes},
        })
        if (attributes["custom:setup_user_id"] == null) {
          authUpdateUserAttributes({"custom:setup_user_id": userId})
            .then(authData => {
              //
            } )
            .catch(err => {
              console.error("Failed update user attributes", err)
            })
        }
      }      
    })
    .catch(err => {
      dispatch({
        type: USER_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserUpdate(owner, userId, username, name, phone, country, info, publicByName, publicByUsername, publicByEmail) {
  return dispatch => {
    dispatch({
      type: USER_UPDATE_REQUEST,
      payload: { owner: owner, userId: userId, username: username, name: name, phone: phone, country: country,
        publicByName: publicByName, publicByUsername: publicByUsername, publicByEmail: publicByEmail},
    })
    userUpdate(owner, userId, username, name, phone, country, info, publicByName, publicByUsername, publicByEmail)
    .then(data => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: {owner: owner, user: data},        
      })
    })
    .catch(err => {
      dispatch({
        type: USER_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserDelete(owner, userId) {
  return dispatch => {
    dispatch({
      type: USER_DELETE_REQUEST,
      payload: { owner: owner, userId: userId},
    })
    userDelete(owner, userId)
    .then(() => {
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: true,
      })
    })
    .catch(err => {
      dispatch({
        type: USER_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleUserSignOut() {
  return dispatch => {
    authSignOut().then(() => {
      setTimeout(() => {
        dispatch({
          type: USER_SIGNOUT_SUCCESS,
          payload: {}        
        })
      }, 2000);
    })    
  }
}

export function observeUser(owner, userId) {
  return dispatch => {
    userObserve(owner, userId)
  }
}

export function handleUserObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: USER_CREATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: data.element,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: USER_DELETE_SUCCESS,
          payload: data.element,
        })   
        break;      
      default:
        break;
    }    
  }
}

export function handleUserObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        dispatch({
          type: USER_CREATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'UPDATE':
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: data,
        })   
        break;
      case 'DELETE':
        dispatch({
          type: USER_DELETE_SUCCESS,
          payload: data,
        })   
        break;      
      default:
        break;
    }    
  }
}