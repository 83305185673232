import {
  WIDGET_CREATE_REQUEST,
  WIDGET_CREATE_SUCCESS,
  WIDGET_CREATE_FAIL,
  WIDGET_READ_SUCCESS,
  WIDGET_UPDATE_SUCCESS,
  WIDGET_DELETE_SUCCESS, WIDGET_GET_SUCCESS
} from "../actions/WidgetActions"

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function widgetReducer(state = initialState, action) {
  switch (action.type) {
    case WIDGET_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case WIDGET_CREATE_SUCCESS:
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case WIDGET_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case WIDGET_READ_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload?.filter(newItem => !state.list.some(oldItem => oldItem.id === newItem.id)) )] }

    case WIDGET_GET_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat([action.payload])]}

    case WIDGET_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          top: action.payload?.top, 
          left: action.payload?.left,
          width: action.payload?.width,
          height: action.payload?.height,
          minWidth: action.payload?.minWidth,
          minHeight: action.payload?.minHeight,
          visible: action.payload?.visible,
          workspaceId: action.payload?.workspaceId,
          widgetWorkspaceId: action.payload?.widgetWorkspaceId,
        } : item)
      }
    case WIDGET_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload)
      }
    default:
      return state
  }
}