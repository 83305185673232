import {
  SETTINGS_CREATE_REQUEST,
  SETTINGS_CREATE_SUCCESS,
  SETTINGS_CREATE_FAIL,
  SETTINGS_READ_SUCCESS,
  SETTINGS_UPDATE_SUCCESS, 
  SETTINGS_DELETE_SUCCESS
} from '../actions/SettingsActions'

const initialState = {
  list: [],
  error: '',
  isFetching: true,
}

export function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case SETTINGS_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case SETTINGS_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.settings }

    case SETTINGS_READ_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload?.filter(newItem => !state.list.some(oldItem => oldItem.id === newItem.id) ) )] }

    case SETTINGS_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          title: action.payload?.title, 
          detail: action.payload?.detail,
          content: action.payload?.content
        } : item)
      }
    case SETTINGS_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}