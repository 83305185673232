import React, { Component } from 'react'

import PermissionContainer from './PermissionContainer'
import ModalContainer from "./ModalContainer"

import Router from '../shared/Router'
import PlatformContainer from "../shared/platform/PlatformContainer"

import UserContainer from '../containers/user/UserContainer'
import BillingContainer from '../containers/billing/BillingContainer'

class RouterContainer extends Component {

  render() {
    return (
      <PlatformContainer authData={this.props.authData}>
        <UserContainer authData={this.props.authData}>
          <PermissionContainer>
            <BillingContainer>
              <ModalContainer>
                <Router/>
              </ModalContainer>
            </BillingContainer>
          </PermissionContainer>
        </UserContainer>
      </PlatformContainer>     
    )
  }
}

export default RouterContainer