import {
  TOPICS_CREATE_REQUEST,
  TOPICS_CREATE_SUCCESS,
  TOPICS_CREATE_FAIL,
  TOPICS_READ_SUCCESS,
  TOPICS_UPDATE_SUCCESS, 
  TOPICS_DELETE_SUCCESS
} from '../actions/TopicsActions'

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function topicsReducer(state = initialState, action) {
  switch (action.type) {
    case TOPICS_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case TOPICS_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state, list: [...state.list, action.payload]
      }

    case TOPICS_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.topics }

    case TOPICS_READ_SUCCESS:
      return { ...state, isFetching: false,
        list: [...state.list.concat(action.payload?.filter(newItem => !state.list.some(oldItem => oldItem.id === newItem.id) ) )]
      }

    case TOPICS_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item,
          channelId: action.payload?.channelId,
          categories: action.payload?.categories,
          labels: action.payload?.labels,
        } : item)
      }
    case TOPICS_DELETE_SUCCESS:
        return {
          ...state, list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}