import { extraCreate, extraRead, extraUpdate, extraDelete }  from '../../services/api/extra'
import { productCreate } from '../../services/api/product'
import { slugify } from '../../utils/Helpres'
import { PRODUCT_PREFIX_SINGLE_EXTRA } from "../../utils/Constant"
import { toastSuccess, toastError } from "../../shared/platform/Toast"

export const EXTRAS_CREATE_REQUEST = 'EXTRAS_CREATE_REQUEST'
export const EXTRAS_CREATE_SUCCESS = 'EXTRAS_CREATE_SUCCESS'
export const EXTRAS_CREATE_FAIL = 'EXTRAS_CREATE_FAIL'

export const EXTRAS_READ_REQUEST = 'EXTRAS_READ_REQUEST'
export const EXTRAS_READ_SUCCESS = 'EXTRAS_READ_SUCCESS'
export const EXTRAS_READ_FAIL = 'EXTRAS_READ_FAIL'

export const EXTRAS_UPDATE_REQUEST = 'EXTRAS_UPDATE_REQUEST'
export const EXTRAS_UPDATE_SUCCESS = 'EXTRAS_UPDATE_SUCCESS'
export const EXTRAS_UPDATE_FAIL = 'EXTRAS_UPDATE_FAIL'

export const EXTRAS_DELETE_REQUEST = 'EXTRAS_DELETE_REQUEST'
export const EXTRAS_DELETE_SUCCESS = 'EXTRAS_DELETE_SUCCESS'
export const EXTRAS_DELETE_FAIL = 'EXTRAS_DELETE_FAIL'

export function handleExtraCreate(authorName, name, brief, description, info, categories, products, isPublic, rating, source, media, currency) {
  return dispatch => {
    dispatch({
      type: EXTRAS_CREATE_REQUEST,
      payload: { authorName: authorName, name: name, brief: brief, description: description, info: info, categories: categories, products: products, isPublic: isPublic, rating: rating, source: source, media: media },
    })
    extraCreate(authorName, name, brief, description, info, categories, products, isPublic, rating, source, media)
    .then(extra => {
      const productItem = { itemId: extra?.id, authorId: authorName, authorName: authorName, authorShare: "100" }
      productCreate(`${PRODUCT_PREFIX_SINGLE_EXTRA}-${slugify(extra?.id)}`, name, description, false, productItem, null, null, 0, currency).
      then(createdProduct => {
        const products = createdProduct == null ? 
          extra?.products : 
          extra?.products.filter(el => el.itemId !== createdProduct?.id ).concat({ 
            itemId: createdProduct?.id, 
            isPublic: createdProduct?.public, 
            isPaid: createdProduct?.public && createdProduct?.price > 0 
          })        
        extraUpdate(extra?.id, extra?.name, extra?.brief, extra?.description, extra?.info, extra?.categories, products, extra?.public, extra?.rating, extra?.media, extra?.source )
        .then(data => {      
          dispatch({  
            type: EXTRAS_UPDATE_SUCCESS,
            payload: data,        
          })
        })
        .catch(err => {
          dispatch({
            type: EXTRAS_UPDATE_FAIL,
            error: true,
            payload: new Error(err),
          })
        })
      }).
      catch(err => {
        console.error('error create single product', err)
      })
      dispatch({
        type: EXTRAS_CREATE_SUCCESS,
        payload: extra,        
      })
      toastSuccess('toast_text_15')
    })
    .catch(err => {
      dispatch({
        type: EXTRAS_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
      toastError('toast_text_16')
    })
  }
}

export function handleExtrasRead() {
  return dispatch => {
    dispatch({
      type: EXTRAS_READ_REQUEST,
      payload: null,
    })
    extraRead()
    .then(data => {
      dispatch({
        type: EXTRAS_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: EXTRAS_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleExtraUpdate(extraId, name, brief, description, info, categories, products, isPublic, rating, media, source) {
  return dispatch => {
    dispatch({
      type: EXTRAS_UPDATE_REQUEST,
      payload: { name: name, brief: brief, description: description, info: info, categories: categories, products: products, isPublic: isPublic, rating: rating, media: media, source: source },
    })
    extraUpdate(extraId, name, brief, description, info, categories, products, isPublic, rating, media, source)
    .then(data => {
      dispatch({
        type: EXTRAS_UPDATE_SUCCESS,
        payload: data,        
      })
      toastSuccess('toast_text_17')
    })
    .catch(err => {
      dispatch({
        type: EXTRAS_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
      toastError('toast_text_18')
    })
  }
}

export function handleExtraDelete(extraId) {
  return dispatch => {
    dispatch({
      type: EXTRAS_DELETE_REQUEST,
      payload: { extraId: extraId },
    })
    extraDelete(extraId)
    .then(data => {
      dispatch({
        type: EXTRAS_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: EXTRAS_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}