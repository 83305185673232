import React, { Component } from 'react'
import { connect } from "react-redux"
import { handleConfigsRead } from "../../store/actions/ConfigsActions"
import { handleSettingsRead } from '../../store/actions/SettingsActions'
import Loader from "../Loader"
import PrivacyModal from "../../components/modal/privacy/PrivacyModal"
import * as K from "../../utils/Constant"
import { handlePaymentGeCurrency } from "../../store/actions/BillingActions"

class PlatformContainer extends Component {
  constructor(props) {
    super(props)
    this.state={
      settingsRead: false,
      currencyRead: false
    }
  }

  componentDidMount() {
    this.props.handleConfigsRead()
  }

  componentDidUpdate() {
    if (this.props.account.owner && !this.state.settingsRead) {
      this.onSettingsRead()
    } else if (this.state.settingsRead && !this.state.currencyRead) {
      this.onCurrencyRead()
    }
  }

  onSettingsRead() {
    if (this.state.settingsRead === true) return

    this.props.handleSettingsRead(this.props.account?.owner)
    this.setState({settingsRead: true})
  }

  onCurrencyRead() {
    if (this.state.currencyRead === true) return

    this.props.handlePaymentGeCurrency()
    this.setState({currencyRead: true})
  }

  render() {
    const platformReady = this.props.configs.list.length > 0
    const accountReady = this.props.authData?.attributes["custom:setup_initial"] === "1"
    const configDisclaimer = this.props.configs?.list.find(el => el?.detail === K.DEFAULT_PRIVACY_DISCLAIMER_CONFIG_KEY)
    const configPP = this.props.configs?.list.find(el => el?.detail === K.DEFAULT_PRIVACY_PP_CONFIG_KEY)
    const configToS = this.props.configs?.list.find(el => el?.detail === K.DEFAULT_PRIVACY_TOS_CONFIG_KEY)
    var showDisclaimer = false
    if (configDisclaimer != null) {
      try {
        showDisclaimer = JSON.parse(configDisclaimer?.content)?.checked === true
      } catch (e) {}
    }
    if (configPP != null && !showDisclaimer) {
      try {
        showDisclaimer = JSON.parse(configPP?.content)?.checked === true
      } catch (e) {}
    }
    if (configToS != null && !showDisclaimer) {
      try {
        showDisclaimer = JSON.parse(configToS?.content)?.checked === true
      } catch (e) {}
    }

    return (
      <div>{
        !platformReady ?
        <Loader/> :
        !accountReady && showDisclaimer ? <PrivacyModal show={true} configs={this.props.configs.list}/> : this.props.children
      }</div>
    )
  }
}

const mapStateToProps = store => {
  return {
    configs: store.configs,
    account: store.user,
    settings: store.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleConfigsRead  : () => dispatch(handleConfigsRead()),
    handleSettingsRead : owner => dispatch(handleSettingsRead(owner)),
    handlePaymentGeCurrency: () => dispatch(handlePaymentGeCurrency()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlatformContainer)
