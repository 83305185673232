import React, { Component } from "react"

import * as K from "../../utils/Constant"

import { storageRead } from "../../services/storage/storage"

import './eula-container.scss'

// for update
class EulaContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eula: ""
    }        
  }

  componentDidMount() {
    this.fetchEula()
  }

  fetchEula() {
    const key = K.DEFAULT_PRIVACY_EULA_PAGE_CONFIG_KEY
    storageRead(key)
      .then(data => {
        var xmlHttp = new XMLHttpRequest()
        xmlHttp.open("GET", data, false)
        xmlHttp.send(null)
        this.setState({ eula: JSON.parse(xmlHttp.responseText) })
      })
      .catch(err => console.error("READ EULA POLICY ERROR ", err))
  }

  render() {
    const { eula } = this.state
    return (
      <section className="eula">
        <div className="eula-container">
          <span dangerouslySetInnerHTML={{ 
            __html: eula
            }} 
          />
        </div>
      </section>
    )
  }
}

export default EulaContainer
