import { getI18n } from "react-i18next"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export const toastDefauilt = (key) => { toast.success(getI18n().t(key)) }
export const toastInfo = (key) => {
  toast.info(getI18n().t(key), {
    position: "top-right",
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}
export const toastSuccess = (key) => {
  toast.info(getI18n().t(key), {
    position: "top-right",
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      backgroundColor: "red"
    }
  });
  // toast.success(getI18n().t(key), {
  //   style: {
  //     backgroundColor: '#34aadc'
  //   }
  // });
}
export const toastWarning = (key) => { toast.warn(getI18n().t(key)) }
export const toastError = (key) => { toast.error(getI18n().t(key)) }