import {
  LABEL_CREATE_REQUEST,
  LABEL_CREATE_SUCCESS,
  LABEL_CREATE_FAIL,
  LABEL_READ_SUCCESS,
  LABEL_UPDATE_SUCCESS, 
  LABEL_DELETE_SUCCESS
} from '../actions/LabelActions'

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function labelReducer(state = initialState, action) {
  switch (action.type) {
    case LABEL_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case LABEL_CREATE_SUCCESS:
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case LABEL_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case LABEL_READ_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload?.filter(newItem => !state.list.some(oldItem => oldItem.id === newItem.id)))] }

    case LABEL_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          name: action.payload?.name
        } : item)
      }
    case LABEL_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }
    default:
      return state
  }
}