import { topicCreate, topicRead, topicUpdate, topicDelete }  from '../../services/api/topics'
import { apiAWS, restapiAWSRequest } from "../../services/rest/apiAWS"

export const TOPICS_CREATE_REQUEST = 'TOPICS_CREATE_REQUEST'
export const TOPICS_CREATE_SUCCESS = 'TOPICS_CREATE_SUCCESS'
export const TOPICS_CREATE_FAIL = 'TOPICS_CREATE_FAIL'

export const TOPICS_READ_REQUEST = 'TOPICS_READ_REQUEST'
export const TOPICS_READ_SUCCESS = 'TOPICS_READ_SUCCESS'
export const TOPICS_READ_FAIL = 'TOPICS_READ_FAIL'

export const TOPICS_UPDATE_REQUEST = 'TOPICS_UPDATE_REQUEST'
export const TOPICS_UPDATE_SUCCESS = 'TOPICS_UPDATE_SUCCESS'
export const TOPICS_UPDATE_FAIL = 'TOPICS_UPDATE_FAIL'

export const TOPICS_DELETE_REQUEST = 'TOPICS_DELETE_REQUEST'
export const TOPICS_DELETE_SUCCESS = 'TOPICS_DELETE_SUCCESS'
export const TOPICS_DELETE_FAIL = 'TOPICS_DELETE_FAIL'

export function handleRestapiTopicsReload(owner) {
  return dispatch => {
    const params = {
      owner: owner,
      attributes: null
    };
    restapiAWSRequest(apiAWS.ReloadTopics, params)
      .then(data => {
        // console.log(data)
      })
      .catch(err => {
        console.error(err)
      })
  }
}
export function handleTopicsCreate(owner, user, parentChannelId, rule) {
  return dispatch => {
    dispatch({
      type: TOPICS_CREATE_REQUEST,
      payload: { owner: owner, user: user, rule: rule },
    })
    topicCreate(owner, user, parentChannelId, rule)
    .then(data => {
      dispatch({
        type: TOPICS_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: TOPICS_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleTopicsRead(owner) {
  return dispatch => {
    dispatch({
      type: TOPICS_READ_REQUEST,
      payload: owner,
    })
    topicRead(owner)
    .then(data => {
      dispatch({
        type: TOPICS_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: TOPICS_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleTopicsUpdate(owner, topicsId, rule) {
  return dispatch => {
    dispatch({
      type: TOPICS_UPDATE_REQUEST,
      payload: { owner: owner, topicsId: topicsId, rule: rule },
    })
    topicUpdate(owner, topicsId, rule)
    .then(data => {
      dispatch({
        type: TOPICS_UPDATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: TOPICS_UPDATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleTopicsDelete(owner, topicsId) {
  return dispatch => {
    dispatch({
      type: TOPICS_DELETE_REQUEST,
      payload: { owner: owner, topicsId: topicsId },
    })
    topicDelete(owner, topicsId)
    .then(data => {
      dispatch({
        type: TOPICS_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: TOPICS_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}