import {
  CHANNEL_CREATE_REQUEST,
  CHANNEL_CREATE_SUCCESS,
  CHANNEL_CREATE_FAIL,
  CHANNEL_READ_SUCCESS,
  CHANNEL_UPDATE_SUCCESS,
  CHANNEL_DELETE_SUCCESS, CHANNEL_GET_SUCCESS
} from "../actions/ChannelActions"

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function channelReducer(state = initialState, action) {
  switch (action.type) {
    case CHANNEL_CREATE_REQUEST:
      return { ...state, error: '' }

    case CHANNEL_CREATE_SUCCESS:
      if (action.payload?.id == null || state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case CHANNEL_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case CHANNEL_READ_SUCCESS:
      return { ...state, isFetching: true, list: [...state.list.filter(oldCh => !action.payload?.some(newCh => oldCh?.id === newCh?.id))?.concat(action.payload)] }

    case CHANNEL_GET_SUCCESS:
      return { ...state, isFetching: true, list: [...state.list.filter(oldCh => oldCh?.id !== action.payload?.id)?.concat([action.payload])] }

    case CHANNEL_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          name: action.payload?.name,
          brief: action.payload?.brief,
          description: action.payload?.description,
          info: action.payload?.info,
          public: action.payload?.public,
          filterRules: action.payload?.filterRules,
          status: action.payload?.status,
          products: action.payload?.products,
          categories: action.payload?.categories,
          shared: action.payload?.shared,
        } : item)
      }
    case CHANNEL_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }
    default:
      return state
  }
}