
export const CREATE_ADMIN_PANEL_TITLE = 'CREATE_ADMIN_PANEL_TITLE'

export function handleAdminPanelLogoCreate(title) {
  return dispatch => {
    dispatch({
      type: CREATE_ADMIN_PANEL_TITLE,
      payload: title
    })      
  }
}