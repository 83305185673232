import * as STRIPE from '../actions/BillingActions'

const initialState = {
  balance: null,
  customer: null,
  customerConnectError: false,
  payment: null,
  subscriptions: [],
  plans: [],
  products: [],
  account: null,
  accountConnectError: false,
  vendors: null,
  transfers: null,
  error: '',
  isFetching: false,
  currency: 'USD',
}

export function stripeReducer(state = initialState, action) {
  switch (action.type) {
    case STRIPE.STRIPE_CURRENCY_GET_SUCCESS:
      return {
        ...state, currency: action.payload
      }
    case STRIPE.STRIPE_BALANCE_GET_SUCCESS:
      return { 
        ...state, balance: action.payload 
      }
    case STRIPE.STRIPE_BALANCE_GET_FAIL:
      return { 
        ...state, balance: null
      }
    case STRIPE.STRIPE_CUSTOMER_CREATE_FAIL:
      return {
        ...state, customer: action.payload, customerConnectError: true
      }
    case STRIPE.STRIPE_CUSTOMER_CREATE_SUCCESS:
      return {
        ...state, customer: action.payload, customerConnectError: false
      }
    case STRIPE.STRIPE_CUSTOMER_READ_FAIL:
      return {
        ...state, customer: action.payload, customerConnectError: true
      }
    case STRIPE.STRIPE_CUSTOMER_READ_SUCCESS:
      return {
        ...state, customer: action.payload, customerConnectError: false
      }
    case STRIPE.STRIPE_PAYMENT_CREATE_FAIL:
      return {
        ...state, payment: action.payload, customerConnectError: true
      }
    case STRIPE.STRIPE_PAYMENT_CREATE_SUCCESS:
      return {
        ...state, payment: action.payload?.data, customerConnectError: false
      }
    case STRIPE.STRIPE_PRODUCT_LIST_SUCCESS:
      return { 
        ...state, products: action.payload 
      }
    case STRIPE.STRIPE_PRODUCT_CREATE_SUCCESS:
      if (state.products.some(item => item.id === action.payload?.id)) { return state }
      return { ...state, products: [...state.products, action.payload] }
    case STRIPE.STRIPE_PRICE_LIST_SUCCESS:
      return { 
        ...state, plans: action.payload 
      }
    case STRIPE.STRIPE_PRICE_CREATE_SUCCESS:
      if (state.plans.some(item => item.id === action.payload?.id)) { return state }
      return { ...state, plans: [...state.plans, action.payload] }
    case STRIPE.STRIPE_SUBSCRIPTION_LIST_SUCCESS:
      return { 
        ...state, subscriptions: action.payload 
      }
    case STRIPE.STRIPE_SUBSCRIPTION_CREATE_SUCCESS:
      if (state.subscriptions.some(item => item.id === action.payload?.id)) { return state }
      return { ...state, subscriptions: [...state.subscriptions, action.payload] }
    case STRIPE.STRIPE_SUBSCRIPTION_UPDATE_SUCCESS:
      return { ...state,
        subscriptions: state.subscriptions.map(item => item.id === action.payload?.id ? { ...item,
          status: action.payload?.status
        } : item)
      }
    case STRIPE.STRIPE_ACCOUNT_CREATE_SUCCESS:
      return { 
        ...state, account: action.payload, accountConnectError: false
      }
    case STRIPE.STRIPE_ACCOUNT_CREATE_FAIL:
      return {
        ...state, account: null, accountConnectError: true
      }
    case STRIPE.STRIPE_ACCOUNT_READ_SUCCESS:
      return {
        ...state, account: action.payload, accountConnectError: false
      }
    case STRIPE.STRIPE_ACCOUNT_READ_FAIL:
      return {
        ...state, account: null, accountConnectError: true
      }
    case STRIPE.STRIPE_ACCOUNT_LIST_SUCCESS:
      return { 
        ...state, vendors: action.payload?.filter(v => v?.charges_enabled && v?.payouts_enabled)
      }

    case STRIPE.STRIPE_ACCOUNT_TRANSFER_LIST_SUCCESS:
      return { 
        ...state, transfers: action.payload 
      }      
    default:
      return state
  }
}