import { tagCreate, tagRead, tagDelete }  from '../../services/api/tag'

export const TAG_CREATE_REQUEST = 'TAG_CREATE_REQUEST'
export const TAG_CREATE_SUCCESS = 'TAG_CREATE_SUCCESS'
export const TAG_CREATE_FAIL = 'TAG_CREATE_FAIL'

export const TAG_READ_REQUEST = 'TAG_READ_REQUEST'
export const TAG_READ_SUCCESS = 'TAG_READ_SUCCESS'
export const TAG_READ_FAIL = 'TAG_READ_FAIL'

export const TAG_DELETE_REQUEST = 'TAG_DELETE_REQUEST'
export const TAG_DELETE_SUCCESS = 'TAG_DELETE_SUCCESS'
export const TAG_DELETE_FAIL = 'TAG_DELETE_FAIL'

export function handleTagCreate(owner, user, label, message) {
  return dispatch => {
    dispatch({
      type: TAG_CREATE_REQUEST,
      payload: { owner: owner, user: user, label: label, message: message },
    })
    tagCreate(owner, user, label, message)
    .then(data => {
      dispatch({
        type: TAG_CREATE_SUCCESS,
        payload: data,        
      })
    })
    .catch(err => {
      dispatch({
        type: TAG_CREATE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleTagRead(owner) {
  return dispatch => {
    dispatch({
      type: TAG_READ_REQUEST,
      payload: owner,
    })
    tagRead(owner)
    .then(data => {
      dispatch({
        type: TAG_READ_SUCCESS,
        payload: data,        
      })      
    })
    .catch(err => {
      dispatch({
        type: TAG_READ_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}

export function handleTagDelete(owner, tagId) {
  return dispatch => {
    dispatch({
      type: TAG_DELETE_REQUEST,
      payload: { owner: owner, tagId: tagId },
    })
    tagDelete(owner, tagId)
    .then(data => {
      dispatch({
        type: TAG_DELETE_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: TAG_DELETE_FAIL,
        error: true,
        payload: new Error(err),
      })
    })
  }
}