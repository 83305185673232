import {
  MESSAGE_CREATE_REQUEST,
  MESSAGE_CREATE_SUCCESS,
  MESSAGE_CREATE_FAIL,
  MESSAGE_READ_SUCCESS,
  MESSAGE_UPDATE_SUCCESS, 
  MESSAGE_DELETE_SUCCESS
} from '../actions/MessageActions'

const initialState = {
  list: [],
  lastCreatedDates: {},
  error: '',
  isFetching: false,
}

export function messageReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_CREATE_REQUEST:
      return { ...state, error: '' }

    case MESSAGE_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { 
        ...state,
        list: [...state.list, action.payload] 
      }

    case MESSAGE_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.message }

    case MESSAGE_READ_SUCCESS:
      const list = [...state.list.concat(action.payload?.filter(newMsg => !state.list.some(oldMsg => oldMsg.id === newMsg.id) ) )]
      const lastCreatedDates = list.reduce((acc, curr) => {
        const { createdAt } = curr
        if ( !acc[curr.channelId]) {
          acc[curr.channelId] = createdAt
        } else {
          acc[curr.channelId] = 
          Date.parse(acc[curr.channelId]) > Date.parse(createdAt)
          ?  acc[curr.channelId]
          :  createdAt
        }
        return acc
      }, {})
      return { ...state, isFetching: true, list, lastCreatedDates }

    case MESSAGE_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          category: action.payload?.category,
          title: action.payload?.title, 
          content: action.payload?.content,
          deleted: action.payload?.deleted,
        } : item).filter(msg => msg?.deleted !== true)
      }
    case MESSAGE_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}