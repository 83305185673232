import React, { Component } from 'react'
import Loader from '../Loader/Loader'
import styles from './NotFound.module.scss'

class AccessDenied extends Component {
  constructor(props) {
    super(props)
    this.state={
      isLoading: true
    }
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({isLoading: false})
      }      
    }, 3000)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      this.state.isLoading ? <Loader/> :
      <div className={styles.Container}>
        <div className="text-center">
          <h1 className={styles.Status}>403</h1>
          <h2 className={styles.Message}>Access Denied</h2>
        </div>
      </div>
    )
  }

}

export default AccessDenied