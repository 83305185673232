import {
  EXTRAS_CREATE_REQUEST,
  EXTRAS_CREATE_SUCCESS,
  EXTRAS_CREATE_FAIL,
  EXTRAS_READ_SUCCESS,
  EXTRAS_UPDATE_SUCCESS, 
  EXTRAS_DELETE_SUCCESS
} from '../actions/ExtraActions'

const initialState = {
  list: [],
  error: '',
  isFetching: false,
}

export function extraReducer(state = initialState, action) {
  switch (action.type) {
    case EXTRAS_CREATE_REQUEST:
      return { ...state, isFetching: true, error: '' }

    case EXTRAS_CREATE_SUCCESS:
      if (state.list.some(item => item.id === action.payload?.id)) { return state }
      return { ...state, list: [...state.list, action.payload] }

    case EXTRAS_CREATE_FAIL:
      return { ...state, isFetching: false, error: action.payload?.extras }

    case EXTRAS_READ_SUCCESS:
      return { ...state, isFetching: false, list: [...state.list.concat(action.payload?.filter(newItem => !state.list.some(oldItem => oldItem.id === newItem.id) ) )] }

    case EXTRAS_UPDATE_SUCCESS:
      return { ...state, isFetching: false, 
        list: state.list.map(item => item.id === action.payload?.id ? { ...item, 
          name: action.payload?.name,
          brief: action.payload?.brief,
          description: action.payload?.description,
          info: action.payload?.info,
          categories: action.payload?.categories,
          products: action.payload?.products,
          public: action.payload?.public,
          rating: action.payload?.rating,
          media: action.payload?.media
        } : item)
      }
    case EXTRAS_DELETE_SUCCESS:
        return {
          ...state,
          list: state.list.filter(item => item.id !== action.payload)
        }

    default:
      return state
  }
}