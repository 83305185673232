import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleUserCreate, handleUserRead, handleUserUpdate, handleUserDelete } from '../../store/actions/UserActions'
import { handleSessionCreate } from "../../store/actions/SessionActions"
import Loader from '../../shared/Loader/Loader'
import { objectsEqual } from '../../utils/Helpres'

class UserContainer extends Component {
  constructor(props) {
    super(props)
    this.state={
      userCreated: false,
      sessionUpdated: false,
    }        
  }
  
  componentDidMount() {
    this.onReloadUser()
  }

  componentDidUpdate() {
    this.onReloadUser()
  }

  onReloadUser() {
    if (this.props.authData?.username != null) {
      if (!this.state.userCreated && this.props.account?.user == null && this.props.authData?.attributes["custom:setup_user_id"] == null) {
        this.onUserCreate()
        this.setState({userCreated: true})
      } else if (this.props.authData?.username !== this.props.account?.owner || !objectsEqual(this.props.authData?.attributes, this.props.account?.attributes)) {
        this.onUserRead()
      } else if (this.props.authData?.username === this.props.account?.owner && this.props.account?.user != null) {
        this.onSessionReload()
      }
    }    
  }

  onUserCreate = () => {
    this.props.handleUserCreate(this.props.authData?.username, this.props.authData?.attributes)
  }

  onUserRead = () => {
    if (this.props.authData?.attributes["custom:setup_user_id"] != null) {
      this.props.handleUserRead(this.props.authData?.username, this.props.authData?.attributes)
    } else {
      setTimeout(() => {
        this.onReloadUser()
      }, 10000);
    }
  }

  onSessionReload = () => {
    if (!this.state.sessionUpdated && this.props.account?.owner && this.props.account?.user) {
      this.props.handleSessionCreate(this.props.account?.owner, this.props.account?.user)
      this.setState({sessionUpdated: true})
      setTimeout(() => {
        this.setState({sessionUpdated: false})
      }, 3*60000);
    }
  }

  render() {
    const accountReady = this.props.authData?.username === this.props.account?.owner && this.props.account?.user
    return (
      <div>{ accountReady  ? this.props.children : <Loader/> }</div>
    )
  }
}

const mapStateToProps = store => {
  return {
    account: store.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleUserCreate: (owner, attributes) => dispatch(handleUserCreate(owner, attributes)),
    handleUserRead: (owner, attributes) => dispatch(handleUserRead(owner, attributes)),
    handleUserUpdate: (owner, userId, usernam) => dispatch(handleUserUpdate(owner, userId, usernam)),
    handleUserDelete: (owner, userId) => dispatch(handleUserDelete(owner, userId)),
    handleSessionCreate: (owner, user) => dispatch(handleSessionCreate(owner, user)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserContainer)