class Queue {
  constructor(...elements) {
    this.elements = [...elements];
  }

  push(...args) {
    return this.elements.push(...args);
  }

  shift(...args) {
    return this.elements.shift(...args);
  }

  get length() {
    return this.elements.length;
  }

  set length(length) {
    return this.elements.length = length;
  }
}

export default Queue